/* eslint-disable */
import store from "../../store/index";
export default {
    name: 'reportdashboard',
    data() {
        return {
          roleprivileges: ""
        }
    },
    async mounted() {
        await this.setroleprivileges(); 
    },
    methods:{
            async setroleprivileges(){
                
              this.roleprivileges = await store.getters.getRolesprivilegeData;
            },
        async schedulereport(){
            if(this.roleprivileges.includes('Reports Menu Schedule')){
                this.$router.push("./report")
            }
        },
        async patientreport(){
            if(this.roleprivileges.includes('Reports Menu Patient Summary')){
                this.$router.push("./patientreport")
            }
        },
        async sitereport(){
            if(this.roleprivileges.includes('Reports Menu Site Report')){
                this.$router.push("./siteReport")
            }
        },
        async visitreport(){
            if(this.roleprivileges.includes('Reports Menu Visit Summary')){
                this.$router.push("./visitSummaryReport")
            }
            
        }
    }
}