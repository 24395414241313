<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{$t('navigations.profile settings')}}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-12">
        <ul class="nav nav-tabs" role="tablist">
          <li>
            <a
              :class="{ activeTab: showBlock[0] === true }"
              @click="
                (showBlock[0] = true),
                  (showBlock[1] = false),
                  (showBlock[2] = false),
                  (showBlock[3] = false),
                  clearFunction(),
                  getAndSetProfileData()
              "
              data-toggle="tab"
            >
              {{ $t("navigations.my profile") }}
            </a>
          </li>

          <li>
            <a
              :class="{ activeTab: showBlock[1] === true }"
              @click="
                (showBlock[1] = true),
                  (showBlock[0] = false),
                  (showBlock[2] = false),
                  (showBlock[3] = false),
                  clearFunction(),
                  (changepassword.currentpassword = ''),
                  (changepassword.newpassword = ''),
                  (changepassword.confirmpassword = '')
              "
              data-toggle="tab"
              >{{ $t("navigations.change password") }}</a
            >
          </li>
          <li>
            <a
              :class="{ activeTab: showBlock[2] === true }"
              @click="
                (showBlock[2] = true),
                  (showBlock[1] = false),
                  (showBlock[0] = false),
                  (showBlock[3] = false),
                  clearFunction(),
                  (isUpdate = false)
              "
              data-toggle="tab"
              >{{ $t("navigations.digital signature") }}</a
            >
          </li>
          <li>
            <a
              :class="{ activeTab: showBlock[3] === true }"
              @click="
                (showBlock[0] = false),
                  (showBlock[1] = false),
                  (showBlock[2] = false),
                  (showBlock[3] = true),
                  clearFunction()
              "
              data-toggle="tab"
            >
              {{ $t("navigations.default setup") }}
            </a>
          </li>
        </ul>
        <div role="tabpanel" v-if="showBlock[0]">
          <div class="ibox-title style_2 d-flex justify-content-between">
            <h6 class="my-0 align-self-center">
              {{ $t("navigations.my profile") }}
            </h6>
            <div class="ibox-tools d-flex flex-column">
              <a
                data-toggle="tooltip"
                data-placement="top"
                title="Save"
                href="#"
                @click.prevent="UpdateUser()"
                ><img src="../../assets/img/save.svg" alt="Save profile"
              /></a>
              <span class="mobile-view">{{ $t("navigations.save") }}</span>
            </div>
          </div>
          <div class="ibox-content">
            <div class="form-group row">
              <div class="col-lg-4 my-2">
                <label class="col-form-label"
                  >{{ $t("navigations.first name") }}<sup><i class="fa fa-asterisk imp"></i></sup>
                </label>
                <input
                  v-model="ProfileData.firstName"
                  type="text"
                  class="form-control"
                  placeholder="Enter your first name"
                />
                <span class="errormsg" v-if="validations[0]"
                  >{{$t("navigations.please enter first name")}}</span
                >
              </div>
              <div class="col-lg-4 my-2">
                <label class="col-form-label"
                  >{{ $t("navigations.last name") }}<sup><i class="fa fa-asterisk imp"></i></sup
                ></label>
                <input
                  v-model="ProfileData.lastName"
                  type="text"
                  class="form-control"
                  placeholder="Enter your last name"
                />
                <span class="errormsg" v-if="validations[1]"
                  >{{$t("navigations.please enter last name")}}</span
                >
              </div>
              <div class="col-lg-4 my-2">
                <label class="col-form-label"
                  >{{ $t("navigations.user name") }}<sup><i class="fa fa-asterisk imp"></i></sup
                ></label>
                <input
                  v-model="ProfileData.userName"
                  type="text"
                  class="form-control"
                  placeholder="Enter your user name"
                  maxlength = "10"
                />
                <span class="errormsg" v-if="validations[11]"
                  > {{$t("navigations.username should be 5 to 10 characters")}}</span>
                 
              </div>
              <div class="col-lg-4 my-2">
                <label class="col-form-label"
                  >{{ $t("navigations.primary email") }}<sup><i class="fa fa-asterisk imp"></i></sup
                ></label>
                <input
                  v-bind:readonly="true"
                  v-model="ProfileData.emailIdPrimary"
                  type="email"
                  class="form-control"
                  autocomplete="off"
                  placeholder="Enter your primary email id"
                />
                <span class="errormsg" v-if="validations[2]"
                  > {{$t("navigations.you have entered an invalid email address")}}</span
                >

              </div>
              <div class="col-lg-4 my-2">
                <label class="col-form-label">{{ $t("navigations.secondary email") }}</label>
                <input
                  v-model="ProfileData.emailIdSecondary"
                  type="email"
                  class="form-control"
                  autocomplete="off"
                  placeholder="Enter your secondary email id"
                />
                <span class="errormsg" v-if="validations[3]"
                  >{{$t("navigations.you have entered an invalid email address")}}</span
                >
              </div>
              <div class="col-lg-4 my-2" id="data_1">
                <label class="col-form-label">{{ $t("navigations.date of birth") }}</label>
                <div class="input-group date">
                  <input
                    type="date"
                    v-model="ProfileData.dateOfBirth"
                    max="9999-12-31"
                    class="form-control"
                  />
                </div>
                <span class="errormsg" v-if="validations[4]"
                  >{{$t("navigations.please enter a valid date")}}</span
                >
              </div>
              <div class="col-lg-4 my-2">
                <label class="col-form-label">{{ $t("navigations.phone number") }}</label>
                <div class="input-group date">
                  <input
                    type="text"
                    v-model="ProfileData.phoneNumber"
                    class="form-control"
                    maxlength="10"
                  />
                </div>
                <span class="errormsg" v-if="validations[5]"
                  >{{$t("navigations.you have entered an invalid phone number")}}</span
                >

              </div>
              <div class="col-lg-4 my-2">
                <label class="col-form-label">{{ $t("navigations.select your country") }}</label>
                <select
                  v-model="ProfileData.country"
                  class="form-select"
                  name="account"
                >
                  <option>{{ $t("navigations.united states") }}</option>
                  <option>{{ $t("navigations.india") }}</option>
                  <option>{{ $t("navigations.japan") }}</option>
                  <option>{{ $t("navigations.china") }}</option>
                </select>
              </div>
              <div class="col-lg-8 my-2">
                <label class="col-form-label col-lg-12 px-0">{{ $t("navigations.address 1") }}</label>
                <div class="input-group date">
                  <input
                    type="text"
                    v-model="ProfileData.addressLineOne"
                    class="form-control address"
                  />
                </div>
              </div>
              <div class="col-lg-12 my-2">
                <label class="col-form-label col-lg-12 px-0">{{ $t("navigations.address 2") }}</label>
                <div class="input-group date">
                  <input
                    type="text"
                    v-model="ProfileData.addressLineTwo"
                    class="form-control address"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div role="tabpanel" v-if="showBlock[1]">
          <div class="ibox-title style_2 d-flex justify-content-between">
            <h6 class="my-0 align-self-center">
              {{ $t("navigations.change password") }}
            </h6>
          </div>
          <div class="ibox-content">
            <form class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <input
                    type="password"
                    :placeholder="$t('navigations.enter current password')"
                    class="form-control"
                    maxlength="16"
                    autocomplete="off"
                    v-model="changepassword.currentpassword"
                  />
                  <span class="errmsg" v-if="currentvalidation[0]">
                    {{ $t("navigations.please enter current password") }}</span
                  >
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    :placeholder="$t('navigations.enter new password')"
                    class="form-control"
                    maxlength="16"
                    autocomplete="off"
                    v-model="changepassword.newpassword"
                  />
                  <span class="errormsg" v-if="validations[6]">{{
                    $t("navigations.please enter a new password")
                  }}</span>
                  <span
                    class="errormsg"
                    v-if="validations[8] && !validations[6]"
                  >
                    {{
                      $t(
                        "navigations.the password must be at least 8 characters long and include at least one uppercase letter, lower case letter,numeric character, and one special character"
                      )
                    }}</span
                  >
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    :placeholder="$t('navigations.confirm new password')"
                    class="form-control"
                    maxlength="16"
                    autocomplete="off"
                    v-model="changepassword.confirmpassword"
                  />
                  <span class="errormsg" v-if="validations[7]">{{
                    $t("navigations.please confirm new password")
                  }}</span>
                  <span
                    class="errormsg"
                    v-if="validations[9] && !validations[7]"
                  >
                    {{
                      $t(
                        "navigations.the password must be at least 8 characters long and include at least one uppercase letter, lower case letter,numeric character, and one special character"
                      )
                    }}</span
                  >
                  <span class="errormsg" v-if="validations[10]">{{
                    $t("navigations.password and confirm password mismatch")
                  }}</span>
                </div>
                <div class="d-flex justify-content-end">
                  <button
                    @click.prevent="checkpasswords()"
                    class="btn save_btn py-1 px-3"
                  >
                    {{ $t("navigations.update") }}
                  </button>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <ul>
                  <li class="text-danger">
                    {{
                      $t(
                        "navigations.password must contain 8 or more characters in length"
                      )
                    }}
                  </li>
                  <li class="text-danger">
                    {{
                      $t(
                        "navigations.password must contain 1 or more uppercase characters"
                      )
                    }}
                    (A-Z).
                  </li>
                  <li class="text-danger">
                    {{
                      $t(
                        "navigations.password must contain 1 or more numeric characters"
                      )
                    }}
                    (0-9).
                  </li>
                  <li class="text-danger">
                    {{
                      $t(
                        "navigations.password must contain 1 or more special characters"
                      )
                    }}
                    (#*$_).
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>
        <div role="tabpanel" v-if="showBlock[2]">
          <div class="ibox-title style_2">
            <h5 class="my-0">
              {{ $t("navigations.digital signature settings") }}
            </h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox-content mb-2 mx-0 row pt-0">
                  <form class="col-lg-12">
                    <dl class="row mb-0">
                      <div class="col-sm-6 text-sm-left my-2">
                        {{ $t("navigations.user id") }} :
                        <strong> {{ firstName }}&nbsp;{{ lastName }} </strong>
                      </div>
                      <div class="col-sm-6 text-sm-left my-2">
                        {{ $t("navigations.email") }} :
                        <strong> {{ this.ProfileData.emailIdPrimary }}</strong>
                      </div>
                    </dl>
                    <div class="form-group row">
                      <div class="col-lg-4 mt-1" v-if="isUpdate">
                        <label class="col-form-label"
                          >{{ $t("navigations.old password") }}<sup></sup
                        ></label>
                        <input
                          type="password"
                          placeholder=""
                          class="form-control"
                          v-model="oldPassword"
                          maxlength="16"
                        />
                        <span class="errorClass" v-if="signErrors[2]">
                          {{ errorMessages[2] }}</span
                        >
                        <span class="errorClass" v-if="errors[0] == true"
                          >{{ $t("navigations.please enter old password") }}
                        </span>
                      </div>
                      <div
                        class="col-lg-4 mt-1"
                        v-if="signStatus == 'No Signature' || isUpdate"
                      >
                        <label class="col-form-label "
                          >{{ $t("navigations.new password") }}<sup></sup>
                          <img
                            src="../../assets/img/info.svg"
                            alt="info icon"
                            class="info ml-1 cursor-pointer"
                            @click="showPopup = !showPopup"
                            v-on:clickout="showPopup = false"
                          />
                          <div
                            class="create-user-mod"
                            v-if="showPopup == true"
                            v-on:clickout="showPopup = false"
                          >
                            <div class="modal-dialog">
                              <div class="modal-content">
                                <div
                                  class="modal-header-projects d-flex justify-content-bertween p-3"
                                >
                                  <div>
                                    {{
                                      $t(
                                        "navigations.the password must be at least 8 characters long and include at least one uppercase letter, lower case letter,numeric character, and one special character"
                                      )
                                    }}.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </label>
                        <input
                          type="password"
                          autocomplete="off"
                          placeholder=""
                          class="form-control"
                          v-model="signPassword"
                          id="password-text"
                          maxlength="16"
                          @keyup="isGood(signPassword)"
                          name="password"
                        />
                        <div id="passwordStrength"></div>
                        <span class="errorClass" v-if="signErrors[0]">
                          {{ errorMessages[0] }}</span
                        >
                      </div>
                      <div
                        class="col-lg-4 mt-1"
                        v-if="signStatus == 'No Signature' || isUpdate"
                      >
                        <label class="col-form-label"
                          >{{ $t("navigations.confirm password") }}<sup></sup
                        ></label>
                        <input
                          type="password"
                          placeholder=""
                          class="form-control"
                          v-model="confirmSignPassword"
                          maxlength="16"
                        />
                        <span class="errorClass" v-if="signErrors[1]">
                          {{ errorMessages[1] }}</span
                        >
                      </div>
                      <div class="col-lg-12 align-self-end mt-2">
                        <button
                          v-if="signStatus == 'No Signature'"
                          class="save_btn px-3"
                          @click.prevent="createSign()"
                        >
                        {{ $t("navigations.save") }}
                        </button>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end">
                      <button
                        v-if="isUpdate"
                        class="save_btn px-3 ml-2"
                        @click.prevent="cancelUpdatesign()"
                      >
                        {{ $t("navigations.cancel") }}
                      </button>
                      <button
                        v-if="isUpdate"
                        class="save_btn px-3 ml-2"
                        @click.prevent="UpdateSign()"
                      >
                        {{ $t("navigations.update") }}
                      </button>
                    </div>
                    <div class="float-left">
                      <button
                        v-if="signStatus == 'Sign Exist' && isUpdate == false"
                        class="save_btn px-3"
                        @click.prevent="isUpdateClicked()"
                      >
                      {{ $t("navigations.update password") }}
                      </button>
                    </div>
                  </form>
                  <div
                    class="col-lg-6"
                    v-if="signStatus == 'No Signature' || isUpdate"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div role="tabpanel" v-if="showBlock[3]">
        <div class="ibox-title style_2">
          <div class="form-group row">
            <div class="col-lg-6 my-2">
              <div class="ibox mb-0">
                <div class="ibox-title style_2">
                  <h5>{{ $t("navigations.default study configuration") }}</h5>
                </div>
                <div class="ibox-content">
                  <div class="input-group">
                    <select v-model="currentStudy" class="form-select">
                      <option value="" selected>
                        {{ $t("navigations.select a study to set default") }}
                      </option>
                      <option
                        v-for="study in studyList"
                        :key="study.studyId"
                        :value="study.studyId"
                      >
                        {{ study.studyRef }}
                      </option>
                    </select>
                    <button
                      type="button"
                      class="btn-1"
                      @click="UpdateClicked()"
                    >
                    {{ $t("navigations.save") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 my-2">
              <div class="ibox mb-0">
                <div class="ibox-title style_2"> 
                  <h5>{{ $t("navigations.set your local language") }}</h5>
                </div>
                <div class="ibox-content">
                  <div class="input-group">
                    <select v-model="defaultlanguage" class="form-select">
                      <option value="" selected>{{ $t("navigations.select a local language") }}</option>
                      <option
                        v-for="lang in langList"
                        :key="lang.code"
                        :value="lang.code"
                      >
                        {{ lang.language }}
                      </option>
                    </select>
                    <button
                      type="button"
                      class="btn-1"
                      @click="updateDefLanguage(defaultlanguage)"
                    >
                    {{ $t("navigations.save") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-6 my-2">
              <div class="ibox mt-1 mb-0">
                <div class="ibox-title style_2">   
                  <h5>{{ $t("navigations.default date format") }}</h5>
                  <div class="ibox-tools"></div>
                </div>
                <div class="ibox-content">
                  <div class="input-group">
                    <select class="form-select" v-model="defaultdateFormat">
                      <option value="" selected>{{ $t("navigations.select a date format") }}</option>
                      <option
                        v-for="date in dateList"
                        :key="date.dateFormat"
                        :value="date.dateFormat"
                      >
                        {{ date.dateFormat }}
                      </option>
                    </select>
                    <button
                      type="button"
                      class="btn-1"
                      @click="updateDefDateFormat()"
                    >
                    {{ $t("navigations.save") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 my-2">
              <div class="ibox mt-1">
                <div class="ibox-title style_2">
                  <h5>{{ $t("navigations.default time format") }}</h5>   
                  <div class="ibox-tools"></div>
                </div>
                <div class="ibox-content">
                  <div class="input-group">
                    <select class="form-select" v-model="defaultTimeFormat">
                      <option value="h:mm A">{{ $t("navigations.12 hrs") }}</option>
                      <option value="HH:mm">{{ $t("navigations.24 hrs") }}</option>
                    </select>
                    <button
                      type="button"
                      class="btn-1"
                      @click="updateTimeFormat()"
                    >
                    {{ $t("navigations.save") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./profileSettings.js"></script>

<style scoped>
@media (max-width: 425px) {
  .save_btn {
    width: 100%;
  }
}

.errorClass {
  color: red;
  font-size: 15px;
}
.ibox-title1 {
  background-color: #3c4192;
  border-color: #e7eaec;
  height: 48px;
  color: #e7eaec;
  border-radius: 5px;
  border-image: none;
  border-style: solid solid none;
  border-width: 1 px;
  color: inherit;
  margin-bottom: 0;
  padding: 15 px 90 px 8 px 15 px;
  min-height: 48 px;
  position: relative;
  text-align: left !important;
  clear: both;
  border-radius: 4 px;
}
.mtText {
  display: inline-block;
  float: none;
  padding: auto;
  color: aliceblue;
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: auto;
}
/* .newDiv {
  margin-top: 20px;
  background: orange;
} */
.form-select {
  border-radius: .5rem !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
.btn-1 {
  display: inline-block;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  background-color: #e7e7e7;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0 3px 3px 0;
}
.btn-1:hover,
.btn-1.focus {
  background-color: #618bd7;
  color: #ffffff;
}
.mobile-view {
  display: none;
}
.collapse-icon {
  transform-origin: 50% 50%;
  transition: transform 0.3s ease-in;
}
.collapse-btn i {
  float: right;
}
.collapse-btn {
  cursor: pointer;
}
.collapse-content {
  color: inherit;
  background-color: #ffffff;
  padding: 15px 20px 20px 20px;
  border-color: #eeeeee;
  -o-border-image: none;
  border-image: none;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
}
.create-user-mod {
  position: absolute;
  width: 350px;
  top: -114px;
  left: 9px;
}
.animated-collapse {
  -webkit-animation-name: collapseInDown;
  animation-name: collapseInDown;
}
.animated-collapse {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@keyframes collapseInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 768px) {
  .mobile-view {
    display: block;
  }
}
.activeTab {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
</style>