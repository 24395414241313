<template>
  <div>
    <svg ref="arcChart" :width="width" :height="height"></svg>
    <div>{{ passedNumber }}/{{ totalNumber }}</div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'ArcTweenChart',
  props: {
    totalNumber: {
      type: Number,
      required: true,
      default: 100,
    },
    passedNumber: {
      type: Number,
      required: true,
      default: 50,
    },
    color: {
      type: String,
      default: '#4caf50',
    },
    width: {
      type: Number,
      default: 75,
    },
    height: {
      type: Number,
      default: 75,
    },
  },
  watch: {
    totalNumber() {
      this.createChart();
    },
    passedNumber() {
      this.createChart();
    },
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const svg = d3.select(this.$refs.arcChart);
      svg.selectAll('*').remove(); // Clear any existing elements

      const width = this.width;
      const height = this.height;
      const radius = Math.min(width, height) / 2;

      const arc = d3.arc()
        .innerRadius(radius * 0.7)
        .outerRadius(radius * 0.9);

      const pie = d3.pie()
        .sort(null)
        .value(d => d.value);

      const data = [
        { name: 'passed', value: this.passedNumber },
        { name: 'remaining', value: this.totalNumber - this.passedNumber },
      ];

      const arcs = pie(data);

      const color = d3.scaleOrdinal()
        .domain(data.map(d => d.name))
        .range([this.color, '#ccc']);

      const g = svg.append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`);

      g.selectAll('path')
        .data(arcs)
        .enter()
        .append('path')
        .attr('fill', d => color(d.data.name))
        .transition()
        .duration(1000)
        .attrTween('d', function(d) {
          const i = d3.interpolate(d.startAngle, d.endAngle);
          return function(t) {
            d.endAngle = i(t);
            return arc(d);
          };
        });

      // Add text element for percentage
      const percentage = ((this.passedNumber / this.totalNumber) * 100).toFixed(2) + '%';
      svg.append('text')
        .attr('text-anchor', 'middle')
        .attr('dy', '0.35em')
        .attr('x', width / 2)
        .attr('y', height / 2)
        .style('font-size', '16px')
        .style('fill', '#000')
        .text(percentage);
    },
  },
};
</script>

<style scoped>
div {
  text-align: center;
}
</style>
