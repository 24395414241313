<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.study profile") }}</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 px-3">
      <div class="mt-3" v-if="rollsprivilages.includes('Studylist Edit')">
        <span class="float-right" @click.prevent="studyEditModal = true"><i class="fa fa-edit fs-5"></i>
          <div class="tool-tip">{{ $t("navigations.edit study") }}</div>
        </span>
        <h4>{{ $t("navigations.study information") }}</h4>
      </div>
      <hr class="my-1" />
      <div class="row">
        <div class="col-sm-1">
          <small class="stats-label">{{ $t("navigations.study id") }}</small>
          <h4>{{ studyDetails.studyRef }}</h4>
        </div>
        <div class="col-sm-3">
          <small class="stats-label">{{ $t("navigations.name") }}</small>
          <h4>{{ studyDetails.studyName }}</h4>
        </div>
        <div class="col-sm-3">
          <small class="stats-label">{{ $t("navigations.sponsor") }}</small>
          <h4>{{ studyDetails.sponsor }}</h4>
        </div>
        <div class="col-sm-2">
          <small class="stats-label">{{ $t("navigations.no of enrolled sites") }}</small>
          <h4>{{ studyDetails.numberOfSite }}</h4>
        </div>
        <div class="col-sm-2">
          <small class="stats-label">{{ $t("navigations.no of enrolled patients") }}</small>
          <h4>{{ studyDetails.numberOfPatient }}</h4>
        </div>
        <div class="col-sm-1">
          <small class="stats-label">{{ $t("navigations.status") }}</small>
          <h4>{{ studyDetails.status }}</h4>
        </div>

      </div>
      <hr class="my-1" />
    </div>
    <div class="col-lg-12 mt-2">
      <div class="px-0">
        <div class="btn-group w-100">
          <button class="btn btn-success btn-outline btn-xs mx-1" data-toggle="modal" data-target="#createVisit"
            v-if="rollsprivilages.includes('Visit Add Visit')" @click.prevent="visitPopup(0)">
            <i class="fa fa-map-marker mr-1"></i>
            <span class="bold">{{ $t("navigations.create visit") }}</span>
          </button>
          <button class="btn btn-success btn-outline btn-xs mx-1" data-toggle="modal" data-target="#createSite"
            v-if="rollsprivilages.includes('Sites Create Site')" @click.prevent="siteModal = true">
            <i class="fa fa-hospital-o mr-1"></i>
            <span class="bold">{{ $t("navigations.create site") }}</span>
          </button>
          <button class="btn btn-success btn-outline btn-xs mx-1"
            v-if="rollsprivilages.includes('Subjects Create Subject')" @click.prevent="showPatientModal = true">
            <i class="fa fa-wheelchair mr-1"></i>
            <span class="bold">{{ $t("navigations.create subject") }}</span>
          </button>
          <button class="btn btn-success btn-outline btn-xs mx-1" @click.prevent="createTemplate()" data-toggle="modal"
            v-if="rollsprivilages.includes('Visit Templates Create Template')" data-target="#createTemplate">
            <i class="fa fa-file-text-o mr-2"></i>
            <span class="bold">{{ $t("navigations.create template") }}</span>
          </button>
          <button class="btn btn-success btn-outline btn-xs mx-1" data-toggle="modal" data-target="#createTemplate"
            @click.prevent="onClickButton">
            <i class="fa fa-file-text-o mr-2"></i>
            <span v-if="showLoadingAnimation">
              <img src="../../assets/img/loading.webp" width="20" class="loading-img" alt="Waiting for login" />
            </span>
            <span v-else class="bold">AI Forms</span>
          </button>
        </div>
      </div>
    </div>

    <div class="col-lg-6 wrapper wrapper-content p-3">
      <h4>{{ $t("navigations.visit templates") }}</h4>
      <div class="table-responsive">
        <table class="table table-striped table-bordered ibox-body">
          <thead>
            <tr>
              <th>{{ $t("navigations.form name") }}</th>
              <th>{{ $t("navigations.version") }}</th>
              <th>{{ $t("navigations.last updated") }}</th>
              <th class="text-center" v-if="rollsprivilages.includes('Visit Templates View Template')">{{
        $t("navigations.view") }}</th>
              <th class="text-center">{{ $t("navigations.action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(template, index) in studyTemplates" :key="template.id">
              <td>{{ template.templateName }}</td>
              <td>{{ template.version }}</td>
              <td>{{ filterDate(template.createdDate) }} </td>
              <td class="text-center" v-if="rollsprivilages.includes('Visit Templates View Template')">
                <a @click.prevent="viewtemplatefun(template.templateId, template.version)"><i
                    class="fa fa-eye editicon"></i></a>
              </td>
              <td class="text-center" v-on:clickout="showSidePopup[index] = false">
                <div class="position-relative cursor-pointer" @click.prevent="
        showSidePopup[index] = !showSidePopup[index]
        ">
                  <i class="fa fa-ellipsis-v"></i>
                </div>
                <div class="plan-side-popup" v-if="showSidePopup[index]">
                  <div v-if="rollsprivilages.includes('Visit Templates Create New Version')"
                    @click.prevent="createNewVersion(template.templateFormId, template.version, template.sourceTemplateId,template.templateId)">
                    {{ $t("navigations.create new version") }}</div>
                  <div v-if="rollsprivilages.includes('Visit Templates Retire Form')"
                    @click.prevent="openretireModal(template.sourceTemplateId, template.version)">{{
        $t("navigations.retire form") }}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-12 d-flex justify-content-lg-end px-0">
        <v-pagination v-model="currentTemplatePage" :pages="totalTemplatePages" :range-size="0" active-color="#f4f9ff"
          @update:modelValue="loadTemplate" />
      </div>
    </div>

    <div class="col-lg-6 wrapper wrapper-content p-3">
      <h4>{{ $t("navigations.visits") }}</h4>
      <div class="table-responsive">
        <table class="table table-striped table-bordered ibox-body">
          <thead>
            <tr>
              <th>{{ $t("navigations.visit name") }}</th>
              <th>{{ $t("navigations.visit no") }}</th>
              <th>{{ $t("navigations.templates") }}</th>
              <th class="text-center">{{ $t("navigations.action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(visit, index) in visitList" :key="visit.visitId">
              <td>{{ visit.visitFormName }}</td>
              <td>{{ visit.visitNo }}</td>
              <td>
                {{ visit.template_Name ? visit.template_Name + '(' + visit.version + ')' : '' }}
              </td>
              <td class="text-center" v-on:clickout="showVisitPopup[index] = false">
                <div class="position-relative cursor-pointer" @click.prevent="
        showVisitPopup[index] = !showVisitPopup[index]
        ">
                  <i class="fa fa-ellipsis-v"></i>
                </div>
                <div class="plan-side-popup" v-if="showVisitPopup[index]">
                  <div v-if="rollsprivilages.includes('Visit Assign Template')"
                    @click.prevent="openTemplateModal(visit.visitId)"> {{ $t("navigations.assign template") }}</div>
                  <div v-if="rollsprivilages.includes('Visit Delete')" @click.prevent="deleteVist(visit.visitId)">
                    {{ $t("navigations.delete") }}
                  </div>
                  <div v-if="rollsprivilages.includes('Visit Edit')" @click.prevent="visitPopup(visit.visitId)">{{
        $t("navigations.edit") }}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-12 d-flex justify-content-lg-end px-0">
        <v-pagination v-model="currentVisitiPages" :pages="totalVisitPages" :range-size="0" active-color="#f4f9ff"
          @update:modelValue="loadVisit" />
      </div>
    </div>
  </div>
  <teleport to="#wrapper">
    <managePatientModal v-if="showPatientModal" @closeModal="showPatientModal = false" :patientId="0" />
  </teleport>
  <manageVisitModal v-if="showSiteModal" :visitId="visitId" @closeModal="closeModal()"
    @createVisit="listStudyTemplate()" />
  <CreateSitePopUp v-if="siteModal" :userid="'0'" @closeModal="siteModal = false" />
  <EditStudyPopUp v-if="studyEditModal" :studyid="studyId" @studyChanged="getStudyDetails()"
    @closeModal="studyEditModal = false" />
  <createnewversion v-if="creatversionpopup == true" @closedialogebox="closecreatenewversion()"
    @createnewtempversion="newtemplateversion()" :templateId="currenttemplateId" :currentversion="currentformversion" :formid="newversionformid" />
  <retireFormPopUp v-if="retireFormModal" @closeModal="closeRetireModal()" :sourceid="sourceId"
    :version="sourceVersion" />
  <teleport to="#wrapper">
    <!-- Template modal starts -->
    <div class="study_profile_modal__wrapper" v-if="showAssignTemplate">
      <div class="study_profile_modal__container custom-width mt-5">
        <div class="modal__title d-flex justify-content-between py-2 px-3">
          <h4 class="ml-lg-2">{{ $t("navigations.assign template") }}</h4>
          <div class="cursor-pointer" @click.prevent="closeTemplateModal">
            <i class="fa fa-times"></i>
          </div>
        </div>
        <div class="ibox-content p-3">
          <div class="modal-body pb-0">
            <div class="mb-3 p-2">
              <div class="form-group row my-lg-2">
                <div class="d-flex flex-column flex-lg-row my-2 mb-lg-0">
                  <div class="col-lg-12 my-2">
                    <select class="form-control mx-lg-2 my-2 my-lg-0" placeholder="Search by Status"
                      v-model="selectedmodeltemplate">
                      <option value="" disabled selected>
                        {{ $t("navigations.select template") }}
                      </option>
                      <option v-for="templates in templatelist" :key="templates.templateId" :value="templates">
                        {{ templates.templateName }} ({{ templates.version }})
                      </option>
                    </select>
                  </div>
                </div>

                <div class="d-flex my-2 mb-lg-0">
                  <div class="assignbtncontainer">
                    <button class="btn btn-primary px-5 mx-1" @click.prevent="assignTemplate()">
                      {{ $t("navigations.assign") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal ends -->
  </teleport>
  <Forms_AI_PopUp v-if="AIpopup" @studyCreated="loadstudy" @closeModal="AIpopup=false" />
</template>

<script src="./Study_Profile.js"></script>
<style scoped>
.btn-success {
  background-color: transparent;
  border-color: var(--secondary-clr);
  border-radius: 0;
  font-size: 0.8rem;
}

.btn-success:hover,
.btn-success:focus {
  color: #fff;
  background-color: #1a7bb9;
  border-color: #1a7bb9;
}

.btn {
  padding-block: 0.2rem;
}

.plan-side-popup {
  position: absolute;
  text-align: left;
  right: 56px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(124px, 90%);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.plan-side-popup>div {
  cursor: pointer;
  padding: 0.5rem;
}

.plan-side-popup>div:hover {
  background-color: #e4eaf9;
}

.study_profile_modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  z-index: 3000;
}

.study_profile_modal__container {
  position: fixed;
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}

.modal__title {
  background-color: var(--pop-up-background);
  color: white;
}

.float-right .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 15px;
  top: -15px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.float-right:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.custom-width {
  width: min(500px, 90%);
}
</style>