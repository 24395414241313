/* eslint-disable */
import axios from "axios";
import "/src/assets/css/animate.css";
import "/src/assets/css/style.css";
import arctween from './arctween.vue'
import d3BarchartComparitive from './d3BarchartComparitive.vue'
import newpichart from './newpichart.vue'
import groupbarchart from './groupbarchartscreening.vue';
import D3PieChart from './D3PieChart.vue';
import d3table from './d3table.vue';
import percebtagechart from './percentagechart.vue';
import newpiechart from './newpichart.vue';
import sunburst from './sunburst.vue';
export default {
  name: "nursesummary",
  components: {
    arctween,
    d3BarchartComparitive,
    newpichart,
    groupbarchart,
    D3PieChart,
    d3table,
    percebtagechart,
    newpiechart,
    sunburst,
  },
  props: {
    templateId: {
      type: String,
      default: ''
    },
    resultCount: {
      type: Object,
      required: true,
      default: () => ({
        noOfSubjects: 0,
        noOfSubjectsEnrolledByAI: 0,
        noOfSubjectsRejectedByAI: 0
      }),
    },
  },
  async mounted() {
    await this.getdata();
  },
  data() {
    return {
      loader: true,
      data: [],
      basetestUrl: process.env.VUE_APP_Service_URL,
      baseUrl: process.env.VUE_APP_Service_URL + "/forms-ml/patientenroll/",
      selectedChartType: 'Comparitive Bar chart',
      chartTypes: ['Comparitive Bar chart', 'Pie Chart', 'Bar Chart', 'Line Chart',],

    };
  },
  methods: {

    async headderforbarcomparitive(repfieldname) {
      this.comparitivebarheader = repfieldname;
    },
    async getdata() {
      this.loader = true;
      const screenInfos = {
        "PageNo": 1,
        "ItemCount": 10000,
        "SortBy": "",
        "IsDescending": false,
        "Template_id": this.templateId,
        "Status": null,
        "ML_confidenceMin": 0,
        "ML_confidenceMax": 1,
        "ML_prediction": null
      }
      await axios.post(
        `${this.baseUrl}screeningresult`,
        screenInfos)
        .then((res) => {
          this.data = res.data.data;
          this.loader = false;
          console.log("data for grapg is", res.data);
        })
        .catch((err) => console.log("error in screen list", err));
    },
  },
};