<template>
  <div class="stat-card-white percentageheightmin" :class="{'percentminheight' : fromPage == 'descriptiveStatistics'}">
    <div class="stat-header-white">
      <span v-if="fromPage=='descriptiveStatistics'">
       Statistical Summary
      </span>
      
      <span v-if="!loader">
        <select class="graph-select" v-model="selectedPercentageAttribute" id="attribute-select" @change="updateChart">
          <option v-for="option in attributeOptions" :key="option" :value="option">{{ option }}</option>
        </select>
      </span>
      <div v-if="!loader && enrolledornot && fromPage=='patientScreening' || fromPage== 'patientRetention'" class="controls mr-2">
        <label>
          <input type="checkbox" v-model="aiPredictionTrue" @change="updateChart"> {{ labelOne }}
        </label>
        <label>
          <input type="checkbox" v-model="aiPredictionFalse" @change="updateChart"> {{  labelTwo }}
        </label>
      </div>
    </div>
    <div class="stat-body-white">
      <div v-if="loader" class="loader"></div>
      <div v-if="attribute && !loader" class="pgbarwidth col-md-4">
        <div v-for="(percentage, key) in percentages" :key="key" class="attribute-bar">
          <span class="col-md-4">{{ getMappedValue(key, attribute) }}</span>
          <div class="progress-bar col-md-4">
            <div class="progress" :style="{ width: percentage + '%' }"></div>
          </div>
          <span class="col-md-4">{{ percentage.toFixed(2) }}%</span>
        </div>
      </div>
      <div v-if="averageFieldOptions.length > 0 && !loader" class="average-container col-md-7">
        <h5 v-if="templateId != '' && templateId != null">Health Statistics Averages</h5>
        <h5 v-else> Matirx Averages</h5>
        <div class="average-stats overflow-block">
          <div class="stat-item" v-for="(average, index) in averages" :key="index">
            <select v-model="selectedAverageFields[index]" @change="updateAverages">
              <option v-for="option in averageFieldOptions" :key="option" :value="option">{{ option }}</option>
            </select>
            <h2 class="col-md-4">{{ average.toFixed(2) }}</h2>
          </div>
        </div>
      </div>
      <div class="average-container align-items-center" v-if="fromPage == 'descriptiveStatistics' && !loader">
        <div class="average-stats overflow-block">
          <div class="stat-item" v-for="(averageItem, key) in averagesItem" :key="key">
            <h2 >{{ key.charAt(0).toUpperCase() + key.slice(1) }}</h2> 
            <h4 >{{ averageItem!=null ? averageItem.toFixed(2) : 0}}</h4> 
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import store from "../../store/index";

export default {
  name: 'AttributePercentageBar',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    templateId: {
      type: String,
      default: ''
    },
    fromPage: {
      type: String,
      default: ""
    },
    enrolledornot: {
      type: Boolean,
      default: true
    },
    labelOne:{
      type: String,
      default:"Enrolled"
    },
    labelTwo:{
      type: String,
      default:"Non-enrolled"
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      idtoken: "",
      baseApi: process.env.VUE_APP_Service_URL,
      baseurl: `${process.env.VUE_APP_Service_URL}/forms-ml/`,
      mappings: {},
      attribute: '',
      percentages: {},
      averages: [],
      averagesItem:[],
      selectedPercentageAttribute: '',
      attributeOptions: [],
      averageFieldOptions: [],
      selectedAverageFields: ['', '', ''],
      aiPredictionTrue: true,
      aiPredictionFalse: true,
    };
  },
  watch: {
    data: {
      handler() {
        this.loadAttributes();
        this.updateAverages();
      },
      deep: true,
    },
  },
  async beforeMount() {
    this.idtoken = store.getters.getIdToken;
    console.log("in before mounted ", this.fromPage)
    if (this.fromPage == 'patientScreening') {
      await this.createmapping();
      this.updateAverages();
      this.loadAttributes();
    }
    if (this.fromPage == 'descriptiveStatistics') {
      await this.updateAverages();
      if (this.attributeOptions.length > 0) {
        this.selectedPercentageAttribute = this.attributeOptions[0];
      }
    }
    if(this.fromPage == 'patientRetention'){
      this.loadAttributes();
      this.updateAverages();
    }


  },
  methods: {
    async createmapping() {
      try {
        const response = await axios.get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          const pages = response.data.pages;
          pages.forEach(page => {
            page.field.forEach(field => {
              if (field.options && field.options.length > 0) {
                this.mappings[field.fieldName] = field.options.reduce((map, option) => {
                  map[option.codevalue] = option.optionname;
                  return map;
                }, {});
              }
            });
          });
        }
      } catch (error) {
        console.log("Error creating mappings", error);
      }
    },
    loadAttributes() {
      if (this.fromPage == 'patientScreening'|| this.fromPage== 'patientRetention') {
        const sampleData = this.data && this.data.length > 0 ? this.data[0].features : {};
        this.attributeOptions = Object.keys(sampleData).filter(key => {
          const uniqueValues = new Set(this.data.map(item => item.features[key])).size;
          return uniqueValues < 5;
        });

        if (this.attributeOptions.length > 0) {
          this.selectedPercentageAttribute = this.attributeOptions[0];
        }

        this.findAttribute();
        this.loadAverageFieldOptions();
      }

    },
    loadAverageFieldOptions() {
      const sampleData = this.data && this.data.length > 0 ? this.data[0].features : {};
      const numericFields = Object.keys(sampleData).filter(key => {
        return typeof sampleData[key] === 'number' && new Set(this.data.map(item => item.features[key])).size > 6;
      });
      this.averageFieldOptions = numericFields;
      this.selectedAverageFields[0] = this.averageFieldOptions[0];
      this.selectedAverageFields[1] = this.averageFieldOptions[1];
      this.selectedAverageFields[2] = this.averageFieldOptions[2];
    },
    findAttribute() {
      if (!this.selectedPercentageAttribute) {
        this.attribute = '';
        return;
      }

      this.attribute = this.selectedPercentageAttribute;
      this.calculatePercentages();
    },
    validateData(data) {
      return data.filter(d => {
        const aiPrediction = d.features.AIPrediction;
        if ((aiPrediction && this.aiPredictionTrue) || (!aiPrediction && this.aiPredictionFalse)) {
          return d.features[this.attribute] !== undefined;
        }
        return false;
      });
    },
    calculatePercentages() {
      const filteredData = this.validateData(this.data);
      const counts = filteredData.reduce((acc, item) => {
        const value = item.features[this.attribute];
        acc[value] = (acc[value] || 0) + 1;
        return acc;
      }, {});
      const total = filteredData.length;
      this.percentages = Object.keys(counts).reduce((acc, key) => {
        acc[key] = (counts[key] / total) * 100;
        return acc;
      }, {});
    },
    async updateAverages() {
      if (this.fromPage == 'patientScreening'|| this.fromPage== 'patientRetention') {
        const filteredData = this.validateData(this.data);
      this.averages = this.selectedAverageFields.map(field => {
        if (field) {
          const total = filteredData.reduce((sum, item) => sum + item.features[field], 0);
          return total / filteredData.length;
        }
        return 0;
      });
      }
      if (this.fromPage == 'descriptiveStatistics') {
        await axios
          .post(`${this.baseurl}report/getdescriptiveanalysis?table_name=SampleTable`,
          {
            "ignored_features": [
              "_id","Participant ID"
            ],
            "filter_condtions": {}
          }
          )
          .then((res) => {
            this.averages = res.data.NumericStats;
            this.attributeOptions = Object.keys(this.averages)
           
            this.averagesItem=[]
            this.averagesItem =  Object.keys(this.averages[this.selectedPercentageAttribute])
                .reduce((obj, key) => {
                  obj[key] = this.averages[this.selectedPercentageAttribute][key];
                  return obj;
                }, {});
            
          })
          .catch((err) => {
            console.log(err);
          });

      }
    },
    getMappedValue(value, key) {
      return this.mappings[key] ? this.mappings[key][value] || value : value;
    },
    updateChart() {
      if(this.fromPage=='patientScreening'|| this.fromPage== 'patientRetention')
      {
        this.findAttribute();
        this.updateAverages();
      }
      if(this.fromPage=='descriptiveStatistics')
      {
        this.updateAverages();
      }
  
    }
  },
};
</script>

<style scoped>
.attribute-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.attribute-bar span {
  width: 100px;
}

.progress-bar {
  flex: 1;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  height: 10px;
  margin: 0 10px;
}

.progress {
  background-color: #00aaff;
  height: 100%;
  transition: width 0.3s;
}

.averages {
  margin-top: 20px;
}

.average-field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.average-stats {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
}

.average-title {
  margin-bottom: 5px;
  font-size: 1.2em;
  color: #888;
}

.stat-item {
  border-left: 1px solid #007bff;
  padding-left: 5px;
  padding-right: 5px;
  flex: 1 1 30%;
  /* min-width: 120px; */
  margin-bottom: 10px;
}

.pgbarwidth {
  width: 100%;
  max-width: 400px;
}

.stat-card-white {
  background: #ffffff;
  border-radius: 10px;
  color: #676a6c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.stat-header-white {
  border-bottom: 2px solid rgba(241, 241, 241);
  padding: 0.4rem 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.stat-header-white span {
  font-size: 1.2em;
  font-weight: 500;
}

.stat-body-white {
  display: flex;
  align-items: center;
  padding: 10px;
  min-height: 125px;
}

.percentminheight {
  height: auto;
  min-height: 190px;
}

.average-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.graph-select {
  display: block;
  width: 100%;
  padding: 0.3rem 2.25rem 0.3rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.graph-select:focus,
.graph-select:focus-within,
.graph-select:focus-visible {
  outline: none;
}

.overflow-block {
  width: 90%;
  overflow-x: scroll;
}

/* Styling the scrollbar */
.overflow-block::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

/* Styling the scrollbar track */
.overflow-block::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

/* Styling the scrollbar thumb */
.overflow-block::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

/* Styling the scrollbar thumb on hover */
.overflow-block::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

/* For Firefox (scrollbar pseudo-class) */
.overflow-block {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #888 #f1f1f1; /* thumb color and track color */
}

.controls {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.percentageheightmin{
  min-height:210px;
}
</style>
