import VPagination from "@hennge/vue3-pagination";
import chart from "../../custom_lib/AiCharts/aiChart.vue";
import aiList from "../../components/AI_List/ai_list.vue";
import axios from "axios";
import store from "../../store/index";
export default {
  name: "PatientScreeningReport",
  components: {
    VPagination,
    chart,
    aiList,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_Service_URL + "/forms-ml/patientenroll/",
      baseApi: process.env.VUE_APP_Service_URL,
      idtoken: "",
      refreshFlags: {
        predictionReport: true,
        analyticalReport: false,
        aiList: false,
      },
      isLoading: false,
      importActive: false,
      templateList: {},
      modelItem: [],
      accuracy: 0,
      recall: 0,
      precision: 0,
      selectedForm: "",
      f1_score: 0,
      version: "",
      screenItem: [],
      currentPage: 1,
      totalPage: 1,
      screenInfos: {
        PageNo: 1,
        ItemCount: 500,
        SortBy: "",
        IsDescending: false,
        Template_id: "",
        Status: null,
        ML_confidenceMin: 0,
        ML_confidenceMax: 1,
        ML_prediction: null,
      },
      templateId: "",
      listforms: [],
      newfeaturesValue: {},
      fields: [],
      csvFile: {},
      showFeatures: false,
      changeHeight: false,
      tableloadingBlock: false,
      features: {},
      changeItem: "",
      resultCount: {},
      loadingBlock: false,
      subRejectedPercentage: 0,
      subEnrolledPercentage: 0,
      feature_interaction: {},
      pages: [],
      rollsprivilages: "",
    };
  },
  async mounted() {
    this.idtoken = store.getters.getIdToken;
    this.rollsprivilages = await store.getters.getRolesprivilegeData;
    console.log("rollsprivilages:", this.rollsprivilages);
    window.addEventListener("studyIdChanged", async (event) => {
      await this.getForms();
      await this.getModelInfo();

      await this.totalCount();
      await this.screeeInfo();
    });
    await this.getForms();
    await this.getModelInfo();

    await this.totalCount();
    await this.screeeInfo();
  },
  computed: {
    featuresWithShap() {
      return Object.keys(this.features)
        .filter((key) => this.getShapValue(key) !== 0)
        .map((key) => ({ key, value: this.features[key] }));
    },
    featuresWithoutShap() {
      return Object.keys(this.features)
        .filter((key) => this.getShapValue(key) === 0)
        .map((key) => ({ key, value: this.features[key] }));
    },
  },
  methods: {
    validateKeypress(event) {
      const charCode = event.charCode;
      const char = String.fromCharCode(charCode);
      const regex = /^[0-9.]$/;

      // Prevent default if the character does not match the regex
      if (!regex.test(char)) {
        event.preventDefault();
      }

      // Additional check to ensure only one decimal point is allowed
      const value = event.target.value;
      if (char === "." && value.includes(".")) {
        event.preventDefault();
      }
    },
    handleTabClick(tab) {
      this.refreshFlags = [false, false, false];
      console.log(this.selectedForm);
      if (tab == "predictionReport") {
        this.refreshFlags[tab] = true;
      } else {
        if (this.selectedForm == undefined) {
          alert(this.$t("navigations.please select a form"));
        } else {
          this.refreshFlags[tab] = true;
        }
      }
    },
    async importClicked() {
      this.importActive = true;
    },
    async uploadFile() {
      this.loadingBlock = true;

      // Check if a file is selected
      if (
        !this.csvFile ||
        typeof this.csvFile !== "object" ||
        !(this.csvFile instanceof File)
      ) {
        alert("Please select a file to upload.");
        this.loadingBlock = false;
        return;
      }

      const formData = new FormData();
      formData.append("file", this.csvFile);
      formData.append("template_id", this.templateId);
      formData.append("UidColumnName", "UID");

      try {
        const response = await axios.post(
          `${this.baseUrl}uploadcsv`,
          formData,
          {
            headers: {
              Authorization: "Bearer " + this.idToken,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        alert("Uploaded successfully");
        this.totalCount();
        this.screeeInfo();

        this.getModelInfo();
      } catch (err) {
        console.log("error in upload file", err);

        if (err.response && err.response.data && err.response.data.detail) {
          if (err.response.data.detail === "Model Not Found") {
            alert("Model not found");
          } else if (
            err.response.data.detail ===
            "New data is missing the following required features: {'ETHNICITY', 'GENDER'}"
          ) {
            alert(
              "New data is missing the following required features: {'ETHNICITY', 'GENDER'}"
            );
          } else if (
            err.response.data.detail ===
            "'utf-8' codec can't decode byte 0x92 in position 15: invalid start byte"
          ) {
            alert("Unsupported file format!");
          } else {
            alert("An error occurred: " + err.response.data.detail);
          }
        } else {
          alert("Upload failed!");
        }
      } finally {
        // Reset loadingBlock and csvFile
        this.loadingBlock = false;
        this.csvFile = null; // Ensure the file is reset after an attempt
        this.importActive = false;
      }
    },
    async previewFiles(event) {
      this.csvFile = event.target.files[0];
    },
    async changeFunction() {
      this.screenInfos.PageNo = 1;
      this.currentPage = 1;
      this.totalPage = 1;
      this.totalCount();
      await this.getModelInfo();
      await this.screeeInfo();
    },

    async totalCount() {
      this.selectedForm = this.listforms.find(
        (form) => form.sourceTemplateId === this.templateId
      );
      await axios
        .get(
          `${this.baseUrl}getscreeningprocessifo?templateId=${this.templateId}`
        )
        .then((res) => {
          //   this.resultCount = res.data;
          const data = res.data;
          this.resultCount = {
            noOfSubjects: data["No of Subjects"],
            noOfSubjectsEnrolledByAI: data["No of Subjects Enrolled By AI"],
            noOfSubjectsRejectedByAI: data["No of Subjects Rejected By AI"],
            noOfSubjectsEnrolled: data["No of Subjects Enrolled"],
            noOfSubjectsRejected: data["No of Subjects Rejected"],
            averageAIConfidence: data["Average AI Confidence"],
          };
          this.subEnrolledPercentage =
            this.resultCount.noOfSubjects !== 0
              ? (
                  (this.resultCount.noOfSubjectsEnrolledByAI * 100) /
                  this.resultCount.noOfSubjects
                ).toFixed(2)
              : null;
          this.subRejectedPercentage =
            this.resultCount.noOfSubjects !== 0
              ? (
                  (this.resultCount.noOfSubjectsRejectedByAI * 100) /
                  this.resultCount.noOfSubjects
                ).toFixed(2)
              : null;
        })
        .catch((err) => console.log("error in screen list", err));
    },
    async getForms() {
      await axios
        .get(
          `${this.baseApi}/forms/template/listlatesttemplate?StudyID=${store.getters.getStudyIs}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.listforms = res.data.results.sort((a, b) =>
            a.templateName.localeCompare(b.templateName)
          );
          this.templateId = this.listforms[0].templateId;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async listTemplate() {
      await axios
        .get(`${this.baseUrl}`)
        .then((res) => {
          this.templateList = res.data;
        })
        .catch((err) => console.log("error in list template", err));
    },
    async getModelInfo() {
      this.selectedForm = this.listforms.find(
        (form) => form.sourceTemplateId === this.templateId
      );
      this.eventPayload = this.templateId;
      this.version = this.selectedForm.version;
      await axios
        .get(`${this.baseUrl}getmodelinfo?templateId=${this.templateId}`)
        .then((res) => {
          this.modelItem = res.data;
          if (res.data.length != 0) {
            this.accuracy = this.modelItem[
              this.modelItem.length - 1
            ].PerformanceMatrix.accuracy.toFixed(2);
            this.precision = this.modelItem[
              this.modelItem.length - 1
            ].PerformanceMatrix.precision.toFixed(2);
            this.recall = this.modelItem[
              this.modelItem.length - 1
            ].PerformanceMatrix.recall.toFixed(2);
            this.f1_score = this.modelItem[
              this.modelItem.length - 1
            ].PerformanceMatrix.f1_score.toFixed(2);
          } else {
            this.accuracy = 0;
            this.precision = 0;
            this.recall = 0;
            this.f1_score = 0;
          }
        })
        .catch((err) => console.log("error in list template1", err));
    },
    async ViewFeatures(
      features,
      feature_interaction,
      ML_confidence,
      ML_prediction
    ) {
      this.feature_interaction = feature_interaction;
      this.ML_prediction = ML_prediction;
      this.ML_confidence = ML_confidence;
      console.log("feeee", this.feature_interaction);
      // console.log("feeee",this.feature_interaction )
      console.log("ML_confidence", this.ML_confidence);
      console.log("ML_prediction", this.ML_prediction);
      this.showFeatures = true;
      this.tableloadingBlock = true;
      this.changeHeight = true;
      window.scroll(0, 0);
      this.newfeaturesValue = features;
      this.getPagesInfo();
    },
    // getShapValue(label) {
    //   const fieldName = this.getFieldName(label);
    //   if (fieldName && this.feature_interaction[fieldName]) {
    //     const shapValues = this.feature_interaction[fieldName];
    //     // console.log("no" ,shapValues, fieldName)
    //     const sum = shapValues[0] + shapValues[1];
    //     // console.log("normalizedSumssssssssss" ,sum)

    //     const normalizedSum = this.normalizeSum(sum);
    //     // console.log("normalizedSumssssssssss" ,normalizedSum)
    //     return `${normalizedSum} `;
    //   }
    //   return 0;
    // },

    // normalizeSum(sum) {
    //   const overallMin = Math.min(...Object.values(this.feature_interaction).map(val => val[0] + val[1]));
    //   const overallMax = Math.max(...Object.values(this.feature_interaction).map(val => val[0] + val[1]));

    //   const normalizedSum = ((sum - overallMin) / (overallMax - overallMin)) * 200 - 100;
    //   // console.log("normalizedSum" ,normalizedSum)
    //   return normalizedSum;
    // },
    getShapValue(label) {
      const fieldName = this.getFieldName(label);
      if (fieldName && this.feature_interaction[fieldName]) {
        const shapValues = this.feature_interaction[fieldName];
        const result = this.calculateShapValue(shapValues);
        console.log("shapValuessss", fieldName,shapValues);
        return `${result}`;
      }
      return 0;
    },

    calculateShapValue(shapValues) {
      const absShapValue0 = Math.abs(shapValues[0]);
      const absShapValue1 = Math.abs(shapValues[1]);
      let result;

      if (absShapValue0 > absShapValue1) {
        result = shapValues[0];
      } else {
        result = shapValues[1];
      }
      return result;
    },
    getShapBarWidth(label) {
      const shapValue = this.getShapValue(label);

      const numericShapValue = parseFloat(shapValue);

      const scalingFactor = 10;
      const maxShapValue = 1;
      const scaledWidth =
        ((Math.abs(numericShapValue) * scalingFactor) / maxShapValue) * 100;
     
      return Math.min(scaledWidth, 100);
    },

    getShapBarColor(label) {
      const shapValue = this.getShapValue(label);
      return shapValue >= 0 ? "#8ce38f" : "#FF8184";
    },

    // getShapBarWidth(label) {
    //   const shapValue = this.getShapValue(label);
    //   // console.log("shapvalue" ,shapValue)
    //   return Math.abs(shapValue);
    // },
    // getShapBarColor(label) {
    //   const shapValue = this.getShapValue(label);
    //   return shapValue >= 0 ? '#8ce38f' : '#FF8184';
    // },

    getconfidenceBarColor() {
      const confidencePercentage = this.ML_confidence * 100;
      3;
      if (this.ML_prediction === true) {
        return `linear-gradient(to right, #8ce38f ${confidencePercentage}%, #FF8184 ${confidencePercentage}%)`;
      } else if (this.ML_prediction === false) {
        return `linear-gradient(to right, #FF8184 ${confidencePercentage}%, #8ce38f ${confidencePercentage}%)`;
      } else {
        return "#FF8184";
      }
    },

    getFieldName(label) {
      for (let page of this.pages) {
        for (let field of page.field) {
          if (field.label === label) {
            return field.fieldName;
          }
        }
      }
      return null;
    },

    async closemodal() {
      this.showFeatures = false;
    },
    async changeStatus(id, status) {
      let statusChange = "";
      if (status == true) {
        statusChange = false;
      } else {
        statusChange = true;
      }
      await axios
        .get(`${this.baseUrl}changestatus?Id=${id}&status=${statusChange}`)
        .then((res) => {
          this.changeItem = res.data;
          if (this.changeItem == "Updated") {
            alert("Screening status changed");

            this.totalCount();
            this.screeeInfo();
          }
          console.log("changeItem", this.changeItem);
        })
        .catch((err) => console.log("error in screen list", err));
    },
    async screeeInfo() {
      this.totalPage = 1;
      this.isLoading = true; // Start loading
      if (
        !this.screenInfos.ML_confidenceMin ||
        this.screenInfos.ML_confidenceMin === ""
      ) {
        this.screenInfos.ML_confidenceMin = 0;
      }
      if (
        !this.screenInfos.ML_confidenceMax ||
        this.screenInfos.ML_confidenceMax === ""
      ) {
        this.screenInfos.ML_confidenceMax = 1;
      }
      this.screenInfos.Template_id = this.templateId;
      try {
        const res = await axios.post(
          `${this.baseUrl}screeningresult`,
          this.screenInfos
        );
        this.screenItem = res.data.data;
        let count = res.data.count;
        console.log("COUNT IS", this.resultCount);
        console.log("page", count, "/", this.screenInfos.ItemCount);
        this.totalPage = Math.ceil(count / this.screenInfos.ItemCount);
      } catch (err) {
        console.log("error in screen list", err);
      } finally {
        this.isLoading = false; // End loading
      }
    },
    loadPage(page) {
      this.currentPage = page;
      this.screenInfos.PageNo = page;
      //this.totalCount();
      this.screeeInfo();
      window.scrollTo(0, 0);
    },
    async searchScreeeInfo() {
      this.currentPage = 1;
      this.screenInfos.PageNo = 1;
      await this.screeeInfo();
    },
    async getPagesInfo() {
      this.templateFieldDetails = [];
      await axios
        .get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=${this.version}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.fields = [];
          this.features = {};
          if (res.data) {
            let newFeatures = {};
            res.data.pages.forEach((page) => {
              this.fields.push(page.field);
            });
            this.fields[0].forEach((field) => {
              Object.entries(this.newfeaturesValue).forEach(([key, value]) => {
                if (key === field.fieldName) {
                  newFeatures[field.label] = value;
                }
              });

              Object.entries(newFeatures).forEach(([key, value]) => {
                if (field.type === "dropdown" || field.type === "radio") {
                  field.options.forEach((option) => {
                    if (newFeatures[key] == option.codevalue) {
                      console.log("Entered");
                      newFeatures[key] = option.optionname;
                    }
                  });
                }
              });
            });
            this.changeHeight = false;
            this.tableloadingBlock = false;
            this.features = newFeatures;
            this.pages = res.data.pages;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
