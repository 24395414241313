import axios from "axios";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import jwt_decode from "jwt-decode";
export default {
    name: "dataExport",
    components: {
        VPagination
      },
    async mounted() {
        this.defaultdate = localStorage.getItem("dateformat");
        this.idToken = store.getters.getIdToken;
        this.Headers = {
            headers: {
                Authorization: "Bearer " + this.idToken,
                "Content-Type": "application/json",
            },
        }
        window.addEventListener("studyIdChanged", (event) => {
            console.log("StudyIDChanged into" + event.detail.storage);
            this.rollsprivilages = store.getters.getRolesprivilegeData;
            this.setToDefault();
            this.listSites();
            this.listVisits();
            
        });
        this.rollsprivilages = await store.getters.getRolesprivilegeData;
        await this.listSites();
        await this.listVisits();
        await this.listAllStudy();
        await this.listAudit();
    },

    data() {
        return {
            baseUrl: process.env.VUE_APP_Service_URL,
            baseAPI: process.env.VUE_APP_Service_URL + "/management",
            idToken: "",
            Headers: {},
            currentStudy: store.getters.getStudyIs,
            siteList: [],
            subjectList: [],
            visitList: [],
            studyList:[],
            aduitList:[],
            selectedSite: "",
            selectedSubject: "",
            selsectedVisit: "",
            fileName:"",
            selectedStudy:"",
            disablePatient: true,
            rollsprivilages: "",
            pageNumber: 1,
            pageIndex:1,
            pageSize:10,
            currentPage:1,
            SortProperty:"createdAt",
            SortOrder:0,
            search:{
                fromDate:"",
                toDate:""
            }
        }
    },

    methods: {
      formatTime(timestamp) {
        return moment(timestamp).format('h:mm A');
      },
        async listSites() {
            await axios.get(`${this.baseAPI}/site/getallsitesbyuser?studyId=${this.selectedStudy}&sortProperty: siteCode`, this.Headers)
                .then((res) => {
                    console.log("sitelist...", res.data);
                    this.siteList = res.data;

                })
                .catch(err => console.log("error in gettting sitelist", err));
        },
        async listSubjects() {
            await axios.get(`${this.baseAPI}/patient/listpatient?SiteId=${this.selectedSite}&Studyid=${this.selectedStudy}`, this.Headers)
                .then((res) => {
                    console.log("Subject list...", res.data.results);
                    this.subjectList = res.data.results;

                })
                .catch(err => console.log("error in gettting subject list", err));
        },
        async listVisits() {
            await axios.get(`${this.baseAPI}/visit/listvisit?StudyId=${this.currentStudy}`, this.Headers)
                .then((res) => {
                    console.log("Visit list...", res.data.results);
                    this.visitList = res.data.results;
                })
                .catch(err => console.log("error in gettting Visit list", err));
        },
        async listAllStudy() {
          const usermail = jwt_decode(this.idToken).email;
            await axios.get(`${this.baseAPI}/study/getallstudy?user=${usermail}&api-version=1.0`, this.Headers)
                .then((res) => {
                    this.studyList = res.data;
                    console.log("Study list...", res);
                })
                .catch(err => console.log("error in gettting Visit list", err));
        },
        async listAudit() {
          var isDes = true;
      isDes = this.SortOrder == 0 ? true : false;
      console.log("IS Descending is ", isDes);
            await axios
          .get(`${this.baseUrl}/forms/cdisc/listaudit?&pageindex=${this.pageIndex}&SortProperty=exportedAt&SortOrder=1&pagesize=${this.pageSize}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
            this.aduitList = res.data.entities;
          this.totalItems = parseInt(res.data.totalCount / this.pageSize);
          if (res.data.totalCount % this.pageSize != 0) {
            this.totalItems = this.totalItems + 1;
          }
         
          console.log("The aduit list",this.aduitList);
          
        })
        .catch((err) => {
          console.log(err);
        });
        },
        async SearchDate() {
          var isDes = true;
          isDes = this.SortOrder == 0 ? true : false;
          console.log("IS Descending is ", isDes);
            await axios
          .get(`${this.baseUrl}/forms/cdisc/listaudit?&ExportedAtStart=${this.search.fromDate}&ExportedAtEnd=${this.search.toDate}&pageindex=${this.pageIndex}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}&pagesize=${this.pageSize}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
            this.aduitList = res.data.entities;
          this.totalItems = parseInt(res.data.totalCount / this.pageSize);
          if (res.data.totalCount % this.pageSize != 0) {
            this.totalItems = this.totalItems + 1;
          }
         
          console.log("The aduit list",this.aduitList);
          
        })
        .catch((err) => {
          console.log(err);
        });
        },
        async SiteChanged() {
            this.selectedSubject = "";
            await this.listSubjects();
            this.disablePatient = false;
        },
    SortSelected(sortProp, id) {
      console.log("SortPropChanged");
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc;
        if (this.SortOrder == 0) {

          // if (selectedElement.classList.contains("sortDesc")) {
          //   return;

          // } 
           if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } 
          // else if (selectedElement.classList.contains("sortAsc")) {
          //   return;

          // } 
          else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.SearchDate();
    },
        async exportData() {
            if (this.selectedStudy=="") {
                alert('Please select a study')
            }
            else {
                const idtoken = store.getters.getIdToken;
                await axios.get(`${this.baseUrl}/forms/cdisc/export?studyId=${this.selectedStudy}&siteId=${this.selectedSite}&patientId=${this.selectedSubject}&exportFileName=${this.fileName}`,
                {
                    headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/octet-stream"
                    },
                    responseType: 'arraybuffer',
                  })
                    .then((res) => {
                      const contentDispositionHeader = res.headers['filename'];
                      // Extract the filename and format from the header
                      // const filenameMatch = contentDispositionHeader ? contentDispositionHeader.match(/filename="(_\d+)\.([^"]+)"/) : null;
                      console.log(contentDispositionHeader,"File Name match")
                      // const filename = filenameMatch ? `${filenameMatch[1]}_${filenameMatch[2]}` : 'downloaded_file.octet-stream';
                      const format = contentDispositionHeader 
                      
                      const jsonData = res.data;
                      
                      // Create a Blob from the JSON data
                      const blob = new Blob([jsonData], { type: 'application/octet-stream' });
                      
                      // Create a link element
                      const link = document.createElement('a');
                      
                      // Set the link's attributes
                      link.href = window.URL.createObjectURL(blob);
                      link.download = `${contentDispositionHeader}`;
                      
                      // Append the link to the document
                      document.body.appendChild(link);
                      
                      // Trigger a click on the link to initiate the download
                      link.click();
                      
                      // Remove the link from the document after a short delay
                      setTimeout(() => {
                          document.body.removeChild(link);
                          window.URL.revokeObjectURL(link.href);
                      }, 100);
                      this.listAudit()
                    })
                    .catch((err) => {
                       
                        if(err.response)
                        {
                            console.log("Error in data export", err.response.data);
                            // const jsonString = String.fromCharCode.apply(null, err.response.data);
                            // Step 1: Convert ArrayBuffer to Uint8Array
                            const uint8Array = new Uint8Array( err.response.data);
                            
                            // Step 2: Convert Uint8Array to string
                            const jsonString = String.fromCharCode.apply(null, uint8Array);
                            
                            // Step 3: Parse the string as JSON
                            const jsonObject = JSON.parse(jsonString);
                            
                            // Now 'jsonObject' contains the JSON representation of the original ArrayBuffer
                            if(jsonObject.errors.DomainValidations[0]==='No CDISC json found for this visit')
                            {
                                alert("No CDISC json found for this visit")
                            }
                        }
                        
                        
                    });
            }
        },
        async setToDefault() {
            this.disablePatient = true;
            this.selsctedSite = "";
            this.selectedSubject = "";
            this.selsectedVisit = "";
            this.selectedStudy = "";
        },
        filter(data) {
            if (data) {
              return moment(data).format(this.defaultdate)
            }
          },
          loadPage:function(page) {
            this.currentPage = page;
            this.pageIndex=page;
            this.listAudit();
          },
    },
};