<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t('navigations.users') }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-0 my-lg-0">
        <div
          class="
            d-flex
            flex-column flex-lg-row
            justify-content-lg-start
            my-2 my-lg-0
            mb-lg-0
          "
        >
          <div class="col-lg-5 my-1 pl-lg-0 pr-lg-1">
            <div class="form-group mb-md-0">
              <label class="mb-0">{{ $t('navigations.email or name') }}</label>
              <input
                type="email"
                class="form-control my-md-2 my-lg-0"
                v-model="searchData.searchEmailorName"
              />
            </div>
          </div>
          <div class="col-lg-5 my-1 pr-lg-1">
            <div class="form-group mb-md-0">
              <label class="mb-0"> {{$t('navigations.role')}}</label>
              <select
                class="form-select my-md-2 my-lg-0 setheight"
                name="account"
                v-model="searchData.searchRole"
              >{{ $t('navigations.search') }}
                <option value="All">{{$t('navigations.all')}}</option>
                <option
                  v-for="role in roleList"
                  :key="role.roleName"
                  :value="role.roleName"
                >
                  {{ role.roleName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-2 my-1 align-self-end">
            <span type="button" class="save_btn w-100" @click.prevent="search()">
              {{ $t('navigations.search') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5>{{$t('navigations.users list')}}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>

                <th
                  class="sort_block"
                  @click="SortSelected('firstName', 1)"
                  id="1"
                >
                  <div class="d-flex flex-row justify-content-between">       
                    {{$t('navigations.name')}}       
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <!-- <th>{{$t('navigations.name')}}</th> -->

                
                <th
                  class="sort_block"
                  @click="SortSelected('email', 2)"
                  id="2"
                >
                  <div class="d-flex flex-row justify-content-between">   
                    {{$t('navigations.email')}}           
                                   <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                </th>
                <!-- <th>{{$t('navigations.email')}}</th> -->
                
                
                
                <th>{{$t('navigations.role')}}</th>
                <th>{{$t('navigations.assigned sites')}}</th>


                <th
                  class="sort_block"
                  @click="SortSelected('createdByName', 3)"
                  id="3"
                >
                  <div class="d-flex flex-row justify-content-between">         
                    {{$t('navigations.created by')}}     
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <!-- <th>{{$t('navigations.created by')}}</th> -->

                <th
                  class="sort_block"
                  @click="SortSelected('createdDate', 4)"
                  id="4"
                >
                  <div class="d-flex flex-row justify-content-between">    
                    {{$t('navigations.created date')}}          
                                   <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                </th>
                <!-- <th>{{$t('navigations.created date')}}</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in userList" :key="user.email">
                <td>{{ user.firstName + " " + user.lastName }}</td>
                <td>{{ user.email }}</td>
                <td>
                  <span v-for="(roles, index) in user.role" :key="index"
                    >{{ index == 0 ? roles : "," + roles }}
                  </span>
                </td>
<!-- site more or less sliced -->
                <td v-if="!checkvalues[user.email]">
                  <span
                    v-for="(sites, index) in user.assignedSite.slice(0, 3)"
                    :key="index"
                    ><span v-if="index == 0">
                      {{ sites }}
                    </span>
                    <span v-else>
                      <span v-if="index % 5 == 0"><br /></span>
                      {{ "," + sites }}
                    </span>
                  </span>
                  <a
                    @click="formattedBody(user.email)"
                    v-if="user.assignedSite.length > 3"
                  >... <span class="more">{{ $t('navigations.more') }}
                  </span> </a>
                </td>
                <!-- site more or less -->
                <td v-if="checkvalues[user.email]">
                  <span
                    v-for="(sites, index) in user.assignedSite"
                    :key="index"
                    ><span v-if="index == 0">
                      {{ sites }}
                    </span>
                    <span v-else>
                      <span v-if="index % 5 == 0"><br /></span>
                      {{ "," + sites }}
                    </span>
                  </span>
                  <a
                    @click="formattedBody(user.email)"
                    v-if="user.assignedSite.length > 3"
                  >
                    <span class="more"> &nbsp; {{$t('navigations.less')}}
                  </span>  </a>
                </td>
                <td>{{ user.createdByName }}</td>
                <td>
                  {{
                    filter(user.createdDate)
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <!------ pagination -------->
          <div class="col-lg-12 mx-0 px-0">
            <div class="row mx-0">
              <div
                class="dataTables_paginate paging_simple_numbers pagination justify-content-end"
              >
                <v-pagination
                  v-model="currentPage"
                  :pages="totalitems"
                  :range-size="1"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import auth0 from "auth0-js";
var webAuth = new auth0.WebAuth({
  domain: "dev-datamatica.eu.auth0.com",
  clientID: "R4ggiTZw7Z8WSrmzfoKxUmv09oJZp93j",
  scope: "openid profile email offline_access",
  responseType: "token id_token",
  useRefreshTokens: true,
});
export default {
  name: "StudyBasedUserList",
  components: {
    VPagination,
  },
  data() {
    return {
      defaultdate: "",
      checkvalue: true,
      checkvalues: {},
      baseurl: process.env.VUE_APP_Service_URL,
      searchData: {
        searchEmailorName: "",
        searchRole: "",
      },
      roleList: [],
      userList: [],


      sortDesc:"",
      SortOrder:1,
      SortProperty:'firstName',
    };
  },
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    window.addEventListener("studyIdChanged", (event) => {
      this.Userlisting();
    });
    await this.setRoleList();
    await this.Userlisting();
  },
  methods: {
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
    },

    async Userlisting() {
      var isDes = true;
      isDes = this.SortOrder == 0 ? true : false;
      
      const idtoken = store.getters.getIdToken;
      const stdid = store.getters.getStudyIs;
      await axios
        .get(  
          `${this.baseurl}/account-core/user/getstudybasedusers?studyid=${stdid}&rolename=${this.searchData.searchRole}&EmailorName=${this.searchData.searchEmailorName}&pageindex=1&pagesize=10&SortProperty=${this.SortProperty}&SortOrder=0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.userList = res.data.entities;
        });
    },

    async search() {
      if(this.searchData.searchRole=="All") {
        this.searchData.searchRole=""
      }
      this.Userlisting();
    },
    SortSelected(sortProp, id) {
      console.log("SortPropChanged");
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc;
        if (this.SortOrder == 0) {

          // if (selectedElement.classList.contains("sortDesc")) {
          //   return;
          // } 

           if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          }
           else selectedElement.classList.add("sortDesc");
        } 
        else
         {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } 
          
          // else if (selectedElement.classList.contains("sortAsc"))
          //  {
          //   return;
          // } 
          else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.Userlisting();
    },
    async setRoleList() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/account-core/roles/rolelist`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.roleList = res.data;
        });
    },

    formattedBody(val) {
      if(this.checkvalues[val] == true){
        this.checkvalues[val] = false
      }
      else{
        this.checkvalues[val] = true
      }
      this.checkvalue = !this.checkvalue;
    },
    loadPage: function (page) {
      this.currentPageNumber = page;
      this.pageindex = page;
      this.Userlisting();
    },
  },
};
</script>

<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.form-group {
  margin-bottom: 0 !important;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.form-select {
  border-radius: .5rem !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
.assigned-block {
  width: 30px;
}
.more {
 font-size: 14px;
color: #618BD7;
}
</style>
