<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-6">
      <h2>{{ $t("navigations.patient retention") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <ul class="nav nav-tabs col-lg-12" role="tablist">
            <li>
              <a href="#tab-1" 
                class="nav-link" 
                :class="{activeTab: refreshFlags['predictionReport'] === true,}"
                data-toggle="tab" 
                @click="handleTabClick('predictionReport')">
                  {{ $t("navigations.prediction report") }}
              </a>
            </li>
            <li>
              <a href="#tab-2" class="nav-link"
                :class="{ activeTab: refreshFlags['analyticalReport'] === true,}" data-toggle="tab"
                @click="handleTabClick('analyticalReport')"
              >
                {{ $t("navigations.data analysis") }}
              </a>
            </li>
          </ul>
        </div>
        <div class="ibox-content row" v-if="refreshFlags['predictionReport']">
          <!-- Cards -->
          <div class="col-lg-3 pr-0">
            <div class="ibox mb-0">
              <div class="ibox-title style_2 px-3 bg-info">
                <h5 class="text-white">Total Subjects</h5>
              </div>
              <div class="ibox-content">
                <h1 class="no-margins text-muted">
                  {{ retentionCount.total_count }}
                </h1>
                <small>Total Subjects</small>
              </div>
            </div>
          </div>
          <div class="col-lg-3 pr-0">
            <div class="ibox mb-0">
              <div class="ibox-title style_2 px-3 blue-bg">
                <h5 class="text-white">Possible to retain</h5>
              </div>
              <div class="ibox-content">
                <h1 class="no-margins text-muted">
                  {{ retentionCount.true_count }}
                </h1>
                <div class="stat-percent font-bold txt-blu">
                  {{ retainedPercentage }}%
                </div>
                <small>Subjects</small>
              </div>
            </div>
          </div>
          <div class="col-lg-3 pr-0">
            <div class="ibox mb-0">
              <div class="ibox-title style_2 px-3 red-bg">
                <h5 class="text-white">Possible to dropout</h5>
              </div>
              <div class="ibox-content">
                <h1 class="no-margins text-muted">
                  {{ retentionCount.false_count }}
                </h1>
                <div class="stat-percent font-bold text-danger">
                  {{ dropoutPercentage }}%
                </div>
                <small>Subjects</small>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="ibox mb-0">
              <div class="ibox-title style_2">
                <h5>Performance Metrics</h5>
              </div>
              <div class="ibox-content pr-content height">
                <div class="row text-left">
                  <div class="col-3">
                    <small class="stats-label">Accuracy</small>
                    <h4>{{ accuracy }}</h4>
                  </div>
                  <div class="col-3">
                    <small class="stats-label">Precision</small>
                    <h4>{{ precision }}</h4>
                  </div>
                  <div class="col-3">
                    <small class="stats-label">Recall</small>
                    <h4>{{ recall }}</h4>
                  </div>
                  <div class="col-3">
                    <small class="stats-label">F1Score</small>
                    <h4>{{ f1_score }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox-content row mb-1 px-3 py-2" v-if="refreshFlags['predictionReport']">
          <div class="form-group">
            <div class="d-flex flex-column flex-lg-row 
                        justify-content-lg-start
                        my-2 my-lg-0 mb-lg-0">
              <div class="col-lg-4 my-1 pl-lg-0 pr-lg-1">
                <div class="form-group mb-md-0">
                  <label class="mb-0">AI Prediction</label>
                  <select class="form-select my-md-2 my-lg-0 setheight" name="account" placeholder=""
                    v-model="searchData.ML_prediction">
                    <option :value="null">All</option>
                    <option value="true">Possible to retain</option>
                    <option value="false">Possible to dropout</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 my-1 pl-lg-0 pr-lg-1">
                <div class="form-group mb-md-0">
                  <label class="mb-0">Confidence Min</label>
                  <input type="text" class="form-control my-md-2 my-lg-0" @keypress="validateKeypress"
                    v-model="searchData.ML_confidenceMin" />
                </div>
              </div>
              <div class="col-lg-3 my-1 pl-lg-0 pr-lg-1">
                <div class="form-group mb-md-0">
                  <label class="mb-0">Confidence Max</label>
                  <input type="text" class="form-control my-md-2 my-lg-0" @keypress="validateKeypress"
                    v-model="searchData.ML_confidenceMax" />
                </div>
              </div>
              <div class="col-12 col-lg-2 my-1 align-self-end">
                <span type="button" class="save_btn w-100" @click.prevent="getPatientRetentionInfo()">
                  Search
                </span>
              </div>
            </div>
          </div>
          <div>
            <!-- Loading Animation -->
            <div v-if="isLoading" class="text-center">
              <img src="../../assets/img/loading.webp" class="table-load" width="30" alt="loading" />
            </div>

            <!-- Table Content -->
            <div v-else class="table-responsive" :class="{ 'height-500': retentionDetails.length != 0 }">
              <table class="table table-striped table-bordered screen-table">
                <thead>
                  <th>{{ $t("navigations.ref id") }}</th>
                  <th>{{ $t("navigations.ai prediction") }}</th>
                  <th>{{ $t("navigations.confidence") }}</th>
                  <th class="text-center">{{ $t("navigations.view") }}</th>
                </thead>
                <tbody>
                  <tr v-for="retention in retentionDetails" :key="retention._id">
                    <td>{{ retention.foreignKey }}</td>
                    <td>{{ retention.ML_prediction ? 'Possible to retain' : 'Possible to dropout' }}</td>
                    <td>{{ retention.ML_confidence }}</td>
                    <td class="text-center">
                      <i class="fa fa-eye edit-delete" @click="
                          ViewFeatures(
                            retention.features,
                            retention.feature_interaction,
                            retention.ML_confidence,
                            retention.ML_prediction
                          )
                        ">
                      </i>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- pagination -->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div class="dataTables_paginate paging_simple_numbers pagination float-right">
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination v-model="currentPage" :pages="totalPage" active-color="#618bd7"
                    @update:modelValue="loadPage" />
                </div>
              </div>
            </div>
          </div>
          <!-- pagination ends -->
        </div>
      </div>
      <div class="tabpanel" v-if="refreshFlags['analyticalReport']">
        <RetentionDashboard/>
      </div>
    </div>
  </div>
  <!-- Patient details popup -->
  <teliport to="#app">
    <div v-if="showFeatures == true" class="Retention_modal__wrapper">
      <div class="Retention_modal__container">
        <div class="titleclass d-flex justify-content-between py-2 px-3">
          <h4 class="my-0">{{ $t("navigations.patient details") }}</h4>
          <div class="cursor-pointer">
            <i class="fa fa-times" @click.prevent="closemodal()"></i>
          </div>
        </div>
        <div class="summary-heading px-3 py-0">
          <div class="row pt-2">
            <div class="col-3 text-left">
              <small class="stats-label">Prediction</small>
              <h4>{{ ML_prediction === true ? "Possible to retain" : "Possible to dropout" }}</h4>
            </div>
            <div class="col-6 text-left"> 
               <small class="stats-label">Confidence:</small> <span style="font-weight: bold;"> {{ ML_confidence }}</span>
                <div class="confidence">
                  <div
                    class="status-bar"
                    :style="{
                      backgroundImage: getconfidenceBarColor(),
                      width: '100%',
                      height: '12px',
                    }"
                  >
                  </div>
                </div>
            </div>
            <div class="col-3">
                <small class="stats-label">Posibility</small>
                  <div class="status-box">
                  <div class="box-color" style="background-color: #8ce38f; width:12px; height:12px;"></div>
                  <div class="box-color" style="background-color: #FF8184; width:12px; height:12px;"></div>
                </div>
            </div>
          </div>
        </div>
        <div
          class="patientenrollment__modal__content p-3"
          :class="{
            'height-100': changeHeight == true,
            'max-height-500': changeHeight == false,
            'align-items-center': changeHeight == true,
          }"
        >
          <div v-if="tableloadingBlock" class="text-center">
            <img
              src="../../assets/img/loading.webp"
              class="table-load"
              width="30"
              alt="loading"
            />
          </div>
          <table v-else class="table features-table">
            <thead>
              <th>Label</th>
              <th>Value</th>
              <th>Relative Feature Impacts</th>
            </thead>
            <tr v-for="item in featuresWithShap" :key="item.key">
              <td class="py-1">{{ item.key }}</td>
              <td class="py-1">{{ item.value }}</td>
              <td class="py-1">
                <div class="shap-bar-container">
                  <div
                    class="shap-bar"
                    :style="{
                      width: getShapBarWidth(item.key) + '%',
                      backgroundColor: getShapBarColor(item.key),
                    }"
                  ></div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </teliport>
</template>
<script src="./PatientRetention.js"></script>

<style scoped>
.txt-blu {
  color: #1c84c6;
}

.height {
  height: 103px;
}

.activeTab {
  color: #495057;
  background-color: #fff !important;
  border-color: #dee2e6 #dee2e6 #fff;
}

.align-end {
  position: absolute !important;
}

.max-height-500 {
  overflow-y: scroll;
  height: 500px;
}

.upload_btn {
  text-align: center;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
  padding: 0.43rem 0.4rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: all 0.3s ease-in-out !important;
}

.upload_btn img {
  mix-blend-mode: color-dodge;
}

.height-100 {
  height: 535px;
}

.Retention_modal__container {
  width: min(45%, 90%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
}

.titleclass {
  color: #ffffff;
  background: #768dd3;
  z-index: 2000;
}

.height-500 {
  max-height: 500px;
  overflow-y: scroll;
}

.screen-table thead th {
  background-color: hsl(0, 0%, 100%);
  border-top: 1px solid #e7eaec;
  position: sticky;
  top: 0;
}

.Retention_modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
}

.features-table thead th {
  width: 60%;
}

.table-load {
  mix-blend-mode: difference;
  filter: brightness(0.5);
}

.loading-img {
  mix-blend-mode: lighten;
}

.pr-content {
  min-height: 105px;
}

.txt-blu {
  color: #1c84c6;
}

.shap-bar-container {
  width: 100px;
  height: 16px;
  border: 1px solid #ccc;
  overflow: hidden;
  background-color: #fdfcfc;
  border-radius: 3px;
  padding: 3px 3px !important;
}

.shap-bar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  line-height: 20px;
}

.status-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.status-box {
  display: flex;
  align-items: center;
  gap: 5px;
}

.box-color {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  /* Optional: border for better visibility */
}

.confidence {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.confidence p {
  margin: 0;
}

.status-bar {
  width: 120px;
  height: 20px;
  border: 1px solid #ccc;
  margin-top: 3px;
}
</style>