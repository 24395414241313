<template>
  <div class="maincontainer">
    <div v-for="input in htmlarray" :key="input.folder_Id">
      <div
        class="ibox-content mainbgchange mainbgchangewidth getdfcontentss"
        v-for="(item, key) in input.pages"
        :key="key"
      >
        <div class="row">
          <div class="col-0 col-sm-4 col-md-4 col-lg-2 mb-2 d-none d-sm-block">
            <img
              class="float-left"
              src="../../assets/img/tnt_logo_sm.png"
              alt=""
            />
          </div>
          <div class="col-12 col-sm-4 col-md-4 col-lg-8 text-center mb-2">
            <h3 class="form-header">{{ formHeader }} </h3>
          </div>
          <div class="col-0 col-sm-4 col-md-4 col-lg-2 mb-2 d-none d-sm-block">
            <img
              class="float-right"
              src="../../assets/img/client_Logo.png"
              alt=""
            />
          </div>
        </div>
        <div id="formElements mt-3 ">
          <form>
            <div class="form-group">
              <div>
                <div class="pageContainer mt-2">
                  <div>
                    <div
                      class="groupsdiv mainbgchange"
                      v-for="(groupno, key) in groups[pageno]"
                      :key="key"
                    >
                      <div class="flexContainer mainbgchange">
                        <template
                          v-for="(newfield, key) in item.field"
                          :key="key"
                        >
                          <div
                            class="flex-sub-container mainbgchange"
                            v-if="newfield.groupOrderNo == groupno"
                          >
                            <!-- headder starts -->
                            <div
                              v-if="newfield.type == 'header'"
                              class="headerdiv"
                            >
                              <h5 class="headdertext align-self-center mb-0">{{ newfield.label }}</h5>
                            </div>
                            <!-- headder ends -->
                            <!-- input starts -->
                            <div
                              v-else-if="inputElements.includes(newfield.type)"
                              class="flex-item"
                              @load="testfun()"
                            >
                              <baseinput
                                v-if="newfield.privilege != 'Mask'"
                                @click.prevent="
                                  testvarfun(
                                    newfield,
                                    formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                    item.pageNo, 0,''
                                  )"
                                v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                                 :requiredname='newfield.validation.includes("Required") ? "Required" : ""'
                                :fromarchive="fromArchive"
                                :label="newfield.label"
                                :inputtype="newfield.type"
                                type="text"
                              />
                              <span v-if="isvalidate" class="errormsg">{{
                                errormsg[newfield.fieldName]
                              }}</span>
                            </div>
                            <!-- input ends -->
                            <!-- date element starts -->
                            <div
                              v-else-if="newfield.type == 'date'"
                              class="flex-item"
                              @load="testfun()"
                            >
                              <basedate
                                v-if="newfield.privilege != 'Mask'"
                                @click.prevent="
                                  testvarfun(
                                    newfield,
                                    formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                    item.pageNo, 0,''
                                  )
                                "
                                :classname="errorclasslist[newfield.fieldName]"
                                v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                                :requiredname='newfield.validation.includes("Required") ? "Required" : ""'
                                :label="newfield.label"
                                type="text"
                                :fromarchive="fromArchive"
                              />
                              <span class="errormsg">{{
                                errormsg[newfield.fieldName]
                              }}</span>
                            </div>
                            <!-- date element ends -->
                            <!-- calculation starts -->
                            <div
                                      v-else-if="newfield.type == 'calculation'"
                                      class="flex-item"
                                    >
                                    <div class="d-flex p-2">
                                      <div class="labelclass">
                                        {{ newfield.label }}
                                      </div>
                                      <div hidden>
                                      {{ calculateResult(item.pageNo, newfield.options, newfield.fieldName) }}
                                      </div>
                                      <div class="labelclass ml-2">
                                      {{ calculatedresult[newfield.fieldName] }}
                                    </div>
                                  </div>
                            </div>
                            <!-- calculation ends -->
                            <!-- dropdown menu starts -->
                            <div
                              v-else-if="newfield.type == 'dropdown'"
                              class="flex-item"
                            >
                              <baseselect
                                v-if="newfield.privilege != 'Mask'"
                                @click.prevent="
                                  testvarfun(
                                    newfield,
                                    formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                    item.pageNo, 0,''
                                  )
                                "
                                :requiredname='newfield.validation.includes("Required") ? "Required" : ""'
                                :fromarchive="fromArchive"
                                :options="newfield.options"
                                :privilege="newfield.privilege"
                                v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                                :label="newfield.label"
                              />
                              <span v-if="isvalidate" class="errormsg">{{
                                errormsg[newfield.fieldName]
                              }}</span>
                            </div>
                            <!-- dropdown menu ends -->
                            <!-- Grid starts -->
                            <div
                                    class="elem"
                                    v-else-if="newfield.type == 'grid'"
                                    @click.prevent="inputfieldedit(newfield, groupno)"
                                  >
                                  <div class="notesstyle gridbackground">
                                      <div class="ibox-content-edit flextable gridbackground">
                                        <div class="addfieldtable">
                                          <div
                                            class="rowcolmn"
                                            v-for="(gridrow, index) in gridoptarray[newfield.fieldName][0].Rows"
                                            :key="gridrow"
                                          >
                                            <div class="rowlabelgrid rowcolmnc">
                                              {{ gridrow }}
                                            </div>
                                            <div
                                              class="rowcolmnc"
                                              v-for="gridcolumn in gridoptarray[newfield.fieldName][0].Columns"
                                              :key="gridcolumn"
                                            >
                                              <div v-if="index == 0">
                                                {{ gridcolumn }}
                                              </div>
                                              <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'text'">
                                              <input
                                                class="form-control"
                                                type="text"
                                                v-model="gridlinkeddatas[newfield.fieldName][gridrow+gridcolumn]"
                                              />
                                              </div>
                                              <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'number'">
                                              <input
                                                class="form-control"
                                                type="number"
                                                v-model="gridlinkeddatas[newfield.fieldName][gridrow+gridcolumn]"
                                              />
                                              </div>
                                              <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'date'">
                                              <input
                                                class="form-control"
                                                type="date"
                                                v-model="gridlinkeddatas[newfield.fieldName][gridrow+gridcolumn]"
                                              />
                                              </div>
                                              <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'time'">
                                              <input
                                                class="form-control"
                                                type="time"
                                                v-model="gridlinkeddatas[newfield.fieldName][gridrow+gridcolumn]"
                                              />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                            </div> 
                            <!-- Grid ends -->
                            <!-- text area starts -->
                            <div
                              class="elem"
                              v-else-if="
                                (newfield.type == 'description') |
                                  (newfield.type == 'textarea')
                              "
                            >
                              <div
                                class="txtarealabel"
                                v-if="newfield.privilege != 'Mask'"
                              >
                                <label
                                  :for="newfield.fieldName"
                                  class="textsize"
                                  >{{ newfield.label }}<sup v-if="newfield.validation.includes('Required')"><i class="fa fa-asterisk imp"></i></sup></label
                                >
                              </div>
                              <div
                                class="txtar"
                                v-if="newfield.privilege != 'Mask'"
                              >
                                <textarea
                                  @click.prevent="
                                    testvarfun(
                                      newfield,
                                      formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                      item.pageNo, 0,''
                                    )
                                  "
                                  :id="newfield.fieldName"
                                  :name="newfield.fieldName"
                                  class="textbox"
                                  v-model="
                                    formdata[`pageNo${item.pageNo}`][
                                      newfield.fieldName
                                    ]
                                  "
                                />

                                <span v-if="isvalidate" class="errormsg">{{
                                  errormsg[newfield.fieldName]
                                }}</span>
                              </div>
                            </div>
                            <!-- text area ends -->
                            <!-- notes starts -->
                            <div
                              class="elem"
                              v-else-if="newfield.type == 'notes'"
                            >
                              <div>
                                <i>{{ newfield.label }}</i><sup v-if="newfield.validation.includes('Required')"><i class="fa fa-asterisk imp"></i></sup>
                              </div>
                            </div>
                            <!-- notes ends -->
                            
                            <!-- checkboxes starts -->
                            <div
                              v-else-if="newfield.type == 'checkbox'"
                              class="form-group sticktobottom"
                            >
                              <label class="labelstyle">&nbsp;</label>
                              <div
                                v-if="newfield.privilege != 'Mask'"
                                class="checkboxentry"
                                @click.prevent="
                                  testvarfun(
                                    newfield,
                                    formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                    item.pageNo, 0,''
                                  )
                                "
                              >
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  :id="newfield.fieldName"
                                  :name="newfield.fieldName"
                                  v-model="
                                    formdata[`pageNo${item.pageNo}`][
                                      newfield.fieldName
                                    ]
                                  "
                                /><span class="checklabel">
                                  {{ newfield.label }}
                                </span>
                              </div>
                              <span v-if="isvalidate" class="errormsg">{{
                                errormsg[newfield.fieldName]
                              }}</span>
                            </div>
                            <!-- checkboxes ends -->
                            <!-- radio button starts -->
                            <div
                              v-else-if="newfield.type == 'radiohorizondal'"
                              class="form-group sticktobottom"
                            >
                              <div
                                v-if="newfield.privilege != 'Mask'"
                                @click.prevent="
                                  testvarfun(
                                    newfield,
                                    formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                    item.pageNo, 0,''
                                  )
                                "
                              >
                                <baseradiogroup
                                  :fromarchive="fromArchive"
                                  :label="newfield.label"
                                  :name="newfield.fieldName"
                                  :options="newfield.options"
                                  orientation="horizondal"
                                  :requiredname='newfield.validation.includes("Required") ? "Required" : ""'
                                  v-model="
                                    formdata[`pageNo${item.pageNo}`][
                                      newfield.fieldName
                                    ]
                                  "
                                >
                                </baseradiogroup>
                              </div>
                              <span v-if="isvalidate" class="errormsg">{{
                                errormsg[newfield.fieldName]
                              }}</span>
                            </div>
                            <!-- radio button ends -->
                            <!-- radio button starts -->
                            <div
                              v-else-if="newfield.type == 'radio'"
                              class="form-group sticktobottom"
                            >
                              <div
                                v-if="newfield.privilege != 'Mask'"
                                @click.prevent="
                                  testvarfun(
                                    newfield,
                                    formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                    item.pageNo, 0,''
                                  )
                                "
                              >
                                <baseradiogroup
                                  :fromarchive="fromArchive"
                                  :label="newfield.label"
                                  :name="newfield.fieldName"
                                  :options="newfield.options"
                                  :requiredname='newfield.validation.includes("Required") ? "Required" : ""'
                                  v-model="
                                    formdata[`pageNo${item.pageNo}`][
                                      newfield.fieldName
                                    ]
                                  "
                                >
                                </baseradiogroup>
                              </div>
                              <span v-if="isvalidate" class="errormsg">{{
                                errormsg[newfield.fieldName]
                              }}</span>
                            </div>
                            <!-- radio button ends -->
                            <!-- subHeader starts -->
                            <div
                              v-else-if="newfield.type == 'subHeader'"
                              class="subheadder"
                            >
                              <h4>{{ newfield.label }}</h4>
                            </div>
                            <!-- subHeader ends -->
                          </div>
                        </template>
                      </div>
                    </div>

                    <!------ pagination -------->
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- test pdf ends -->
  </div>
  <div class="maincontainertwo"></div>
  <!-- <button class="btn" type="button"  @click="printFacture()" >
                  <span class="pointer">
                    Generate pdf
                  </span>
              </button> -->
  <div
    v-if="this.closedoption != 'displayform'"
    class="row wrapper border-bottom white-bg page-heading"
  >
    <div class="col-lg-10">
      <h2>{{ $t("navigations.view form") }}</h2>
    </div>

    <div class="col-lg-2">
      <i class="fa fa-times close my-2" @click="onclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox mb-lg-0">
      <div class="ibox-content form-bg">
        <div class="row">
          <div class="col-sm-12 col-lg-8 mb-4 mb-lg-auto">
            <div
              class="row my-0 p-0"
              v-for="form in forms"
              :key="form.folderId"
            >
              <div class="col-sm-3 col-lg-4 mt-1">
                <div class="text-left">
                  {{ $t("navigations.status") }} : &nbsp;
                  <span
                    class="label label-primary"
                    v-if="receivedData[0].formStatus == 'Verified'"
                    >{{ receivedData[0].formStatus }}</span
                  >
                  <span class="label label-danger" v-else>{{
                    receivedData[0].formStatus
                  }}</span>
                  <!--<h5>Priority : &nbsp <span class="label label-warning float-right"> Unverified</span></h5>
                    <h5>Form status : &nbsp <span class="label label-danger float-right"> Verified</span></h5>
                    <h5>Priority : &nbsp <span class="label label-success float-right"> Low</span></h5>-->
                </div>
              </div>
              <div
                class="col-sm-5 col-lg-4 text-left mt-1"
                v-if="receivedData[0].formStatus == 'Verified'"
              >
                {{ $t("navigations.verified by") }} :
                <span>
                  <strong>{{ form.verifiedBy }}</strong></span
                >
              </div>
              <div
                class="col-sm-4 col-md-4 text-left mt-1"
                v-if="receivedData[0].formStatus == 'Verified'"
              >
                {{ $t("navigations.verified date") }}:
                <span>
                  <strong>{{ filter(form.verifiedAt) }}</strong></span
                >
              </div>
            </div>
          </div>
          <div
            class="
              col-sm-12 col-lg-4
              d-flex
              flex-lg-row
              align-items-center
              justify-content-lg-end
              mx-lg-0
              my-lg-0
            "
            v-if="isqueryfield"
          >
            <div>
              <span v-if="fromArchive == 'false'">
                <span
                  class="btn btn-rverify float-right ml-1"
                  v-if="
                    isVerified != 'Verified' &&
                    roleprivileges.includes('Forms Form Verify')
                  "
                  @click="checkFormFieldsStatus()"
                >
                  <span class="pointer"> {{ $t("navigations.verify") }}</span>
                  <i class="fa fa-check"></i>
                </span>
              </span>
              <span v-if="fromArchive == 'false'">
                <span
                  v-if="roleprivileges.includes('Forms Form Save')"
                  class="btn btn-rverify float-right ml-1"
                  type="button"
                >
                  <span class="pointer">
                    {{ $t("navigations.form save") }}
                  </span>
                  <i class="fa fa-floppy-o"></i>
                </span>
              </span>
              <span v-if="fromArchive == 'false'">
                <span
                  v-if="roleprivileges.includes('Forms Form Query')"
                  class="btn btn-rquery float-right ml-1"
                  type="button"
                  @click="gotoraiseQuary('formQuery')"
                >
                  <span class="pointer"
                    >{{ $t("navigations.form query") }}
                  </span>
                  <i class="fa fa-question"></i>
                </span>
              </span>
              <span v-if="fromArchive == 'false'">
                <button
                  v-if="roleprivileges.includes('Forms Download pdf')"
                  class="btn btn-rverify float-right mr-1"
                  type="button"
                  @click="printFacture()"
                >
                  <span class="pointer">
                    {{ $t("navigations.download pdf") }}
                  </span>
                  <i class="fa fa-file-pdf-o"></i>
                </button>
              </span>
              <!-- <a href="#" class="notification">
  <span>Inbox</span>
  <span class="badge">3</span>
</a> -->
              <span v-if="fromArchive == 'false'">
                <button
                  v-if="roleprivileges.includes('Forms Open Query')"
                  class="btn btn-danger float-right mr-1 tooltipvisibility"
                  type="button"
                  @click="FormQueryDetails(`Open`)"
                >
                  <span class="pointer">
                    {{ $t("navigations.open queries") }} ({{
                      formOpenQueryCount
                    }})
                  </span>
                  <img src="../../assets/images/open_query.png" alt="" /><span
                    class="badge"
                    >{{ formOpenQueryCount }}</span
                  >
                </button>
              </span>
              <span v-if="fromArchive == 'false'">
                <button
                  v-if="roleprivileges.includes('Forms Closed Query')"
                  class="btn btn-info float-right mr-1 tooltipvisibility"
                  type="button"
                  @click="FormQueryDetails(`Closed`)"
                >
                  <span class="pointer">
                    {{ $t("navigations.closed queries") }} ({{
                      formClosedQueryCount
                    }})
                  </span>
                  <img src="../../assets/images/closed_query.png" /><span
                    class="badge"
                    >{{ formClosedQueryCount }}</span
                  >
                </button>
              </span>
              <span v-if="fromArchive == 'false'">
                <button
                  v-if="roleprivileges.includes('Forms Upload')"
                  class="btn btn-rsquare float-right mr-1"
                  type="button"
                >
                  <i class="fa fa-upload"></i>
                </button>
              </span>
              <!--  -->
              <span v-if="fromArchive == 'false' && roleprivileges.includes('Forms Upload')">
                <button
                  class="btn btn-info float-right mr-1 tooltipvisibility"
                  type="button"
                  @click="UploadDocumentModal()"
                >
                  <span class="pointer">
                    {{ $t("navigations.upload document") }}
                  </span>
                  <img src="../../assets/images/Form_upload.png" />
                </button>
              </span>

              <span v-if="fromArchive == 'false' && roleprivileges.includes('Forms View Documents')">
                <button
                  class="btn btn-info float-right mr-1 tooltipvisibility"
                  type="button"
                  @click="ViewDocumentModal()"
                >
                  <span class="pointer">
                    {{ $t("navigations.view documents") }}
                  </span>
                  <img src="../../assets/images/form_view.png" />
                </button>
              </span>
              <span v-if="fromArchive == 'false' && roleprivileges.includes('Forms Archive')">
                <button
                  class="btn btn-danger float-right mr-1 tooltipvisibility"
                  @click="archive()"
                  type="button"
                >
                  <span class="pointer">
                    {{ $t("navigations.archive") }}
                  </span>
                  <i class="fa fa-archive" aria-hidden="true"></i>
                </button>
              </span>
              <!--  -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content mb-1 form-bg">
      <div class="row">
        <div class="col-md-12 col-lg-8">
          <div class="row">
            <div class="col-sm-3 col-md-3 col-lg-3">
              {{ $t("navigations.site code") }} :
              <span>
                <strong>{{ sitesId }}</strong></span
              >
            </div>
            <div class="col-sm-4 col-md-4 col-lg-5 text-left">
              {{ $t("navigations.subject id") }} :
              <span>
                <strong>{{ patientsId }}</strong></span
              >
            </div>
            <div class="col-sm-5 col-md-5 col-lg-4 text-left">
              {{ $t("navigations.visit name") }} :
              <span>
                <strong>{{ formName }}</strong></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- OPen And Close Query Count Icon -->
    <!-- <div class="d-flex justify-content-lg-end">
              <a class="linkstyle hoverstyle" @click="FormQueryDetails(`Open`)">
                Open Query({{formOpenQueryCount}})
              </a>
                <a class="linkstyle hoverstyle" @click="FormQueryDetails(`Closed`)">
                Closed Query({{formClosedQueryCount}})
              </a>
            </div> -->

    <!-- ends -->
    <div class="ibox-content mb-1">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group my-lg-0">
            <label
              class="
                col-lg-12 col-form-label
                float-left
                px-lg-0
                pb-lg-1
                pt-lg-0
              "
              >{{ $t("navigations.page name") }}</label
            >
            <!-- <button class="btn" type="button"  @click="handleClickSignIn">
              <span class="pointer">
              synch
            </span>
            <i class="fa fa-question"></i>
          </button> -->
            <select
              class="col-lg-9 form-select"
              @change="onpagechange($event.target.value)"
              name="account"
              placeholder="Search"
            >
              <option
                v-for="page in pages"
                :key="page.pageNumber"
                :value="page.pageNumber"
                :selected="page.pageNumber == pageno"
              >
                {{ page.pageName }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-4 mx-lg-0" v-if="isqueryfield">
          <span v-if="fromArchive == 'false'">
            <span
              v-if="
                roleprivileges.includes('Forms Page Save') &&
                receivedData[0].pages[pageno - 1].pageStatus != 'Validated'
              "
              class="btn btn-rverify float-right ml-1"
              type="button"
              @click="onpagesave()"
            >
              <span class="pointer"> {{ $t("navigations.page save") }} </span>
              <i class="fa fa-floppy-o"></i>
            </span>
          </span>
          <span v-if="fromArchive == 'false'">
            <span
              v-if="roleprivileges.includes('Forms Page Query')"
              class="btn btn-rquery float-right ml-1"
              type="button"
              @click="gotoraiseQuary('pageQuery')"
            >
              <span class="pointer"> {{ $t("navigations.page query") }} </span>
              <i class="fa fa-question"></i>
            </span>
          </span>
          <span
            v-if="
              roleprivileges.includes('Forms sync') &&
              closedoption == 'IOTPatients'
            "
            class="btn btn-rsync float-right ml-1"
            type="button"
            @click="handleClickSignIn()"
          >
            <span class="pointer"> {{ $t("navigations.sync") }} </span>
            <i class="fa fa-refresh"></i>
          </span>
          <!-- to sign -->
          <span
            v-if="(signStatus == `Unsigned` || signStatus == null) && roleprivileges.includes('Forms Sign')"
            class="btn btn-rsquare float-right mr-1 tooltipvisibility"
            type="button"
            @click="signatureClick(`Signed`)"
          >
            <span class="pointer"> {{ $t("navigations.sign") }} </span>
            <i class="fa fa-pencil"></i>
          </span>
          <!-- to unsign
          <span v-if="signStatus == `Signed`"
            class="btn btn-danger float-right mr-1 tooltipvisibility"
            type="button" @click="signatureClick(`Unsigned`)" >
            <span class="pointer"> {{ $t("navigations.sign") }} </span>
            <i class="fa fa-pencil"></i>
          </span> -->
        </div>

        <!-- <div class="col-md-12 mt-2">
            <span class="mb-1" v-if="signStatus == `Signed`"
              >This form was signed on {{filter(signdate)}} at {{signtime}} by {{signedby}}
              </span
            ><span class="float-right" v-if="loggedinUser && signStatus == `Signed`"
              ><button type="button" @click="signatureClick(`Unsigned`)" class="btn btn-outline btn-warning py-0">
                Unsign
              </button></span
            >
          </div> -->
      </div>
    </div>
    <div class="ibox-sign mb-2" v-if="signStatus == `Signed`">
      <div class="row">
        <div class="col-md-12">
          <span class="mb-1"
            ><img src="../../assets/img/icons/sign.png" alt="sign_icon" /> 
            This form was signed on {{ filter(signdate) }} at {{ formatTime(signtime)}} by
            {{ signedby }} </span
          ><span
            v-if="loggedinUser && signStatus == `Signed`"
            class="float-right"
            @click="signatureClick(`Unsigned`)"
          >
            <a href="#"><u>unsign</u></a></span
          >
        </div>
      </div>
    </div>
    <div class="ibox-content mainbgchange bottommargin" id="getdfcontent">
      <div class="row">
        <div class="col-0 col-sm-4 col-md-4 col-lg-2 mb-2 d-none d-sm-block">
          <img
            class="float-left"
            src="../../assets/img/tnt_logo_sm.png"
            alt=""
          />
        </div>
        <div class="col-12 col-sm-4 col-md-4 col-lg-8 text-center mb-2">
          <h3 class="form-header">{{ formHeader }}</h3>
        </div>
        <div class="col-0 col-sm-4 col-md-4 col-lg-2 mb-2 d-none d-sm-block">
          <!-- <img
            class="float-right"
            src="../../assets/img/client_Logo.png"
            alt=""
          /> -->
        </div>
      </div>
      <div id="formElements mt-3 ">
        <form>
          <div
            class="form-group"
            v-for="input in htmlarray"
            :key="input.folder_Id"
          >
            <div v-for="(item, key) in input.pages" :key="key">
              <div
                v-if="item.pageNo == pageno"
                class="pageContainer mt-2 mainbgchange"
              >
                <div>
                  <div
                    class="groupsdiv"
                    v-for="(groupno, key) in groups[pageno]"
                    :key="key"
                  >
                    <div class="flexContainer mainbgchange">
                      <template
                        v-for="(newfield, key) in item.field"
                        :key="key"
                      >
                        <div
                          class="flex-sub-container mainbgchange"
                          v-if="newfield.groupOrderNo == groupno"
                        >
                          <!-- headder starts -->
                          <div
                            v-if="newfield.type == 'header'"
                            class="headerdiv text-center"
                          >
                            <h5 class="headdertext align-self-center mb-0">{{ newfield.label }}</h5>
                          </div>
                          <!-- headder ends -->
                          <!-- input starts -->

                          <div
                            v-else-if="inputElements.includes(newfield.type)"
                            class="flex-item"
                            @load="testfun()"
                          >
                            <baseinput
                              v-if="newfield.privilege != 'Mask'"
                              @click.prevent="
                                testvarfun(
                                  newfield,
                                  formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                  item.pageNo, 0,''
                                )
                              "
                              v-model="
                                formdata[`pageNo${item.pageNo}`][newfield.fieldName]"
                              :classname="{
                                'form-control hightlight ':
                                  fieldName == newfield.fieldName,
                                'form-control ':
                                  fieldName != newfield.fieldName,
                                borderValidated: newfield.status == 'Validated',
                                borderIncorrect: newfield.status == 'Incorrect',
                                borderFail: newfield.status == 'Fail',
                              }"
                              :label="newfield.label"
                              :inputtype="newfield.type"
                              :requiredname='newfield.validation.includes("Required") ? "Required" : ""'
                              :privilege="newfield.privilege"
                              :fromarchive="fromArchive"
                              type="text"
                            />

                            <span v-if="isvalidate" class="errormsg">{{
                              errormsg[newfield.fieldName]
                            }}</span>
                          </div>
                          <!-- input ends -->
                          <!-- file upload starts -->
                          <div
                            v-else-if="newfield.type == 'uploadfile'"
                              class="flex-item"
                            >
                              <div class="labelclass">
                                {{ newfield.label }} <sup v-if="newfield.validation.includes('Required')"><i class="fa fa-asterisk imp"></i></sup>
                              </div>
                              <div hidden>
                              {{
                                getdownloadFilename(newfield.fieldName,formdata[`pageNo${item.pageNo}`][
                                  newfield.fieldName
                                ])
                              }}
                            </div> 
                              <div class="fileuploadbtm">
                                      <div @click.prevent="
                                  testvarfun(
                                    newfield,
                                    formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                    item.pageNo, 0,''
                                  )
                                ">
                                <div class="default-text">{{downloadedfilenames[newfield.fieldName]}}</div>
                              </div>
                                    <button class="btn btn-primary btn-sm" @click.prevent="downloaduploadedfile(formdata[`pageNo${item.pageNo}`][
                                  newfield.fieldName
                                ])">download</button>
                                    </div>
                            </div>
                            <!-- file upload ends -->
                            <!-- repeated measure starts -->
                            <div v-else-if="newfield.type == 'repeatedmeasure' || newfield.type == 'RepeatingMeasure' || newfield.type == 'repeatingfield'">
                              <!-- Repeating label -->
                              <div class="text-center">
                                  <h5 class="headerdiv" :class="{'cursor-pointer': fromUrlStatus===true}" >{{ newfield.label }}<sup v-if="newfield.validation.includes('Required')"><i class="fa fa-asterisk imp"></i></sup></h5>
                              </div>
                              <!-- End of repeating label -->
                              <div
                              v-for="(fields,rfieldIndex) in formdata[`pageNo${item.pageNo}`][newfield.fieldName]"
                                    :key="rfieldIndex"
                                    class="flexContainer mainbgchange"
                            >
                            <div  class="groupsdiv mainbgchange" v-for="(repgroupno, key) in repeatedgroupgroupnos[
                            newfield.fieldName
                          ]"
                          :key="key">
                          <div  class="flexContainer mainbgchange">
                              <template
                                v-for="field,fieldindex in fields.fields"
                                :key="field.groupOrderNo"
                              >
                                <div
                                  v-if="field.groupOrderNo == repgroupno"
                                  class="flex-sub-container mainbgchange"
                                >
                                  <!-- Repeating header -->
                                  <div v-if="field.type == 'header'"  class="headerdiv">
                                    <div class="text-center">
                                      <h5 class="headdertext align-self-center mb-0" :class="{'cursor-pointer': fromUrlStatus===true}">{{ field.label }}</h5>
                                    </div>
                                  </div>
                                  <!-- End of repeating header -->
                                  <!-- Repeating sub header -->
                                  <div v-else-if="field.type == 'subHeader'"
                                       class="subheadder"
                                      :class="{'cursor-pointer': fromUrlStatus===true}"
                                  >
                                            <h4>{{ field.label }}</h4>
                                  </div>
                                  <!-- End of repeating sub header -->
                                  <!-- Repeating summary starts -->
                                  <div
                                    class="ml-2 pb-2"
                                    :class="{
                                      hightlight: fieldName == field.fieldName,
                                      borderValidated: field.status == 'Validated',
                                      borderIncorrect: field.status == 'Incorrect',
                                      borderFail: field.status == 'Fail',
                                    }"
                                    v-else-if="field.type == 'summary'"
                                  >
                                    <div>
                                      <i>{{ field.label }}</i>
                                    </div>
                                  </div>
                                   <!-- Repeating notes ends -->
                                   <!-- scales starts -->
                                    <div
                                      v-else-if="field.type == 'scales'"
                                      :class="{'cursor-pointer': fromUrlStatus===true}"
                                      @click.prevent="testvarfun(
                                            field,
                                            formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value,
                                            item.pageNo, 
                                            fields.fieldIdentifier,
                                            newfield.fieldName
                                          )"
                                      class="flex-item text-start"
                                      
                                     >
                                      <label class="labelclass mr-2" :class="{'cursor-pointer': fromUrlStatus===true}" >
                                        {{field.label}}<sup v-if='field.validation.includes("Required")'><i class="fa fa-asterisk imp"></i></sup>
                                      </label>

                                      <div class="d-flex"> 
                                        <h6>{{ (field.limit[0].min ? field.limit[0].min : 0) }}</h6>
                                        <input
                                          type="range"
                                          :class="{'cursor-pointer': fromUrlStatus===true}"
                                          :min="parseFloat(field.limit[0].min)"
                                          :max="parseFloat(field.limit[0].max)"
                                          v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value"
                                          step="1"
                                        /><h6>{{ field.limit[0].max }}</h6></div>
                                        <input
                                          class="form-control"
                                          :class="{
                                  'borderValidated form-control':
                                  field.status == 'Validated',
                                  'borderIncorrect form-control':
                                  field.status == 'Incorrect',
                                  'borderFail form-control':
                                  field.status == 'Fail',
                                }"
                                          oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                          type="number"
                                          v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value"
                                          :min="parseFloat(field.limit[0].min)"
                                          :max="parseFloat(field.limit[0].max)"
                                        /> 
                                      
                               
                                    </div>
                                    <!-- scales ends -->
                                   <!-- radio button starts -->
                                   <div
                                        v-else-if="field.type == 'radiohorizondal'"
                                        class="form-group sticktobottom"
                                      >
                                        <div
                                          @click.prevent="
                                          testvarfun(
                                                field,
                                                formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value,
                                                item.pageNo, 
                                                fields.fieldIdentifier,
                                                newfield.fieldName
                                              )"
                                          
                                        >                                        
                                          <baseradiogroup
                                            :fromarchive="fromArchive"
                                            :label="field.label"
                                            :name="field.fieldName"
                                            :options="field.options"
                                            :class="{
                                                hightlight: fieldName == field.fieldName,
                                                borderValidated:
                                                  field.status == 'Validated',
                                                borderIncorrect:
                                                  field.status == 'Incorrect',
                                                borderFail: field.status == 'Fail',
                                              }"
                                            :requiredname='field.validation.includes("Required") ? "Required" : ""'
                                            orientation="horizondal"
                                            v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value"/>
                                        <span v-if="isvalidate" class="errormsg">{{errormsg[field.fieldName]}}</span>
                                      </div>
                                   </div>
                                   <!-- radio button ends -->
                                  <div
                                          v-else-if="inputElements.includes(field.type)"
                                          class="flex-item"
                                          :class="{'cursor-pointer': fromUrlStatus===true}"
                                          @load="testfun()"
                                          @click.prevent = "
                                            testvarfun(
                                                field,
                                                formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value,
                                                item.pageNo, 
                                                fields.fieldIdentifier,
                                                newfield.fieldName
                                              )"
                                        >
                                          <baseinput
                                            :class="{'cursor-pointer': true}"
                                            :classname="{
                                              'form-control hightlight ':
                                                fieldName == field.fieldName,
                                              'form-control ':
                                                fieldName != field.fieldName,
                                              borderValidated: field.status == 'Validated',
                                              borderIncorrect: field.status == 'Incorrect',
                                              borderFail: field.status == 'Fail',
                                            }"
                                            :sourceValue="fromUrlStatus"
                                            v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value"
                                            :label="field.label"
                                            :inputtype="field.type"
                                            :requiredname='field.validation.includes("Required") ? "Required" : ""'
                                            type="text"
                                          />
                                          <span v-if="isvalidate" class="errormsg">{{errormsg[field.fieldName]}}</span>
                                  </div>
                                  <!-- Repeating date -->
                                  <div v-else-if="field.type == 'date'"
                                       class="flex-item"
                                       :class="{'cursor-pointer': fromUrlStatus===true}"
                                      @load="testfun()"
                                      @click.prevent = "
                                            testvarfun(
                                              field,
                                              formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value,
                                                item.pageNo, 
                                                fields.fieldIdentifier,
                                                newfield.fieldName
                                              )"
                                  >
                                          <basedate
                                          :requiredname='field.validation.includes("Required") ? "Required" : ""'
                                          :sourceValue="fromUrlStatus"
                                            :class="{'cursor-pointer': fromUrlStatus===true}"
                                            v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value"
                                            :classname="{
                                              'form-control':
                                                !errorclasslist[field.fieldName],
                                              'form-control errorlist':
                                                errorclasslist[field.fieldName] ==
                                                'form-control errorlist',
                                              'borderValidated form-control':
                                                field.status == 'Validated',
                                              'borderIncorrect form-control':
                                                field.status == 'Incorrect',
                                              'borderFail form-control':
                                                field.status == 'Fail',
                                            }"
                                            :label="field.label"
                                            type="text"
                                          />
                                          <span v-if="isvalidate" class="errormsg">{{
                                            errormsg[field.fieldName]
                                          }}</span>          
                                  </div>
                                  <!-- End of repeating date -->
                                    <!-- qr code starts -->
                                    <div
                                      class="elem"
                                      v-else-if="field.type == 'qrcode'"
                                    >
                                    <div hidden>
                                        {{
                                          qrcodefun(
                                            field.fieldName,
                                            field.qrData
                                          )
                                        }}
                                      </div> 
                                      <div class="labelstyle mb-2">  {{ field.label }} </div>   
                                            <img :src="qrcodetxt[field.fieldName]" title="qr code"/>
                                    </div>
                                    <!-- qr code ends -->
                                     <!-- numberanddate starts -->
                                     <div
                                      v-else-if="field.type == 'numberanddate'"
                                      class="flex-item single-line-input"
                                    >
                                    <div hidden>
                                    {{
                                      repeatednumbanddatefocusoutfun(
                                        field.fieldName+fields.fieldIdentifier
                                      )
                                    }}
                                  </div>
                                      <label class="labelclass">
                                      {{ field.label }}<sup v-if="field.validation[0] == 'required' || field.validation[0] == 'Required'"><i class="fa fa-asterisk imp"></i></sup>
                                      </label>
                                      <div class="twoitemflex input-container">
                                        <input
                                          :label="field.label"
                                          @click.prevent="
                                              testvarfun(
                                                field,
                                                formdata[`pageNo${item.pageNo}`][field.fieldName],
                                                item.pageNo,fields.fieldIdentifier,newfield.fieldName,
                                                repeatednumberanddateval[field.fieldName+fields.fieldIdentifier].numberdata,
                                                repeatednumberanddateval[field.fieldName+fields.fieldIdentifier].datedata
                                              )
                                            "
                                          type="number"
                                          :class="{
                                            'form-control number-input':
                                              !errorclasslist[field.fieldName],
                                            'form-control errorlist number-input':
                                              errorclasslist[field.fieldName] ==
                                              'form-control errorlist number-input',
                                            'borderValidated form-control number-input':
                                              field.status == 'Validated',
                                            'borderIncorrect form-control number-input':
                                              field.status == 'Incorrect',
                                            'borderFail form-control number-input':
                                              field.status == 'Fail',
                                          }"
                                          v-model="repeatednumberanddateval[field.fieldName+fields.fieldIdentifier].numberdata"
                                        />
                                        <input
                                          :label="field.label"
                                          @click.prevent="
                                              testvarfun(
                                                field,
                                                formdata[`pageNo${item.pageNo}`][field.fieldName],
                                                item.pageNo,fields.fieldIdentifier,newfield.fieldName,
                                                repeatednumberanddateval[field.fieldName+fields.fieldIdentifier].numberdata,
                                                repeatednumberanddateval[field.fieldName+fields.fieldIdentifier].datedata
                                              )
                                            "
                                          :class="{
                                          'form-control':
                                            !errorclasslist[field.fieldName],
                                          'form-control errorlist':
                                            errorclasslist[field.fieldName] ==
                                            'form-control errorlist date-input',
                                          'borderValidated form-control date-input':
                                            field.status == 'Validated',
                                          'borderIncorrect form-control date-input':
                                            field.status == 'Incorrect',
                                          'borderFail form-control date-input':
                                            field.status == 'Fail',
                                                }"
                                          type="date"
                                          v-model="repeatednumberanddateval[field.fieldName+fields.fieldIdentifier].datedata"
                                        />
                                      </div>
                          </div>
                          <!-- numberanddate ends -->
                                  <!-- text area starts -->
                                  <div
                                    class="elem"
                                    v-else-if="(field.type == 'description') ||(field.type == 'textarea') "
                                  >
                                    <div
                                      class="txtarealabel"
                                      v-if="field.privilege != 'Mask'"
                                    >
                                      <label
                                        :for="field.fieldName"
                                        class="textsize"
                                        >{{ field.label }}<sup v-if="field.validation.includes('Required')"><i class="fa fa-asterisk imp"></i></sup></label
                                      >
                                    </div>
                                    <div
                                      v-if="field.privilege != 'Mask'"
                                      class="txtar"
                                    >
                                      <textarea
                                        @click.prevent="
                                          testvarfun(
                                            field,
                                            formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value,
                                            item.pageNo, 
                                            fields.fieldIdentifier,
                                            newfield.fieldName
                                          )
                                        "
                                        :id="field.fieldName"
                                        :name="field.fieldName"
                                        :disabled="field.privilege == 'View'"
                                        class="textbox"
                                        :class="{
                                          hightlight: fieldName == field.fieldName,
                                          borderValidated:
                                            field.status == 'Validated',
                                          borderIncorrect:
                                            field.status == 'Incorrect',
                                          borderFail: field.status == 'Fail',
                                        }"
                                        v-model="
                                          formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value
                                          "
                                      />

                                      <span v-if="isvalidate" class="errormsg">{{
                                        errormsg[field.fieldName]
                                      }}</span>
                                    </div>
                                  </div>
                                  <!-- text area ends -->
                                  <!-- Repeating year starts -->
                                  <div
                                      v-else-if="field.type == 'year'"
                                      class="flex-item"
                                      :class="{'cursor-pointer': fromUrlStatus===true}"
                                      @click.prevent = "
                                            testvarfun(
                                              field,
                                                formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value,
                                                item.pageNo, 
                                                fields.fieldIdentifier,
                                                newfield.fieldName
                                              )"
                                    >
                                    <div >
                                      <basenumber
                                        :label="field.label"
                                        :id="field.fieldName"
                                        :minValue=1900
                                        :classname="{
                                                'form-control hightlight':
                                                  fieldName == field.fieldName,
                                                'form-control': fieldName != field.fieldName,
                                                borderValidated: field.status == 'Validated',
                                                borderIncorrect: field.status == 'Incorrect',
                                                borderFail: field.status == 'Fail',
                                              }"
                                        :requiredname='field.validation.includes("Required") ? "Required" : ""'
                                        v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value"
                                        type="number"
                                        step="1"
                                        />
                                        </div>
                                    </div>
                                    <!-- Repeating year ends -->
                                  <!-- Repeating dropdown -->
                                  <div v-else-if="field.type == 'dropdown'"
                                       class="flex-item"
                                       :class="{'cursor-pointer': fromUrlStatus===true}"
                                       @click.prevent = "
                                            testvarfun(
                                              field,
                                                formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value,
                                                item.pageNo, 
                                                fields.fieldIdentifier,
                                                newfield.fieldName
                                              )"
                                  >
                                      <baseselect
                                          :requiredname='field.validation.includes("Required") ? "Required" : ""'
                                          :sourceValue="fromUrlStatus"
                                          :class="{'cursor-pointer': fromUrlStatus===true}"
                                          :options="field.options"
                                          :classname="{
                                                'form-control hightlight':
                                                  fieldName == field.fieldName,
                                                'fill-form-select': fieldName != field.fieldName,
                                                borderValidated: field.status == 'Validated',
                                                borderIncorrect: field.status == 'Incorrect',
                                                borderFail: field.status == 'Fail',
                                              }"
                                          v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value"
                                          :label="field.label"
                                      />
                                          <span class="errormsg">{{errormsg[field.fieldName]}}</span>
                                  </div>
                                  <!-- End of Repeating dropdown -->
                                  <!-- Repeating check box -->
                                  <div v-else-if="field.type == 'checkbox'"
                                       class="form-group sticktobottom"
                                       :class="{'cursor-pointer': fromUrlStatus===true}"
                                       @click.prevent = "
                                            testvarfun(
                                              field,
                                                formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value,
                                                item.pageNo, 
                                                fields.fieldIdentifier,
                                                newfield.fieldName
                                              )"
                                  >
                                    <div 
                                    :class="{
                                            hightlight: fieldName == field.fieldName,
                                            borderValidated:
                                              field.status == 'Validated',
                                            borderIncorrect:
                                              field.status == 'Incorrect',
                                            borderFail: field.status == 'Fail',
                                          }">
                                      <input
                                      
                                        type="checkbox"
                                        :id="field.fieldName"
                                        :name="field.fieldName"
                                        :requiredname='field.validation.includes("Required") ? "Required" : ""'
                                        v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value"
                                      />
                                      <span class="labelclass"> {{ field.label }}&nbsp;<sup v-if="field.validation.includes('Required')"><i class="fa fa-asterisk imp px-1"></i></sup></span>
                                    </div>
                                  </div>
                                  <!-- End of repeating checkbox -->
                                  <!-- Repeated radio button starts -->
                                  <div v-else-if="field.type == 'radio'"
                                       class="form-group sticktobottom"
                                       :class="{'cursor-pointer': fromUrlStatus===true}"
                                       @click.prevent = "
                                            testvarfun(
                                              field,
                                                formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value,
                                                item.pageNo, 
                                                fields.fieldIdentifier,
                                                newfield.fieldName
                                              )"
                                  >
                                      <baseradiogroup
                                       
                                        :sourceValue="fromUrlStatus"
                                        :label="field.label"
                                        :name="field.fieldName"
                                        :class="{
                                          hightlight: fieldName == field.fieldName,
                                          borderValidated:
                                            field.status == 'Validated',
                                          borderIncorrect:
                                            field.status == 'Incorrect',
                                          borderFail: field.status == 'Fail',
                                        }"
                                        :requiredname='field.validation.includes("Required") ? "Required" : ""'
                                        :options="field.options"
                                        v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value"
                                      >
                                      </baseradiogroup>
                                      <span class="errormsg">{{errormsg[field.fieldName]}}</span>
                                  </div>
                                  <!-- Repeated radio button ends -->
                                  <!-- Repeated time element starts -->
                                  <div v-else-if="field.type == 'time'" 
                                        class="flex-item bgblue"
                                        @click.prevent = "
                                            testvarfun(
                                              field,
                                                formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value,
                                                item.pageNo, 
                                                fields.fieldIdentifier,
                                                newfield.fieldName
                                              )">
                                  <label class="labelclass float-left">{{ field.label }}<sup v-if='field.validation.includes("Required")'><i class="fa fa-asterisk imp"></i></sup></label>
                                  <input 
                                          :class="{
                                      'form-control':
                                        !errorclasslist[field.fieldName],
                                      'form-control errorlist':
                                        errorclasslist[field.fieldName] ==
                                        'form-control errorlist',
                                      'borderValidated form-control':
                                        field.status == 'Validated',
                                      'borderIncorrect form-control':
                                        field.status == 'Incorrect',
                                      'borderFail form-control':
                                        field.status == 'Fail',
                                    }"
                                    classname="time" 
                                    :label="field.label" type="time"
                                    v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value"/>
                                  </div>
                                  <!-- Repeated time element ends -->
                                  <!-- Repeated dateandtime element starts -->
                                  <div v-else-if="field.type == 'dateandtime' || field.type == 'datetime-local'" 
                                       class="flex-item bgblue"
                                       @click.prevent = "
                                            testvarfun(
                                              field,
                                                formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value,
                                                item.pageNo, 
                                                fields.fieldIdentifier,
                                                newfield.fieldName
                                              )">
                                  <basedatetime
                                    :sourceValue="fromUrlStatus"
                                    :class="{'cursor-pointer': sourceValue===true}"
                                    :classname="{
                                'form-control':
                                  !errorclasslist[field.fieldName],
                                'form-control errorlist':
                                  errorclasslist[field.fieldName] ==
                                  'form-control errorlist',
                                'borderValidated form-control':
                                  field.status == 'Validated',
                                'borderIncorrect form-control':
                                  field.status == 'Incorrect',
                                'borderFail form-control':
                                  field.status == 'Fail',
                              }"
                                    :label="field.label" 
                                    type="datetime-local"
                                    :requiredname='field.validation.includes("Required") ? "Required" : ""'
                                    v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName][rfieldIndex]['fields'][fieldindex].value"
                                  />
                                  <div class="errormsg">{{errormsg[field.fieldName]}}</div>
                                  </div>
                                  <!-- Repeated dateandtime element ends -->
                                  </div>
                              </template>
                              </div>
                              </div>
                              <div class="repeat-border-bottom mb-2 col-md-12">                                
                              </div>
                              </div>
                              
                            </div>
                            <!-- repeatedmeasure ends -->
                            <!-- scales starts -->
                          <div
                            v-else-if="newfield.type == 'scales'"
                            class="flex-item"
                            @click="
                                  testvarfun(
                                    newfield,
                                    formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                    item.pageNo, 0,''
                                  )
                                "
                          >
                            <div class="labelclass float-left mr-2">{{newfield.label}}<sup v-if="newfield.validation.includes('Required')"><i class="fa fa-asterisk imp px-1"></i></sup></div>
                            <div class="d-flex"> <h6>{{ newfield.limit[0].min }}</h6>
                              <input
                                type="range"
                                :min="newfield.limit[0].min"
                                :max="newfield.limit[0].max"                               
                                disabled
                                step="1"                                
                                v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                                :class="{'cursor-pointer': fromUrlStatus===true}"
                              />
                              <h6>{{ newfield.limit[0].max }}</h6></div>
                              <input
                                type="number"
                                :min="newfield.limit[0].min"
                                :max="newfield.limit[0].max"
                                @click.prevent="
                                  testvarfun(
                                    newfield,
                                    formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                    item.pageNo, 0,''
                                  )
                                "
                                :class="{
                                  'form-control':
                                    !errorclasslist[newfield.fieldName],
                                  'form-control errorlist':
                                    errorclasslist[newfield.fieldName] ==
                                    'form-control errorlist',
                                  'borderValidated form-control':
                                    newfield.status == 'Validated',
                                  'borderIncorrect form-control':
                                    newfield.status == 'Incorrect',
                                  'borderFail form-control':
                                    newfield.status == 'Fail',
                                }"
                                v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                              />
                          </div>
                          <!-- scales ends -->
                          <!-- time element starts -->
                          <div
                            v-else-if="newfield.type == 'time'"
                            class="flex-item mainbgchange"
                          >
                            <label class="labelstyle float-left">
                              {{ newfield.label }}<sup v-if="newfield.validation.includes('Required')"><i class="fa fa-asterisk imp"></i></sup>
                            </label>
                            <input
                              :class="{
                                'form-control':
                                  !errorclasslist[newfield.fieldName],
                                'form-control errorlist':
                                  errorclasslist[newfield.fieldName] ==
                                  'form-control errorlist',
                                'borderValidated form-control':
                                  newfield.status == 'Validated',
                                'borderIncorrect form-control':
                                  newfield.status == 'Incorrect',
                                'borderFail form-control':
                                  newfield.status == 'Fail',
                              }"
                              :label="newfield.label"
                              type="time"
                              @click.prevent="testvarfun(
                                  newfield,
                                  formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                  item.pageNo, 0,''
                                )"
                                :requiredname='newfield.validation.includes("Required") ? "Required" : ""'
                              v-model="
                                formdata[`pageNo${item.pageNo}`][
                                  newfield.fieldName
                                ]
                              "
                            />
                          </div>
                          <!-- time element ends -->
                          <!-- date element starts -->
                          <div
                            v-else-if="newfield.type == 'date'"
                            class="flex-item"
                            :class="{
                              hightlight: fieldName == newfield.fieldName,
                            }"
                            @load="testfun()"
                          >
                            <basedate
                              v-if="newfield.privilege != 'Mask'"
                              @click.prevent="
                                testvarfun(
                                  newfield,
                                  formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                  item.pageNo, 0,''
                                )
                              "
                              :classname="{
                                'form-control':
                                  !errorclasslist[newfield.fieldName],
                                'form-control errorlist':
                                  errorclasslist[newfield.fieldName] ==
                                  'form-control errorlist',
                                'borderValidated form-control':
                                  newfield.status == 'Validated',
                                'borderIncorrect form-control':
                                  newfield.status == 'Incorrect',
                                'borderFail form-control':
                                  newfield.status == 'Fail',
                              }"
                              v-model="
                                formdata[`pageNo${item.pageNo}`][
                                  newfield.fieldName
                                ]
                              "
                              :label="newfield.label"
                              :privilege="newfield.privilege"
                              :requiredname='newfield.validation.includes("Required") ? "Required" : ""'
                              :fromarchive="fromArchive"
                              type="text"
                            />
                            {{ errorclasslist[newfield.fieldName] }}
                            <span class="errormsg">{{
                              errormsg[newfield.fieldName]
                            }}</span>
                          </div>
                          <!-- date element ends -->
                          <!-- calculation starts -->
                          <div
                                      v-else-if="newfield.type == 'calculation'"
                                      class="flex-item"
                                    >
                                    <div class="d-flex p-2">
                                      <div class="labelclass">
                                        {{ newfield.label }}
                                      </div>
                                      <div hidden>
                                      {{ calculateResult(item.pageNo, newfield.options, newfield.fieldName) }}
                                      </div>
                                      <div class="labelclass ml-2">
                                      {{ calculatedresult[newfield.fieldName] }}
                                    </div>
                                  </div>
                          </div>
                          <!-- calculation ends -->
                          <!-- Grid starts -->
                          <div
                                    class="elem"
                                    v-else-if="newfield.type == 'grid'"
                                    @click.prevent="inputfieldedit(newfield, groupno)"
                                  >
                                  <div class="notesstyle gridbackground">
                                      <div class="ibox-content-edit flextable gridbackground">
                                        <div class="addfieldtable">
                                          <div
                                            class="rowcolmn"
                                            v-for="(gridrow, index) in gridoptarray[newfield.fieldName][0].Rows"
                                            :key="gridrow"
                                          >
                                            <div class="rowlabelgrid">
                                              {{ gridrow }}
                                            </div>
                                            <div
                                              class="rowcolmnc"
                                              v-for="gridcolumn in gridoptarray[newfield.fieldName][0].Columns"
                                              :key="gridcolumn"
                                            >
                                              <div v-if="index == 0">
                                                {{ gridcolumn }}
                                              </div>
                                              <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'text'">
                                              <input
                                                class="form-control"
                                                type="text"
                                                v-model="gridlinkeddatas[newfield.fieldName][gridrow+gridcolumn]"
                                              />
                                              </div>
                                              <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'number'">
                                              <input
                                                class="form-control"
                                                type="number"
                                                v-model="gridlinkeddatas[newfield.fieldName][gridrow+gridcolumn]"
                                              />
                                              </div>
                                              <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'date'">
                                              <input
                                                class="form-control"
                                                type="date"
                                                v-model="gridlinkeddatas[newfield.fieldName][gridrow+gridcolumn]"
                                              />
                                              </div>
                                              <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'time'">
                                              <input
                                                class="form-control"
                                                type="time"
                                                v-model="gridlinkeddatas[newfield.fieldName][gridrow+gridcolumn]"
                                              />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                          </div> 
                          <!-- Grid ends -->
                          <!-- dateandtime element starts -->
                          <div
                            v-else-if="newfield.type == 'dateandtime' || newfield.type == 'datetime-local'"
                            class="flex-item bgblue"
                          >
                                  <basedatetime 
                                  @click.prevent="
                                testvarfun(
                                  newfield,
                                  formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                  item.pageNo, 0,''
                                )"
                                :requiredname='newfield.validation.includes("Required") ? "Required" : ""'
                                    :sourceValue="fromUrlStatus"
                                    :class="{'cursor-pointer': sourceValue===true}"
                                    :classname="{
                                'form-control':
                                  !errorclasslist[newfield.fieldName],
                                'form-control errorlist':
                                  errorclasslist[newfield.fieldName] ==
                                  'form-control errorlist',
                                'borderValidated form-control':
                                  newfield.status == 'Validated',
                                'borderIncorrect form-control':
                                  newfield.status == 'Incorrect',
                                'borderFail form-control':
                                  newfield.status == 'Fail',
                              }"
                                    :label="newfield.label" 
                                    type="datetime-local"
                                    v-model="
                                            formdata[`pageNo${item.pageNo}`][
                                              newfield.fieldName
                                            ]
                                          "
                                  />
                                  <div class="errormsg">{{errormsg[newfield.fieldName]}}</div>
                              
                          </div>
                          <!-- datetime element ends -->
                          <!-- dropdown menu starts -->
                          <div
                            v-else-if="newfield.type == 'dropdown'"
                            class="flex-item"
                          >
                            <baseselect
                              v-if="newfield.privilege != 'Mask'"
                              @click.prevent="
                                testvarfun(
                                  newfield,
                                  formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                  item.pageNo, 0,''
                                )
                              "
                              :options="newfield.options"
                              :classname="{
                                'form-control hightlight':
                                  fieldName == newfield.fieldName,
                                'fill-form-select': fieldName != newfield.fieldName,
                                borderValidated: newfield.status == 'Validated',
                                borderIncorrect: newfield.status == 'Incorrect',
                                borderFail: newfield.status == 'Fail',
                              }"
                              v-model="
                                formdata[`pageNo${item.pageNo}`][
                                  newfield.fieldName
                                ]
                              "
                              :fromarchive="fromArchive"
                              :label="newfield.label"
                              :requiredname='newfield.validation.includes("Required") ? "Required" : ""'
                              :privilege="newfield.privilege"
                            />
                            <span v-if="isvalidate" class="errormsg">{{
                              errormsg[newfield.fieldName]
                            }}</span>
                          </div>
                          <!-- dropdown menu ends -->
                          <!-- text area starts -->
                          <div
                            class="elem"
                            v-else-if="
                              (newfield.type == 'description') |
                                (newfield.type == 'textarea')
                            "
                          >
                            <div
                              class="txtarealabel"
                              v-if="newfield.privilege != 'Mask'"
                            >
                              <label
                                :for="newfield.fieldName"
                                class="textsize"
                                >{{ newfield.label }}<sup v-if="newfield.validation.includes('Required')"><i class="fa fa-asterisk imp"></i></sup></label
                              >
                            </div>
                            <div
                              v-if="newfield.privilege != 'Mask'"
                              class="txtar"
                            >
                              <textarea
                                @click.prevent="
                                  testvarfun(
                                    newfield,
                                    formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                    item.pageNo, 0,''
                                  )
                                "
                                :id="newfield.fieldName"
                                :name="newfield.fieldName"
                                :disabled="newfield.privilege == 'View'"
                                class="textbox"
                                :class="{
                                  hightlight: fieldName == newfield.fieldName,
                                  borderValidated:
                                    newfield.status == 'Validated',
                                  borderIncorrect:
                                    newfield.status == 'Incorrect',
                                  borderFail: newfield.status == 'Fail',
                                }"
                                v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                              />

                              <span v-if="isvalidate" class="errormsg">{{
                                errormsg[newfield.fieldName]
                              }}</span>
                            </div>
                          </div>
                          <!-- text area ends -->
                          <!-- image starts -->
                          <div
                                    v-else-if="newfield.type == 'image'"
                                    class="flex-item displayflex"
                                    @click.prevent="
                                  testvarfun(
                                    newfield,
                                    formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                    item.pageNo, 0,''
                                  )
                                "
                                  >
                                    <div>
                                      <label class="labelclass col-md-12">
                                        {{ newfield.label }}
                                      </label>
                                    </div>
                                    <div class="d-flex flex-column">    
                                    <input class="form-control col-md-6 mb-2" v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]" type="text"> 
                                    <!-- <baseinput
                                v-if="newfield.privilege != 'Mask'"
                                v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                                :fromarchive="fromArchive"
                                :label="newfield.label"
                                inputtype="text"
                                type="text"
                              /> -->
                                    <img
                                      class="templatepreview max-image"
                                      :src="`${fileuploadurl}/file/${
                                        newfield.imagePath.split(',')[0]
                                      }?versionid=${
                                        newfield.imagePath.split(',')[1]
                                      }`"
                                    />
                                    </div>
                          </div>
                          <!-- image ends -->
                          <!-- notes starts -->
                          <div
                            class="elem"
                            :class="{
                              hightlight: fieldName == newfield.fieldName,
                              borderValidated: newfield.status == 'Validated',
                              borderIncorrect: newfield.status == 'Incorrect',
                              borderFail: newfield.status == 'Fail',
                            }"
                            v-else-if="newfield.type == 'notes'"
                          >
                            <div>
                              <i>{{ newfield.label }}</i>
                            </div>
                          </div>
                          <!-- notes ends -->
                          <!-- year starts -->
                          <div
                            v-else-if="newfield.type == 'year'"
                            class="elem flex-item"
                            
                            @click.prevent="
                                    testvarfun(
                                      newfield,
                                      formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                      item.pageNo,0,''
                                    )
                                  "
                          >
                            <basenumber
                                        :label="newfield.label"
                                        :id="newfield.fieldName"
                                        :minValue=1900
                                        :classname="{
                                                'form-control hightlight':
                                                  fieldName == newfield.fieldName,
                                                'form-control': fieldName != newfield.fieldName,
                                                borderValidated: newfield.status == 'Validated',
                                                borderIncorrect: newfield.status == 'Incorrect',
                                                borderFail: newfield.status == 'Fail',
                                              }"
                                        :requiredname='newfield.validation.includes("Required") ? "Required" : ""'
                                        v-model="
                                formdata[`pageNo${item.pageNo}`][
                                  newfield.fieldName
                                ]
                              "
                                        step="1"
                                        />
                          </div>
                          <!-- year ends -->
                          <!-- summery starts -->
                          <div
                                      class="elem"
                                      v-else-if="newfield.type == 'summary'"
                                    >
                                    
                                    <div><i>
                          <label :for="newfield.fieldName" class="textsize">{{
                            newfield.label
                          }}</label></i>
                        </div>
                     
                          </div>
                          <!-- summery ends -->
                          <!-- qr code starts -->
                          <div
                            class="elem"
                            v-else-if="newfield.type == 'qrcode'"
                          >
                          <div hidden>
                              {{
                                qrcodefun(
                                  newfield.fieldName,
                                  newfield.qrData
                                )
                              }}
                            </div> 
                            <div class="labelstyle mb-2">  {{ newfield.label }} </div>   
                                  <iframe :src="qrcodetxt[newfield.fieldName]" title="qr code">
                              </iframe>
                          </div>
                          <!-- qr code ends -->
                          <!-- numberanddate starts -->
                          <div
                            v-else-if="newfield.type == 'numberanddate'"
                            class="flex-item single-line-input"
                          >
                            <div class="labelclass">
                            {{ newfield.label }}<sup v-if="newfield.validation.includes('Required')"><i class="fa fa-asterisk imp px-1"></i></sup>
                            </div>
                            <div class="twoitemflex input-container">
                              <input
                                :label="newfield.label"
                                @click.prevent="
                                    testvarfun(
                                      newfield,
                                      formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                      item.pageNo, 0,'',
                                      numberanddateval[newfield.fieldName].numberdata,
                                      numberanddateval[newfield.fieldName].datedata
                                    )
                                  "
                                type="number"
                                :class="{
                                  'form-control number-input':
                                    !errorclasslist[newfield.fieldName],
                                  'form-control errorlist number-input':
                                    errorclasslist[newfield.fieldName] ==
                                    'form-control errorlist number-input',
                                  'borderValidated form-control number-input':
                                    newfield.status == 'Validated',
                                  'borderIncorrect form-control number-input':
                                    newfield.status == 'Incorrect',
                                  'borderFail form-control number-input':
                                    newfield.status == 'Fail',
                                }"
                                v-model="numberanddateval[newfield.fieldName].numberdata"
                              />
                              <input
                                :label="newfield.label"
                                @click.prevent="
                                    testvarfun(
                                      newfield,
                                      formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                      item.pageNo, 0,'',
                                      numberanddateval[newfield.fieldName].numberdata,
                                      numberanddateval[newfield.fieldName].datedata
                                    )
                                  "
                                :class="{
                                'form-control date-input':
                                  !errorclasslist[newfield.fieldName],
                                'form-control errorlist date-input':
                                  errorclasslist[newfield.fieldName] ==
                                  'form-control errorlist date-input',
                                'borderValidated form-control date-input':
                                  newfield.status == 'Validated',
                                'borderIncorrect form-control date-input':
                                  newfield.status == 'Incorrect',
                                'borderFail form-control date-input':
                                  newfield.status == 'Fail',
                                      }"
                                type="date"
                                v-model="numberanddateval[newfield.fieldName].datedata"
                              />
                            </div>
                          </div>
                          <!-- numberanddate ends -->
                          <!-- check box starts -->
                                  <div v-else-if="newfield.type == 'checkbox'"
                                       class="form-group sticktobottom mr-2"
                                       :class="{'cursor-pointer': fromUrlStatus===true}"
                                       @click.prevent = "
                                            testvarfun(
                                              newfield,
                                              formdata[`pageNo${item.pageNo}`]
                                              [newfield.fieldName],
                                              item.pageNo, 0,''
                                              )"
                                  >
                                    <div 
                                    :class="{
                                            hightlight: fieldName == newfield.fieldName,
                                            borderValidated:
                                            newfield.status == 'Validated',
                                            borderIncorrect:
                                            newfield.status == 'Incorrect',
                                            borderFail: newfield.status == 'Fail',
                                          }">
                                      <input
                                      
                                        type="checkbox"
                                        :id="newfield.fieldName"
                                        :name="newfield.fieldName"
                                        :requiredname='newfield.validation.includes("Required") ? "Required" : ""'
                                        v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                                      />
                                      <span class="checklabel labelclass">
                                {{ newfield.label }}<sup v-if="newfield.validation.includes('Required')"><i class="fa fa-asterisk imp px-1"></i></sup>
                              </span>
                                    </div>
                                  </div>
                                  <!-- End of checkbox -->
              
                          <!-- radio button starts -->
                            <div
                              v-else-if="newfield.type == 'radiohorizondal'"
                              class="form-group sticktobottom"
                            >
                              <div
                                v-if="newfield.privilege != 'Mask'"
                                @click.prevent="
                                  testvarfun(
                                    newfield,
                                    formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                    item.pageNo, 0,''
                                  )
                                "
                              >
                                <baseradiogroup
                                  :fromarchive="fromArchive"
                                  :label="newfield.label"
                                  :name="newfield.fieldName"
                                  :options="newfield.options"
                                  :class="{
                                  hightlight: fieldName == newfield.fieldName,
                                  borderValidated:
                                    newfield.status == 'Validated',
                                  borderIncorrect:
                                    newfield.status == 'Incorrect',
                                  borderFail: newfield.status == 'Fail',
                                }"
                                  orientation="horizondal"
                                  :requiredname='newfield.validation.includes("Required") ? "Required" : ""'
                                  v-model="
                                    formdata[`pageNo${item.pageNo}`][
                                      newfield.fieldName
                                    ]
                                  "
                                >
                                </baseradiogroup>
                              </div>
                              <span v-if="isvalidate" class="errormsg">{{
                                errormsg[newfield.fieldName]
                              }}</span>
                            </div>
                            <!-- radio button ends -->
                          <!-- radio button starts -->
                          <div
                            v-else-if="newfield.type == 'radio'"
                            class="form-group sticktobottom"
                          >
                            <div
                              v-if="newfield.privilege != 'Mask'"
                              @click.prevent="
                                testvarfun(
                                  newfield,
                                  formdata[`pageNo${item.pageNo}`][newfield.fieldName],
                                  item.pageNo, 0,''
                                )
                              "
                            >
                              <baseradiogroup
                                :fromarchive="fromArchive"
                                :label="newfield.label"
                                :name="newfield.fieldName"
                                :privilege="newfield.privilege"
                                :requiredname='newfield.validation.includes("Required") ? "Required" : ""'
                                :options="newfield.options"
                                :class="{
                                  hightlight: fieldName == newfield.fieldName,
                                  borderValidated:
                                    newfield.status == 'Validated',
                                  borderIncorrect:
                                    newfield.status == 'Incorrect',
                                  borderFail: newfield.status == 'Fail',
                                }"
                                v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                              >
                              </baseradiogroup>
                            </div>
                            <span v-if="isvalidate" class="errormsg">{{
                              errormsg[newfield.fieldName]
                            }}</span>
                          </div>
                          <!-- radio button ends -->
                          <!-- subHeader starts -->
                          <div
                            v-else-if="newfield.type == 'subHeader'"
                            class="subheadder"
                          >
                            <h4>{{ newfield.label }}</h4>
                          </div>
                          <!-- subHeader ends -->
                          <!-- {{fields}} -->
                        </div>
                      </template>
                    </div>
                  </div>
                  <!------ pagination -------->
                  <div class="row mx-0 paginationpadding">
                    <div class="col-lg-12 mx-0 px-0 mt-4">
                      <div
                        class="
                          dataTables_paginate
                          paging_simple_numbers
                          pagination
                          float-right
                        "
                      >
                        <div
                          class="col-lg-12 d-flex justify-content-lg-end px-0"
                        >
                          <v-pagination
                            v-model="pageno"
                            :pages="totalpages"
                            :range-size="1"
                            active-color="#618bd7"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- pagination ends -->
                  <!--
              <div class="buttonContainer">
                <div class="leftbutton">
                  <button
                    v-if="pageno > 1"
                    type="button"
                    class="btn btn-next"
                    @click.prevent="previousFun"
                  >
                    Previous
                  </button>
                </div>
                Page {{ pageno }}
                <div class="rightbutton">
                  <button
                    v-if="pageno < totalpages"
                    type="button"
                    class="btn btn-next"
                    @click.prevent="nextfun"
                  >
                    Next
                  </button>
                  <button
                    v-if="pageno == totalpages && requiredsubmit"
                    type="button"
                    class="btn btn-next"
                    @click.prevent="testfun"
                  >
                    Submit
                  </button>
                </div>
              </div> -->
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- modal starts -->
  <div class="modal__wrapper">
    <div class="modelbackground">
      <div class="modal__containers">
        <div class="titleclass">
          <h4 class="ml-lg-2">{{ $t("navigations.data verification") }}</h4>
          <div class="modalqueryicons">
            <button
              v-if="roleprivileges.includes('Data Verification Open queries')"
              class="btn btn-danger float-right mr-3 tooltipvisibility"
              type="button"
              @click="FieldQueryDetails(`Open`)"
            >
              <img src="../../assets/images/open_query.png" alt="" /><span
                class="badge"
                >{{ openQueryCount }}</span
              >
              <span class="pointer"
                >{{ $t("navigations.open queries") }}({{
                  openQueryCount
                }})</span
              >
            </button>
            <button
              v-if="roleprivileges.includes('Data Verification Closed queries')"
              class="btn btn-info float-right mr-3 tooltipvisibility"
              type="button"
              @click="FieldQueryDetails(`Closed`)"
            >
              <img src="../../assets/images/closed_query.png" /><span
                class="badge"
                >{{ closeQueryCount }}</span
              >
              <span class="pointer"
                >{{ $t("navigations.closed queries") }}({{
                  closeQueryCount
                }})</span
              >
            </button>
          </div>
          <div class="closebutton">
            <a style="color: white" class="close-link">
              <i class="fa fa-times" @click.prevent="closeModal"></i>
            </a>
          </div>
        </div>
        <div id="wrapper">
          <div class="wrapper wrapper-content animated fadeInRight">
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox ibox-body p-3">
                  <!-- OPen And Close Query Count Icon -->
                  <!-- <div class="d-flex justify-content-lg-end">
                                    <a class="linkstyle hoverstyle" @click="FieldQueryDetails(`Open`)">
                                      Open Query({{openQueryCount}})
                                    </a>
                                      <a class="linkstyle hoverstyle" @click="FieldQueryDetails(`Closed`)">
                                      Closed Query({{closeQueryCount}})
                                    </a>
                                  </div> -->
                  <!-- ends -->

                  <div class="ibox-content">
                    <p>{{ modelelement.label }}</p>
                  </div>
                  <div class="form-group row my-3">
                    <label class="col-md-2 col-form-label my-1">{{
                      $t("navigations.field name")
                    }}</label>
                    <div class="col-md-10 my-1">
                      <label class="form-control">{{ modelname }}</label>
                    </div>
                    <!-- input -->
                    <label class="col-md-2 col-form-label my-1">{{
                      $t("navigations.field value")
                    }}</label>
                    <div
                      v-if="inputElements.includes(modeltype)"
                      class="col-md-10 my-1"
                    >
                      <input
                        :disabled="modelprivileges == 'View'"
                        :type="modelelement.type"
                        :class="modelinputclass"
                        placeholder="textbox"
                        v-model="modelvalue"
                      /><span class="errormsg">{{ errormsg[modelname] }}</span>
                    </div>
                    <!-- date element -->
                    <div
                            v-if="modeltype == 'time'" class="col-md-10 my-1"
                          >
                          <input
                            :disabled="modelprivileges == 'View'"
                            type="time"
                            :class="modelinputclass"
                            placeholder="time"
                            v-model="modelvalue"
                          /><span class="errormsg">{{ errormsg[modelname] }}</span>
                          </div>
                    <div v-if="modeltype == 'date'" class="col-md-10 my-1">
                      <input
                        :disabled="modelprivileges == 'View'"
                        type="date"
                        :class="modelinputclass"
                        placeholder="textbox"
                        max="9999-12-31"
                        v-model="modelvalue"
                      /><span class="errormsg">{{ errormsg[modelname] }}</span>
                    </div>
                    <!-- date and time element -->
                    <div v-if="modeltype == 'dateandtime'" class="col-md-10 my-1">
                      <input
                              :class="modelinputclass"
                              type="datetime-local"
                              max="9999-12-31"
                              v-model="modelvalue"
                            /><span class="errormsg">{{ errormsg[modelname] }}</span>
                    </div>
                    <!-- numberanddate element -->
                    <div v-if="modeltype == 'numberanddate'" class="col-md-10 my-1">
                      <div class="twoitemflex">
                        <input
                        :disabled="modelprivileges == 'View'"
                        type="number"
                        :class="modelinputclass"
                        placeholder="Number"
                        v-model="numberVal"
                      /><span class="errormsg">{{ errormsg[modelname] }}</span>
                      <input
                        :disabled="modelprivileges == 'View'"
                        type="date"
                        :class="modelinputclass"
                        placeholder="Date"
                        max="9999-12-31"
                        v-model="dateVal"
                      /><span class="errormsg">{{ errormsg[modelname] }}</span>
                            </div><span class="errormsg">{{ errormsg[modelname] }}</span>
                    </div>
                    <!-- dropdown -->
                    <div v-if="modeltype == 'dropdown'" class="col-md-10 my-1">
                      <select
                        :disabled="modelprivileges == 'View'"
                        class="form-control m-b"
                        name="account"
                        v-model="modelvalue"
                      >
                        <option
                          v-for="opt in modelelement.options"
                          :key="opt"
                          :value="opt.codevalue"
                        >
                          {{ opt.optionname }}
                        </option>
                      </select>
                      <span class="errormsg">{{ errormsg[modelname] }}</span>
                    </div>
                    <!-- radio -->

                    <div v-if="modeltype == 'radio'" :class="modelradioclass">
                      <div>
                        <div
                          v-for="radoptions in modelelement.options"
                          :key="radoptions"
                        >
                          <input
                            :disabled="modelprivileges == 'View'"
                            type="radio"
                            class="form-check-label"
                            :id="radoptions"
                            :name="modelelement.fieldName"
                            :value="radoptions.codevalue"
                            v-model="modelvalue"
                          />
                          {{ radoptions.optionname }}
                        </div>
                      </div>
                    </div>
                    <span
                      v-if="modeltype == 'radio'"
                      class="errormsg radioerror"
                      >{{ errormsg[modelname] }}</span
                    >
                    <!-- year -->
                    <div
                      v-if="(modelelement.type == 'year')"
                      class="col-md-10 my-1"
                    >
                    <input
                              class="form-control"
                              type="number"
                              min="1900"
                              max="2099"
                              step="1"
                              :class="modelinputclass"
                              v-model="modelvalue"
                            />
                    </div>
                    <span
                      v-if="
                        (modelelement.type == 'description') |
                          (modelelement.type == 'textarea')
                      "
                      class="errormsg radioerror"
                      >{{ errormsg[modelname] }}</span
                    >
                    <!-- image image -->
                    <div
                      v-if="(modelelement.type == 'image')"
                      class="col-md-10 my-1"
                    >
                    <input
                              class="form-control"
                              type="text"
                              :class="modelinputclass"
                              v-model="modelvalue"
                            />
                    </div>
                    <span
                      v-if="
                        (modelelement.type == 'description') |
                          (modelelement.type == 'textarea')
                      "
                      class="errormsg radioerror"
                      >{{ errormsg[modelname] }}</span
                    >
                  <!-- scale -->
                      <div
                      v-if="(modelelement.type == 'scales')"
                      class="col-md-10 my-1"
                    >
                    <input
                                type="range"
                                :min = scalevlidatemin
                                :max= scalevalidatemax
                                step="1"
                                class="col-md-12"
                                v-model="modelvalue"
                              />
                              <input
                                type="number"
                                :min = scalevlidatemin
                                :max= scalevalidatemax
                                :class="modelinputclass"
                                v-model="modelvalue"
                              />
                    </div>
                    <span
                      v-if="
                        (modelelement.type == 'description') |
                          (modelelement.type == 'textarea')
                      "
                      class="errormsg radioerror"
                      >{{ errormsg[modelname] }}</span
                    >       
                    <!-- textbox -->
                    <div
                      v-if="
                        (modelelement.type == 'description') |
                          (modelelement.type == 'textarea') 
                      "
                      :class="modelradioclass"
                    >
                      <textarea
                        :disabled="modelprivileges == 'View'"
                        :id="modelelement.fieldName"
                        :name="modelelement.fieldName"
                        class="textbox"
                        v-model="modelvalue"
                      />
                    </div>
                    <span
                      v-if="
                        (modelelement.type == 'description') |
                          (modelelement.type == 'textarea')
                      "
                      class="errormsg radioerror"
                      >{{ errormsg[modelname] }}</span
                    >
                    <!-- checkbox -->
                    <!-- file upload -->
                    <div
                      v-if="
                        (modelelement.type == 'uploadfile')"
                      :class="modelradioclass"
                    >
                      <!-- <textarea
                        :disabled="modelprivileges == 'View'"
                        :id="modelelement.fieldName"
                        :name="modelelement.fieldName"
                        class="textbox"
                        v-model="modelvalue"
                      /> -->
                              <div class="fileuploadbtm d-flex">
                                      <div class="col-md-10 d-flex">
                                <!-- <input
                                
                                  class="form-control"
                                  :label="newfield.label"
                                  type="file"
                                /> -->
                                
                                <h6>{{downloadedfilenames[modelelement.fieldName]}}</h6>
                              </div>
                                </div>
                                    
                                      <div class="col-md-10 d-flex">
                                        <input
                                
                                class="form-control"
                                :label="modelelement.label"
                                type="file"
                                @change="helloButton"
                              />
                              </div>
                            
                    </div>
                    <span
                      v-if="
                        (modelelement.type == 'description') |
                          (modelelement.type == 'textarea')
                      "
                      class="errormsg radioerror"
                      >{{ errormsg[modelname] }}</span
                    >

                    <!-- checkbox -->
                    <div
                      v-if="modeltype == 'checkbox'"
                      :class="modelradioclass"
                    >
                      <input
                        :disabled="
                          modelprivileges == 'View' || fromArchive == 'true'
                        "
                        type="checkbox"
                        :id="modelelement.fieldName"
                        :name="modelelement.fieldName"
                        v-model="modelvalue"
                      /><span class="ml-2">
                        {{ modelelement.label }}
                      </span>
                    </div>

                    <span
                      v-if="modeltype == 'checkbox'"
                      class="errormsg radioerror"
                      >{{ errormsg[modelname] }}</span
                    >

                    <label class="col-md-2 col-form-label my-1">{{
                      $t("navigations.comments")
                    }}</label>
                    <div class="col-md-10 my-1">
                      <textarea class="form-control" v-model="modelcomments" />
                    </div>

                    <label class="col-md-2 col-form-label my-1"></label>
                    <div
                      v-if="
                        roleprivileges.includes('Data Verification Validate')
                      "
                      class="col-md-4 my-1"
                    >
                      <button
                        type="button"
                        class="btn btn-block btn-query btn-outline"
                        @click.prevent="saveaudit('Validated', modeltype)"
                      >
                        {{ $t("navigations.validate") }}
                      </button>
                    </div>
                    <div
                      v-if="
                        roleprivileges.includes('Data Verification Incorrect')
                      "
                      class="col-md-4 my-1"
                    >
                      <button
                        type="button"
                        class="btn btn-block btn btn btn-outline-warning"
                        @click.prevent="saveaudit('Incorrect',modeltype)"
                      >
                        {{ $t("navigations.incorrect") }}
                      </button>
                    </div>
                    <!-- <div class="col-md-3 my-1">

                                      <button type="button" class=" btn btn-block btn-query btn-outline btn-upload " @click="gotoraiseQuary('fieldQuery')">Raise Query </button>
                                  </div> -->
                    <div
                      v-if="
                        roleprivileges.includes('Data Verification Field query')
                      "
                      class="col-md-1 my-2"
                    >
                      <button
                        class="btn btn-rquery ml-1"
                        type="button"
                        @click="gotoraiseQuary('fieldQuery')"
                      >
                        <span class="pointer">
                          {{ $t("navigations.field query") }}
                        </span>
                        <i class="fa fa-question"></i>
                      </button>
                    </div>
                    <div class="col-md-12 my-4">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th>{{ $t("navigations.value") }}</th>
                              <th>{{ $t("navigations.status") }}</th>
                              <th>{{ $t("navigations.user") }}</th>
                              <th>{{ $t("navigations.data source") }}</th>
                              <th>{{ $t("navigations.date") }}</th>
                              <th>{{ $t("navigations.time") }}</th>
                              <th>{{ $t("navigations.comments") }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="audits in audit"
                              :key="audits.validatedDate"
                            >
                              <td class="maximumwidth">
                                {{ audits.newValue }}
                              </td>
                              <td>{{ audits.status }}</td>
                              <td>
                                {{
                                  audits.validatedBy == null
                                    ? ""
                                    : audits.validatedBy
                                }}
                              </td>
                              <td>{{ audits.dataSource }}</td>
                              <td>
                                {{
                                  audits.validatedDate == null
                                    ? ""
                                    : filter(audits.validatedDate)
                                }}
                              </td>
                              <td>
                                {{
                                  audits.validatedTime == null
                                    ? ""
                                    : formatTime(audits.validatedTime)
                                }}
                              </td>
                              <td>{{ audits.comment }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Query Open model starts -->
  <div class="modal__wrapper__Two">
    <div class="modal__container2">
      <div class="titleclass mb-1">
        <h4 class="ml-lg-2">{{ $t("navigations.query details") }}</h4>
        <div class="closebutton">
          <a style="color: white" class="close-link">
            <i class="fa fa-times" @click.prevent="closeQueryModal"></i>
          </a>
        </div>
      </div>
      <div class="modelbody2">
        <!-- test -->
        <div v-if="Querydetails.length == 0">
          <div class="text-center">
            <h1>{{ $t("navigations.no queries found") }}</h1>
          </div>
        </div>
        <div v-if="Querydetails.length > 0" class="table-responsive">
          <table
            class="tableFixHead table table-striped table-bordered dataTables"
          >
            <thead>
              <tr>
                <th>{{ $t("navigations.query id") }}</th>
                <th>{{ $t("navigations.query subject") }}</th>
                <th>{{ $t("navigations.query against") }}</th>
                <th>{{ $t("navigations.page no") }}</th>
                <th>{{ $t("navigations.field name") }}</th>
                <th>{{ $t("navigations.view") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="query in Querydetails" :key="query.id">
                <td>{{ query.queryId }}</td>
                <td>{{ query.query_Subject }}</td>
                <td>{{ query.queryFor }}</td>
                <td>{{ query.pageNo }}</td>
                <td>{{ query.fieldName }}</td>
                <td class="tdtext">
                  <a href="#" class="editicon"
                    ><i
                      class="fa fa-eye"
                      @click.prevent="viewforms(query.id)"
                    ></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- test -->
      </div>
    </div>
  </div>

  <!-- Document upload modal starts -->
  <div class="modal-wrapper-upload">
    <div class="modal-container-upload">
      <div class="titleclass">
        <h4 class="ml-lg-2">{{ $t("navigations.document upload") }}</h4>
        <div class="closebutton">
          <a style="color: white" class="close-link">
            <i class="fa fa-times" @click.prevent="CloseUploadModal()"></i>
          </a>
        </div>
      </div>
      <div class="modelbody">
        <div class="mb-3 p-2">
          <div class="form-group row my-lg-2">
            <div class="col-lg-12 my-2">
              <textarea
                name="description"
                id="description"
                class="form-control"
                v-model="azuredata.DocumentDescription"
                :placeholder="$t('navigations.description')"
              >
              </textarea>
            </div>

            <div class="col-lg-12 my-2">
              <input
                accept=".xls,.xlsx,.jpg,.jpeg,.png,.pdf"
                id="InPutFile"
                type="file"
                @change="previewFiles"
                class="form-control"
                 ref="fileInput"
              />
            </div>
            <span class="errorClass" v-if="docValidations == false">{{
              $t("navigations.please select a file")
            }}</span>

            <div class="d-flex my-2 mb-lg-0">
              <div class="assignbtncontainer">
                <button
                  class="btn btn-primary px-5 mx-1"
                  @click.prevent="CreateDocumentDMS()"
                >
                  {{ $t("navigations.upload") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Document upload modal ends -->

  <!-- Document view modal starts -->
  <div class="modal-wrapper-view">
    <div class="modal-container-view">
      <div class="titleclass">
        <h4 class="ml-lg-2">{{ $t("navigations.documents") }}</h4>
        <div class="closebutton">
          <a style="color: white" class="close-link">
            <i class="fa fa-times" @click.prevent="ClosemodalView()"></i>
          </a>
        </div>
      </div>
      <div class="modelbody">
        <div class="ibox-content">
          <div class="table-responsive">
            <table class="table table-striped table-bordered dataTables">
              <thead>
                <tr>
                  <th>{{ $t("navigations.file name") }}</th>
                  <th>{{ $t("navigations.date") }}</th>
                  <th>{{ $t("navigations.view") }}</th>
                  <th>{{ $t("navigations.download") }}</th>
                  <th>{{ $t("navigations.delete") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="document in documentlist" :key="document.fileName">
                  <td>{{ document.documentName }}</td>
                  <td>{{ filter(document.CreatedAt) }}</td>
                  <td class="text-center">
                    <i
                      class="fa fa-eye edit-delete"
                      @click="viewDocument(document.id)"
                    ></i>
                  </td>
                  <td class="text-center">
                    <i
                      class="fa fa-download edit-delete"
                      @click="downloadfile(document.id)"
                    ></i>
                  </td>
                  <td class="text-center">
                    <i
                      class="fa fa-trash edit-delete"
                      style="color: #a13b7c"
                      @click="deleteAzureDocument(document.id)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Document view modal ends -->
  <!-- repeat measures starts -->
  <teleport to="#app">
    <div class="add_page_modal_wrapper" v-if="repeatedmeasurepopup">
      <div class="option_modal__containers">
        <div
          class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
        >
          <h4 class="my-0">Manage Repeated Measures</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closerepeatedmeasuremodal()"></i>
          </a>
        </div>

        
                  <div class="form-group col-lg-10 mx-auto" v-if="!repeatedmeasurelistpopup">
                    <div  class="form-group col-lg-10 mx-auto textgroup">
                        <label for="optionscheckbox">ID</label>
                        <div class="optgroup">
                          <input
                            type="text"
                            class="form-control"
                            id="optionscheckbox"
                            v-model="repratmeasureid"
                          />
                        </div>
                        </div>
                    <div v-for="measureopts in repeatedoptions" :key="measureopts" class="form-group col-lg-10 mx-auto textgroup">
                        <label for="optionscheckbox">{{ measureopts.fieldName }}</label>
                        <div v-if="measureopts.type =='text'" class="optgroup">
                          <input
                            type="text"
                            class="form-control"
                            v-model="temprepeatedmeasureval[measureopts.fieldName]"
                            id="optionscheckbox"
                            :disabled="repeathidden"
                          />
                        </div>
                        <div v-if="measureopts.type =='date'" class="optgroup">
                          <input
                            type="date"
                            class="form-control"
                            v-model="temprepeatedmeasureval[measureopts.fieldName]"
                            id="optionscheckbox"
                            :disabled="repeathidden"
                          />
                        </div>
                        <div v-if="measureopts.type =='number'" class="optgroup">
                          <input
                            type="number"
                            class="form-control"
                            v-model="temprepeatedmeasureval[measureopts.fieldName]"
                            id="optionscheckbox"
                            :disabled="repeathidden"
                          />
                        </div>
                        <div v-if="measureopts.type =='time'" class="optgroup">
                          <input
                            type="time"
                            class="form-control"
                            v-model="temprepeatedmeasureval[measureopts.fieldName]"
                            id="optionscheckbox"
                            :disabled="repeathidden"
                          />
                        </div>
                        <div v-if="measureopts.type =='dropdown'" class="optgroup">
                          
                          <select :disabled="repeathidden" class="form-control" v-model="temprepeatedmeasureval[measureopts.fieldName]">
                          <option v-for="(option, index) in measureopts.options" :key="index" :value="option">{{ option }}</option>
                          </select>
                        </div>
                        <div v-if="measureopts.type =='radio'" class="optgroup">
                        <label class="mr-2" v-for="(option, index) in measureopts.options" :key="index">
                                <input :disabled="repeathidden" type="radio" :value="option" v-model="temprepeatedmeasureval[measureopts.fieldName]">
                        {{ option }}
                        </label>
                        </div>
                        
                      </div>
                      


                      <div class="form-group col-lg-10 mx-auto textgroup">
                      <button
                      v-if="!repeathidden"
                            type="button"
                            class="btn btn-primary float-right ml-1"
                            @click.prevent="addrepeatemesureentries()"
                          >
                          Add
                    </button>
                    <button
                            type="button"
                            class="btn btn btn btn-secondary float-right"
                            @click.prevent="repeatedmeasurlist()"
                          >
                          Back
                    </button>
                  </div>
                  </div>
        <div class="form-group d-flex flex-column col-lg-10 mx-auto maxheight" v-if="repeatedmeasurelistpopup">
          <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>Repeated Measure Name </th>
                <th class="text-center">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in repeatedmeasurevalarray" :key="item.ID">
                <td @click.prevent="reloadrepeatarray(item)">{{ item.ID }}</td>
                <td class="text-center">
                  <i class="fa fa-trash edit-delete" aria-hidden="true" @click="deleterepeatedmeasure(item)"></i>
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
        <div class="form-group col-lg-10 mx-auto mt-auto">
          <button
                            type="button"
                            class="btn btn-primary mr-2"
                            @click.prevent="repeatedmeasurdatapopup()"
                          >
                          Add Measures
                    </button>
                      <button
                            type="button"
                            class="btn btn-primary"
                            @click.prevent="saverepeatedmeasurdata()"
                          >
                          Save
                    </button>
                  </div>
                      </div>

      </div>
    </div>
  </teleport>
  <!-- repeat measures ends -->
  <signaturePopUp
    v-if="signpopUp == true"
    :status="signatuestatus"
    :formId="signatureformId"
    :modalText="modalStatus"
    @signatureclosemodel="CloseModelCall()"
  >
  </signaturePopUp>
</template>

<script src="./forms.js"></script>

<style scoped>
@import "./style.css";
.fa-archive {
  color: white;
}
.max-image {
    max-width: 500px; /* Maximum width of 500 pixels */
    max-height: 300px; /* Maximum height of 300 pixels */
  }
.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid #6d57ce !important;
  border-radius: inherit;
  margin: 0;
}
.paginationpadding {
  padding-bottom: 10px;
  padding-right: 10px;
}
.pageContainer {
  margin-top: 30px;
}
.seperator {
  font-size: 23px !important;
}
.linkstyleleft {
  font-weight: bold;
  color: #d42ca2 !important;
  font-size: 16px !important;
  float: left;
}
.linkcontent {
  width: 50%;
}
.errorborder {
  border-width: 2px !important;
  border-color: red !important;
  border-style: double;
}
.radioerror {
  margin-left: 16% !important;
}

.hiddenvisibility {
  visibility: hidden;
}
.maincontainer {
  height: 100px;
  width: 100%;
  z-index: -2;
  position: absolute;
  background-color: blue;
  overflow: hidden;
}
.maincontainertwo {
  width: 100%;
  z-index: -1;
  position: absolute;
  background-color: white;
}
.bottommargin {
  margin-bottom: 25px;
}
.hightlight {
  /* border-color: red !important; */
  border-bottom: 6px solid #f8ac59;
}
.border {
  /* background-color:coral; */
  border: 1px solid #09223b !important;
}
.borderValidated {
  border: 2px solid green !important;
}
.borderIncorrect {
  border: 2px solid orange !important;
}
.borderFail {
  border: 2px solid red !important;
}
.modal-container-upload {
  background: #fff;
  width: min(50%, 90%);
  border-radius: 4px;
  position: fixed;
  transition: all 0.3s ease-in-out;
  height: auto !important;
  margin-top: 200px;
}
.rowlabelgrid {
    margin-top: auto;
}
.modal-container-view {
  background: #fff;
  width: min(50%, 90%);
  border-radius: 4px;
  position: fixed;
  transition: all 0.3s ease-in-out;
  height: auto !important;
  margin-top: 200px;
}
.btn:focus-visible,
.btn:focus-within,
.btn:focus {
  outline: none;
  box-shadow: none;
}
.bg-white {
  background-color: white;
}
.labelclass {
  text-align: left;
  font-size: 15px;
}
.labelstyle{
  font-size: 15px;
}
.add_page_modal_wrapper,
.modal__wrapper__component {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
}
.option_modal__containers {
  width: min(600px, 90%);
    background: #fff;
    border-radius: 4px;
    overflow: auto;
    height: 400px;
}
.repeat-border-bottom
{
  border-bottom:1px dashed #3c4192;
}
.single-line-input {
    display: flex;
    flex-direction: column;
}

.single-line-input label {
    margin-bottom: 8px; 
}

.input-container {
    display: flex;
}

.input-container input {
    flex: 1;
    margin-right: 0px; 
}

.input-container input:last-child {
    margin-right: 0;
}
.default-text
{
  font-size: 1rem;
  word-wrap: break-word;
  font-weight: 600
}
</style>
