<template>
  <!-- modal starts -->
  <div class="patient__login__modal__wrapper">
    <div class="patient__login__modal__container">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">{{ modalText }}</h4>
        <div class="cursor-pointer" @click.prevent="onclosed()">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="px-5 py-3">
        <div v-if="modalText == `Sign`">
          <h7>
            !!!On signing, this is equivalent to the wet ink signature!!!
          </h7>
        </div>
        <div class="form-group">
          <label class="col-form-label"
            >{{ $t("navigations.email")
            }}<sup><i class="fa fa-asterisk required"></i></sup
          ></label>
          <input type="text" class="form-control" v-model="emailId" />
        </div>
        <div class="form-group position-relative">
          <label class="col-form-label"
            >{{ $t("navigations.digi sign password")
            }}<sup><i class="fa fa-asterisk required"></i></sup
          ></label>
          <input :type="inputType" class="form-control" v-model="password" />
          <i
            class="password-sign-visible-block cursor-pointer position-absolute"
            :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
            @click.prevent="passwordVisible()"
          ></i>
          <div>
            <span v-if="validation" class="errmsg">{{ err }}</span>
            <span v-if="!validation" class="errmsg">{{ errmsg }}</span>
          </div>
        </div>
        <div class="text-right">
          <button class="save_btn px-5 mt-3" @click="submitClick()">
            {{ $t("navigations.confirm") }}
          </button>
        </div>
      </div>
      <!-- test -->
    </div>
  </div>
  <!-- modal ends -->
</template>
<script>
import axios from "axios";
import store from "../../store/index";
export default {
  name: "signaturePopUp",
  props: {
    status: {
      type: String,
      default: "",
    },
    formId: {
      type: String,
      default: "",
    },
    modalText: {
      type: String,
      default: "",
    },
    fromSrcUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputType: "password",
      visibleOn: false,
      emailId: "",
      password: "",
      err: "",
      validation: false,
      id: "",
      state: "",
      baseurl: process.env.VUE_APP_Service_URL,
    };
  },
  async mounted() {
    await this.assignValues();
  },
  methods: {
    async passwordVisible() {
      this.visibleOn = !this.visibleOn;
      if (this.visibleOn === true) {
        this.inputType = "text";
      } else this.inputType = "password";
    },
    async assignValues() {
      this.id = this.formId;
      this.state = this.status;
      console.log("FormID=>", this.id + "&" + "Status=>", this.state);
    },
    async onclosed() {
      this.$emit("signatureclosemodel");
      this.emailId = "";
      this.password = "";
    },

    async submitClick() {
      if (this.emailId == "") {
        this.validation = true;
        this.err = "Please enter a valid email id";
      } else if (this.password == "") {
        this.validation = true;
        this.err = "Please enter your password";
      } else if (this.emailId != "" && this.password != "") {
        this.validation = false;
        await this.updateSign();
      }
    },
    async updateSign(status) {
      const idtoken = store.getters.getIdToken;
      if (this.fromSrcUrl === "digitalForm") {
        await axios
          .put(
            `${this.baseurl}/forms/template/signtemplate?TempId=${this.id}&status=${this.state}&email=${this.emailId}&password=${this.password}`,
            {},
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.refreshForm("test");
            this.$emit("signatureclosemodel");
            if (this.status == "Signed") {
              this.$emit("docSigned");
              localStorage.removeItem("setId");
              localStorage.removeItem("showSign");
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "Error mesg",
              err.response.data.errors.DomainValidations[0]
            );
            this.validation = true;
            this.err = err.response.data.errors.DomainValidations[0];
          });
      } else {
        await axios
          .put(
            `${this.baseurl}/forms/forms/signform?formId=${this.id}&status=${this.state}&email=${this.emailId}&password=${this.password}`,
            {},
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.refreshForm("test");
            this.$emit("signatureclosemodel");
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "Error mesg",
              err.response.data.errors.DomainValidations[0]
            );
            this.validation = true;
            this.err = err.response.data.errors.DomainValidations[0];
          });
      }
    },
    async refreshForm(test) {
      await store.dispatch("setFormRefresh", true);
      window.dispatchEvent(
        new CustomEvent("FormDigitalSignature", {
          detail: {
            storage: store.getters.getFormRefresh,
          },
        })
      );
    },
  },
};
</script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.modelbody {
  overflow: unset !important;
  background-color: #ffffff !important;
}

.modelbody {
  /* background-color: aquamarine; */
  margin: 0;
  padding: 5%;
}

.form-control {
  padding: 0.2rem 0.75rem !important;
}
.form-select {
  border-radius: .5rem !important;
  padding: 0.2rem 0.75rem !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
.patient__login__modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.patient__login__modal__container {
  position: fixed;
  width: min(500px, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}
.modal__title {
  background-color: var(--pop-up-background);
  color: white;
}
.errmsg {
  font-size: 14px;
  color: red;
  float: left;
}
.password-sign-visible-block {
  right: 11px;
  top: 41px;
}
</style>
