import axios from "axios";
import store from "../../store/index";
import Percentagechart from "../../custom_lib/AiCharts/percentagechart.vue";
import D3PieChart from "../../custom_lib/AiCharts/D3PieChart.vue";
import groupbarchart from  "../../custom_lib/AiCharts//groupbarchart.vue";
import sunburst from "../../custom_lib/AiCharts//sunburst.vue"
import d3table from "../../custom_lib/AiCharts//d3table.vue"
export default {
  name: "descriptiveStatistic",
  components: {
      D3PieChart,
      Percentagechart,
      groupbarchart,
      sunburst,
      d3table
   },
   mounted() {
        this.idtoken=store.getters.getIdToken;
        this.getData()
        this.getSelectData()
   },
  data() {
        return {
            baseurl: process.env.VUE_APP_Service_URL,
            baseapi: `${process.env.VUE_APP_Service_URL}/forms-ml/`,
            selectedCategory:"",
            data:[],
            getSelectedData:{}
        }
    },
    methods:{
      async getData() {			
        await axios
          .get(`${this.baseapi}report/getreports?table_name=SampleTable` 
          )
          .then((res) => {
            this.data = res.data;

          })
          .catch((err) => {
            console.log(err);
          });
      },
      async getSelectData(){
        await axios
          .post(`${this.baseapi}report/getdescriptiveanalysis?table_name=SampleTable`,
          {
            "ignored_features": [
              "_id","Participant ID"
            ],
            "filter_condtions": {}
          }
          )
          .then((res) => {
            if (res.data && res.data.CategoricalStats) {
              this.getSelectedData = Object.fromEntries(
                Object.entries(res.data.CategoricalStats).filter(([key]) => key !== 'SubjectID')
              );

                this.selectedCategory=Object.keys(this.getSelectedData)[0]
              
            } else {

              this.getSelectedData = {}; 
           
            }
          
            console.log("Selected data is",this.getSelectedData)
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
}