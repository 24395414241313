<template>
  <div class="stat-card-white">
    <div class="stat-header-white">
      <span>Comparative Analysis</span>
      <div v-if="!loader" class="d-flex align-items-center">
        <label for="x-axis-select">Attribute:</label>
        <select
          class="graph-select"
          v-model="selectedXAxis"
          id="x-axis-select"
          @change="updateChart"
        >
          <option
            v-for="option in xAxisOptions"
            :key="option"
            :value="option"
          >{{ option }}</option>
        </select>

        <label   class="mb-0 ml-2 mr-1" for="category-select">Category:</label>
        <select
          class="graph-select"
          v-model="selectedCategory"
          id="category-select"
          @change="updateChart"
        >
          <option
            v-for="option in categoryOptions"
            :key="option"
            :value="option"
          >{{ option }}</option>
        </select>
      </div>
    </div>
    <div class="stat-body-white bar-chart-wrapper d-flex flex-column">
      <div v-if="enrolledornot && fromPage === 'patientScreening' && !loader">
        <label>
          <input
            type="checkbox"
            v-model="aiPredictionTrue"
            @change="updateChart"
          />
          Enrolled
        </label>
        <label>
          <input
            type="checkbox"
            v-model="aiPredictionFalse"
            @change="updateChart"
          />
          Non-enrolled
        </label>
      </div>
      <div v-if="loader" class="loader"></div>
      <svg ref="chartContainer" v-show="!loader"></svg>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import axios from "axios";
import store from "../../store/index";

export default {
  name: "GroupedBarChart",
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    templateId: {
      type: String,
      default: "",
    },
    fromPage: {
      type: String,
      default: "",
    },
    enrolledornot: {
      type: Boolean,
      default: true,
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      idtoken: "",
      baseApi: process.env.VUE_APP_Service_URL,
      mappings: {},
      svg: null,
      selectedXAxis: "",
      selectedCategory: "",
      xAxisOptions: [],
      categoryOptions: [],
      aiPredictionTrue: true,
      aiPredictionFalse: true,
      loading: true,
      customColors: ['#62B2FD', '#9BDFC4', '#ABF0FF', '#C1D1FF', '#FBD164', '#FDAD73'],
    };
  },
  watch: {
    data: {
      handler() {
        console.log("dataa", this.data)
        this.loadAttributes();
        this.updateChart();
      },
      deep: true,
    },
  },
  async beforeMount() {
    console.log("dataa", this.data)
    this.idtoken = store.getters.getIdToken;
    if (this.templateId) {
      await this.createMapping();
    }
    this.loadAttributes();
  },
  mounted() {
    this.createChart();
  },
  methods: {
    async createMapping() {
      try {
        const response = await axios.get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          const pages = response.data.pages;
          pages.forEach((page) => {
            page.field.forEach((field) => {
              if (field.options && field.options.length > 0) {
                this.mappings[field.fieldName] = field.options.reduce(
                  (map, option) => {
                    map[option.codevalue] = option.optionname;
                    return map;
                  },
                  {}
                );
              }
            });
          });
        }
      } catch (error) {
        console.log("Error creating mappings", error);
      }
    },
    loadAttributes() {
      const sampleData =
        this.data.length > 0 && this.data[0].features
          ? this.data[0].features
          : {};

      const qualitativeAttributes = [];
      const categories = [];

      Object.keys(sampleData).forEach((key) => {
        const uniqueValues = new Set(
          this.data.map((item) => item.features[key])
        ).size;
        if (uniqueValues < 6) {
          qualitativeAttributes.push(key);
          categories.push(key);
        }
      });

      this.xAxisOptions = qualitativeAttributes;
      this.selectedXAxis = qualitativeAttributes[0] || "";

      this.categoryOptions = categories;
      this.selectedCategory = categories[1] || "";

      this.updateChart();
    },
    async updateChart() {
      this.loading = true;
      await this.$nextTick();
      this.createChart();
      this.loading = false;
    },
    createChart() {
      const margin = { top: 20, right: 30, bottom: 40, left: 60 };
      const width = 550 - margin.left - margin.right;
      const height = 300 - margin.top - margin.bottom;
      const chartWidth = width - margin.left - margin.right;
      const chartHeight = height - margin.top - margin.bottom;

      const aggregatedData = this.aggregateData(this.data);

      if (aggregatedData.length === 0) {
        console.error("Aggregated data is empty");
        return;
      }

      const subCategories = Array.from(
        new Set(
          aggregatedData.flatMap((d) =>
            d.values.map((v) => v.category).filter((v) => v != null)
          )
        )
      );

      const colorScale = d3
        .scaleOrdinal()
        .domain(subCategories)
        .range([...this.customColors, ...d3.schemeCategory10]);

      this.xScale0 = d3
        .scaleBand()
        .domain(aggregatedData.map((d) => d.key))
        .range([0, chartWidth])
        .padding(0.1);

      this.xScale1 = d3
        .scaleBand()
        .domain(subCategories)
        .range([0, this.xScale0.bandwidth()])
        .padding(0.05);

      const maxYValue =
        d3.max(aggregatedData, (d) =>
          d3.max(d.values, (v) => v.value)
        ) || 0;
      const yAxisLimit = maxYValue + maxYValue * 0.1;

      this.yScale = d3
        .scaleLinear()
        .domain([0, yAxisLimit])
        .nice()
        .range([chartHeight, 0]);

      d3.select(this.$refs.chartContainer).selectAll("*").remove();

      this.svg = d3
        .select(this.$refs.chartContainer)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      const bars = this.svg
        .append("g")
        .attr("class", "bars")
        .selectAll("g")
        .data(aggregatedData)
        .enter()
        .append("g")
        .attr("transform", (d) => `translate(${this.xScale0(d.key)},0)`);

      bars
        .selectAll("rect")
        .data((d) => d.values.filter((v) => v.value != null && !isNaN(v.value)))
        .enter()
        .append("rect")
        .attr("x", (d) => this.xScale1(d.category))
        .attr("y", (d) => this.yScale(d.value))
        .attr("height", (d) => this.yScale(0) - this.yScale(d.value))
        .attr("width", this.xScale1.bandwidth())
        .attr("fill", (d, i) => colorScale(d.category))
        .each(function (d) {
          this._currentColor = colorScale(d.category);
        });

      bars
        .selectAll("text")
        .data((d) => d.values.filter((v) => v.value != null && !isNaN(v.value)))
        .enter()
        .append("text")
        .attr(
          "x",
          (d) => this.xScale1(d.category) + this.xScale1.bandwidth() / 2
        )
        .attr("y", (d) => this.yScale(d.value) + 10)
        .attr("text-anchor", "middle")
        .attr("fill", "black")
        .attr("transform", function (d) {
          return `rotate(-90, ${this.getAttribute(
            "x"
          )}, ${this.getAttribute("y")})`;
        })
        .text((d) => d.value);

      this.svg
        .append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0,${chartHeight})`)
        .call(d3.axisBottom(this.xScale0))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)");

      this.svg.append("g").attr("class", "y-axis").call(d3.axisLeft(this.yScale));
    },
    validateData(data) {
      return data.filter((d) => {
        const aiPrediction = d.features.AIPrediction;
        if (
          (aiPrediction && this.aiPredictionTrue) ||
          (!aiPrediction && this.aiPredictionFalse)
        ) {
          return (
            d.features[this.selectedXAxis] !== undefined &&
            d.features[this.selectedCategory] !== undefined
          );
        }
        return false;
      });
    },
    aggregateData(data) {
      const validData = this.validateData(data);
      const nestedData = d3.group(
        validData,
        (d) =>
          this.getMappedValue(
            d.features[this.selectedXAxis],
            this.selectedXAxis
          ),
        (d) => d.features[this.selectedCategory]
      );

      return Array.from(nestedData, ([key, values]) => ({
        key,
        values: Array.from(values, ([category, records]) => ({
          category: this.getMappedValue(category, this.selectedCategory),
          value: records.length,
        })),
      }));
    },
    getMappedValue(value, key) {
      return this.mappings[key] ? this.mappings[key][value] || value : value;
    },
  },
};
</script>

<style scoped>
.bar-chart-wrapper {
  overflow-x: auto;
  width: 100%;
}

.bar-chart {
  position: relative;
}

.tooltip {
  display: none;
}

.stat-card-white {
  background: #ffffff;
  border-radius: 10px;
  color: #676a6c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stat-header-white {
  border-bottom: 2px solid rgba(241, 241, 241);
  padding: 0.4rem 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stat-header-white span {
  font-size: 1.2em;
  font-weight: 500;
}

.stat-body-white {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 360px;
}

.legend {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.legend-item {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.legend-color.enrolled {
  background-color: darkblue;
}

.legend-color.non-enrolled {
  background-color: lightblue;
}

.controls {
  margin-bottom: 10px;
}

.controls label {
  margin-right: 5px;
}

.controls select {
  margin-right: 15px;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.graph-select {
  display: block;
  width: 100%;
  padding: 0.3rem 2.25rem 0.3rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.graph-select:focus,
.graph-select:focus-within,
.graph-select:focus-visible {
  outline: none;
}
</style>
