<template>
  <div class="stat-card-white compminheight">
    <div class="stat-header-white">
      <span>Patient Distribution</span>
      <div v-if="!loader">
        <select v-model="selectedXAxis" id="x-axis-select" @change="updateChart">
          <option v-for="option in xAxisOptions" :key="option" :value="option">{{ option }}</option>
        </select>
      </div>
    </div>
    <div class="stat-body-white bar-chart-wrapper">
      <div v-if="loader" class="loader"></div>
      <svg ref="chartContainer" v-show="!loader"></svg>
    </div>
    <div class="legend" v-if="!loader">
      <div class="legend-item">
        <span class="legend-color enrolled"></span>
        {{ labelOne }}
      </div>
      <div class="legend-item">
        <span class="legend-color non-enrolled"></span>
        {{ labelTwo }}
      </div>
    </div>
  </div>
</template>
<script>
import * as d3 from 'd3';
import axios from "axios";
import store from "../../store/index";

export default {
  name: 'GroupedBarChart',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    templateId: {
      type: String,
      default: ''
    },
    labelOne: {
      type: String,
      default: 'Enrolled'
    },
    labelTwo: {
      type: String,
      default: 'Non-enrolled'
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      idtoken: "",
      baseApi: process.env.VUE_APP_Service_URL,
      mappings: {},
      svg: null,
      selectedXAxis: 'AGE',
      selectedYAxis: 'AIPrediction',
      xAxisOptions: ['AGE', 'GENDER', 'CHOLESTROL', 'AVGLUCOSE', 'CHRONICONDITION', 'BLOODOXYGEN'],
      yAxisOptions: ['AIPrediction'],
      bins: [], // Dynamic bins
      genderMap: { 0: 'Male', 1: 'Female', 2: 'Other' },
      colors: { Enrolled: '#62B2FD', 'Non-enrolled': '#9BDFC4' },
    };
  },
  watch: {
    data: {
      handler() {
        if (this.templateId != '' && this.templateId != null) {
          this.createmapping();
        }
        this.loadQuantitativeAttributes();
        this.createChart();
      },
      deep: true,
    },
    selectedXAxis: {
      handler() {
        if (this.templateId != '' && this.templateId != null) {
          this.createmapping();
        }
        this.updateBins();
        this.updateChart();
      },
    }
  },
  async beforeMount() {
    await this.loadQuantitativeAttributes();
  },
  async mounted() {
    this.idtoken = store.getters.getIdToken;
    if (this.templateId != '' && this.templateId != null) {
      await this.createmapping();
    }
    this.updateBins();
    this.createChart();
  },
  methods: {
    async loadQuantitativeAttributes() {
      const sampleData = (this.data && this.data.length > 0 && this.data[0].features) ? this.data[0].features : {};
      
      const quantitativeAttributes = Object.keys(sampleData).filter(key => typeof sampleData[key] === 'number');

      this.xAxisOptions = quantitativeAttributes;
      this.selectedXAxis = quantitativeAttributes[0] || '';
    },
    async createmapping() {
      try {
        const response = await axios.get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          const pages = response.data.pages;
          pages.forEach(page => {
            page.field.forEach(field => {
              if (field.options && field.options.length > 0) {
                this.mappings[field.fieldName] = field.options.reduce((map, option) => {
                  map[option.codevalue] = option.optionname;
                  return map;
                }, {});
              }
            });
          });
        }
      } catch (error) {
        console.log("Error creating mappings", error);
      }
    },
    updateBins() {
      if (!this.selectedXAxis) return;

      const values = this.data.map(d => d.features[this.selectedXAxis]).filter(v => v !== undefined);
      const uniqueValues = Array.from(new Set(values));

      if (uniqueValues.length > 5) {
        const min = d3.min(values);
        const max = d3.max(values);
        const numBins = 5;
        const step = Math.ceil((max - min) / numBins);
        //const step = (max - min) / numBins;
        this.bins = d3.range(min, max, step).concat(max);
      } else {
        this.bins = uniqueValues;
      }
    },
    updateChart() {
      if (!this.svg) {
        console.error('SVG not initialized');
        return;
      }
      // Clear existing chart
      d3.select(this.$refs.chartContainer).selectAll('*').remove();
      // Re-create the chart with new settings
      this.createChart();
    },
    async createChart() {
      const margin = { top: 20, right: 30, bottom: 40, left: 40 };
      const barWidth = 40; // Fixed width for each bar
      const groupPadding = 20; // Padding between groups
      const chartHeight = 290;
      const aggregatedData = this.aggregateData(this.data);

      if (aggregatedData.length === 0) {
        console.error('Aggregated data is empty');
        return;
      }

      const subCategories = ['Enrolled', 'Non-enrolled'];
      const groupWidth = subCategories.length * barWidth + groupPadding;
      const totalWidth = Math.max(800, aggregatedData.length * groupWidth + margin.left + margin.right);
      const chartWidth = totalWidth - margin.left - margin.right;

      this.xScale0 = d3.scaleBand()
        .domain(aggregatedData.map(d => d.key))
        .range([margin.left, chartWidth + margin.left])
        .padding(0.1);

      this.xScale1 = d3.scaleBand()
        .domain(subCategories)
        .range([0, this.xScale0.bandwidth()])
        .padding(0.05);

      this.yScale = d3.scaleLinear()
        .domain([0, d3.max(aggregatedData, d => d3.max(d.values, v => v.value)) || 0])
        .nice()
        .range([chartHeight, 0]);

      this.svg = d3.select(this.$refs.chartContainer)
        .attr('viewBox', `0 -20 ${totalWidth} 400`);

      const barGroups = this.svg.append('g')
        .attr('class', 'bars')
        .selectAll('g')
        .data(aggregatedData)
        .enter().append('g')
        .attr('transform', d => `translate(${this.xScale0(d.key)},0)`);

      barGroups.selectAll('rect')
        .data(d => d.values)
        .enter().append('rect')
        .attr('x', d => this.xScale1(d.category))
        .attr('y', d => this.yScale(d.value))
        .attr('height', d => this.yScale(0) - this.yScale(d.value))
        .attr('width', this.xScale1.bandwidth())
        .attr('fill', d => this.colors[d.category])
        .attr('stroke', 'none'); // Remove border on mouseover

      barGroups.selectAll('text')
        .data(d => d.values)
        .enter().append('text')
        .attr('x', d => this.xScale1(d.category) + this.xScale1.bandwidth() / 2)
        .attr('y', d => this.yScale(d.value) + (d.value > 10 ? 15 : -5)) // Adjusting the position of text inside the bar
        .attr('text-anchor', 'middle')
        .attr('fill', 'black')
        .style('font-size', '14px') // Increasing font size
        .text(d => d.value);

      this.svg.append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(0,${chartHeight})`)
        .call(d3.axisBottom(this.xScale0))
        .selectAll("text")
        .style("text-anchor", "end")
        .style("font-size", "18px")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)");

      this.svg.append('g')
        .attr('class', 'y-axis')
        .style("font-size", "14px")
        .attr('transform', `translate(${margin.left},0)`)
        .call(d3.axisLeft(this.yScale));

    },
    aggregateData(data) {
      const validData = data.filter(
        (d) => d.features[this.selectedXAxis] !== undefined && d.features[this.selectedYAxis] !== undefined
      );

      if (!validData.length) {
        return [];
      }

      const nestedData = d3.group(
        validData,
        d => this.getXKey(d.features[this.selectedXAxis]),
        d => d.features[this.selectedYAxis]
      );

      const aggregatedData = Array.from(nestedData, ([key, values]) => ({
        key: String(this.getMappedValue(key, this.selectedXAxis)),
        values: [
          { category: 'Enrolled', value: values.get(true) ? values.get(true).length : 0 },
          { category: 'Non-enrolled', value: values.get(false) ? values.get(false).length : 0 },
        ]
      }));

      return aggregatedData.sort((a, b) => String(a.key).localeCompare(String(b.key), undefined, { numeric: true }));
    },
    getXKey(value) {
      if (this.bins.length > 5) {
        return this.getBin(value, this.bins);
      }
      return value;
    },
    getBin(value, bins) {
      for (let i = 0; i < bins.length - 1; i++) {
        if (value >= bins[i] && value < bins[i + 1]) {
          return `${bins[i]}-${bins[i + 1]}`;
        }
      }
      return `${bins[bins.length - 1]}+`;
    },
    getMappedValue(value, key) {
      return this.mappings[key] ? this.mappings[key][value] || value : value;
    }
  },
};
</script>
<style scoped>
.bar-chart-wrapper {
  overflow-x: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar-chart {
  position: relative;
}

.legend {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.legend-item {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.legend-color.enrolled {
  background-color: #62B2FD;
}

.legend-color.non-enrolled {
  background-color: #9BDFC4;
}

.stat-card-white {
  background: #ffffff;
  border-radius: 10px;
  color: #676a6c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stat-header-white {
  border-bottom: 2px solid rgba(241, 241, 241);
  padding-bottom: 6px;
  margin-bottom: 0px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stat-header-white span {
  font-size: 1.2em;
  font-weight: 500;
}

.stat-body-white {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 125px;
}

.compminheight {
  min-height: 389px;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
