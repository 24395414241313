<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.unscheduled visits") }}</h2>
    </div>
  </div>

  <div class="wrapper wrapper-content">
    <div class="ibox-body mb-1 p-2">
      <div class="row my-2 my-lg-0 mb-lg-0">
        <div class="col-lg-5">
          <div class="form-group mb-md-0">
            <label class="mb-0">{{ $t("navigations.subject id") }}</label>

            <input
              v-model="searchData.patientNo"
              type="text"
              class="form-control my-md-2 my-lg-0"
            />
          </div>
        </div>

        <div class="col-lg-5">
          <div class="form-group mb-md-0">
            <label class="mb-0">{{ $t("navigations.status") }}</label>

            <select class="form-select my-md-2 my-lg-0" v-model="searchData.visitStatus">
              <option value="All">{{ $t("navigations.all") }}</option>
              <option value="Completed">{{ $t("navigations.completed") }}</option>
              <option value="Imminent">{{ $t("navigations.imminent") }}</option>
              <option value="Overdue">{{ $t("navigations.overdue") }}</option>
              <option value="Planned">{{ $t("navigations.planned") }}</option>
            </select>

          </div>
        </div>

        <div class="col-lg-10">
          <div class="form-group mb-md-0">
            <label class="mb-0">{{ $t("navigations.visit date") }}</label>

            <div class="d-flex">
              <input
                type="date"
                max="9999-12-31"
                class="form-control"
                v-model="searchData.fromDate"
              />

              <span class="mx-1 mt-2">{{ $t("navigations.to") }}</span>

              <input
                type="date"
                max="9999-12-31"
                class="form-control"
                v-model="searchData.toDate"
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-2 align-self-end">
          <span
            type="button"
            @click="OnSearchButtonClicked()"
            class="btn btn-primary save_btn mx-lg-1 w-100"
          >
            {{ $t("navigations.search") }}
          </span>
        </div>
      </div>
    </div>

    <div class="ibox-body">
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>{{ $t("navigations.site code") }}</th>

                <th>{{ $t("navigations.subject id") }}</th>

                <th class="sort_block" @click="SortSelected('visitNo',3)" id="3">
                  <div class="d-flex flex-row justify-content-between">  
                    {{ $t("navigations.visit no") }}            
                   <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>                 
                </th>

                <th class="sort_block" @click="SortSelected('visitName',4)" id="4">
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t("navigations.visit name") }}           
                                   <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                 
                </th>
                <th class="sort_block" @click="SortSelected('visitType',5)" id="5">
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t("navigations.visit type") }}           
                                   <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                 
                </th>

                <th class="sort_block" @click="SortSelected('preVisit',6)" id="6">
                  <div class="d-flex flex-row justify-content-between">  
                    {{ $t("navigations.pre-visit") }}            
                                   <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                </th>

                <th class="sort_block" @click="SortSelected('visitDate',7)" id="7">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.visit date") }}              
                                   <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                </th>
                <th class="sort_block" @click="SortSelected('postVisit',8)" id="8">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.post-visit") }}              
                                   <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                  
                </th>

                <th class="sort_block" @click="SortSelected('visitStatus',9)" id="9">
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t("navigations.visit status") }}           
                                   <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                  
                </th>

                <th class="sort_block" @click="SortSelected('investigatorName',10)" id="10">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.investigator") }}              
                                   <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                  
                </th>

                <th class="sort_block" @click="SortSelected('visitCompletedDate',11)" id="11">
                  <div class="d-flex flex-row justify-content-between">        
                    {{ $t("navigations.visit completed date") }}      
                                   <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                  
                </th>

                <th
                  v-if="roleprivileges.includes('Unscheduled Visits View Form')"
                  class="text-center"
                >
                  {{ $t("navigations.view") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="shedule in sheduleList" :key="shedule.id">
                <td>{{ shedule.siteCode }}</td>

                <td>{{ shedule.patientNo }}</td>

                <td>{{ shedule.visitNo }}</td>

                <td>{{ shedule.visitName }}</td>
                <td>{{ shedule.visitType }}</td>

                <!-- pre visit -->

                <td>
                  <span v-if="shedule.preVisit">{{
                    filter(shedule.preVisit)
                  }}</span>

                  <span v-else>{{}}</span>
                </td>

                <!-- visit date -->

                <td>
                  <span v-if="shedule.sheduleId == editShedule.sheduleId">
                    <input
                      type="date"
                      max="9999-12-31"
                      class="form-control"
                      v-model="visitdate"
                    />
                  </span>

                  <span
                    v-if="
                      shedule.visitDate &&
                      shedule.sheduleId != editShedule.sheduleId
                    "
                  >
                    {{ filter(shedule.visitDate) }}
                  </span>

                  <span v-else> {{}} </span>
                </td>

                <!-- post visit -->

                <td>
                  <span v-if="shedule.postVisit">
                    {{ filter(shedule.postVisit) }}
                  </span>

                  <span v-else>{{}}</span>
                </td>

                <td>
                  <span
                    class="badge badge-grn"
                    v-if="shedule.visitStatus == 'Completed'"
                    >{{ shedule.visitStatus }}</span
                  >
                  <span
                    class="badge badge-danger"
                    v-if="shedule.visitStatus == 'Overdue'"
                    >{{ shedule.visitStatus }}</span
                  >
                  <span
                    class="badge badge-warning"
                    v-if="shedule.visitStatus == 'Imminent'"
                    >{{ shedule.visitStatus }}</span
                  >
                  <span
                    class="badge badge-genre"
                    v-if="
                      shedule.visitStatus == 'Planned' ||
                      shedule.visitStatus == 'Withdrawn'
                    "
                    >{{ shedule.visitStatus }}</span
                  >
                </td>

                <!-- Investigator -->

                <td>
                  <span v-if="shedule.investigatorName == 'null'"> {{}} </span>

                  <span
                    v-if="
                      shedule.sheduleId != editShedule.shedule &&
                      shedule.investigatorName != 'null' &&
                      shedule.investigatorName != ''
                    "
                  >
                    {{ shedule.investigatorName }}
                  </span>

                  <span v-if="shedule.sheduleId == editShedule.sheduleId">
                    <select
                      class="form-control"
                      name="account"
                      v-model="investigator"
                    >
                      <option v-for="user in users" :key="user.userID">
                        {{ user.email }}
                      </option>
                    </select>

                    <!-- <input   type="text" class="form-control"  v-model="investigator"/>   -->
                  </span>
                </td>

                <td>
                  <span v-if="shedule.visitCompletedDate">
                    {{ filter(shedule.visitCompletedDate) }}
                  </span>

                  <span v-else>{{}}</span>
                </td>

                <td
                  class="text-center"
                  v-if="roleprivileges.includes('Unscheduled Visits View Form')"
                >
                  <span v-if="shedule.visitStatus == 'Completed'"
                    ><i
                      class="fa fa-eye edit-delete"
                      @click.prevent="viewforms(shedule.formId)"
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

          <!-- pagination -->

          <div class="row mx-0 mt-1">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="
                  dataTables_paginate
                  paging_simple_numbers
                  pagination
                  float-right
                "
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="this.currentPage"
                    :pages="totalPage"
                    :range-size="0"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
                
              </div>
            </div>
          </div>

          <!-- pagination ends -->
        </div>
      </div>
    </div>
  </div>

  <!-- modal starts -->
</template>

 

<script>
/* eslint-disable */

import axios from "axios";

import store from "../../store/index";

import moment from "moment";

import VPagination from "@hennge/vue3-pagination";

import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import jwt_decode from "jwt-decode";

export default {
  name: "unscheduledvisits",

  props: { patientId: String },

  components: {
    VPagination,
  },

  data() {
    return {
      defaultdate: "",
      roleprivileges: "",
      Investigator: "",
      sProp:"",

      IsDesc:true,

      StudyId: "",

      patientId: "",

      siteId: "",

      patient: "",

      hideComplte: false,

      templatelist: [],

      selectedmodeltemplate: "",

      minDate: new Date().toISOString(),

      isScheduled: false,

      searchData: {
        patientNo: "",

        fromDate: "",

        toDate: "",

        visitStatus: "",

        pageNo: 1,

        pageSize: 10,
      },

      oldprevisit: "",

      oldpostvisit: "",

      oldnursename: "",

      currentPage: 1,

      totalPage: 0,

      totalCount: 1,

      sheduleList: [],

      baseUrl: `${process.env.VUE_APP_Service_URL}/management/patientschedule/`,

      baseapi: process.env.VUE_APP_Service_URL,

      validation: [false],

      curntdate: new Date().toISOString().slice(0, 10),

      popuperrors: [],

      editShedule: "",

      investigator: "",

      visitdate: "",

      users: "",
      
    };
  },

  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    await this.setroleprivileges();
    this.gettemplatedata();

    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);

      // window.location.reload();
      this.gettemplatedata();
      this.getShedules();
      this.idToken = store.getters.getIdToken;
    });
    this.idToken = store.getters.getIdToken;

    this.headerValues = {
      headers: {
        Authorization: "Bearer " + this.idToken,

        "Content-Type": "application/json",
      },
    };

    this.getShedules();
  },

  methods: {
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }

      console.log(data);
    },

    async hideCompleteIcon() {
      this.hideComplte = false;

      if (this.sheduleList.length) {
        this.sheduleList.forEach((shedule) => {
          if (
            shedule.visitStatus != "Completed" &&
            shedule.visitStatus != "Withdrawn"
          ) {
            this.hideComplte = true;
          }
        });
      } else {
        this.hideComplte = true;
      }
    },

    async viewforms(id) {
      if (id == null) {
        alert("The id value is null");
      } else {
        const fromArchive = false;
        this.$router.push(`/showform/patientlist/${id}/${fromArchive}`);
      }
    },

    async changeSiteStatus() {
      const idtoken = store.getters.getIdToken;

      await axios
        .put(
          `${this.baseapi}/management/site/changesitestatus?siteId=` +
            this.siteId +
            `&status=Enrolled`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,

              "Content-Type": "application/json",
            },
          }
        )

        .then((res) => {
          console.log(res);
          console.log("site status changed successfully...!");
        })

        .catch((err) => {
          console.log(err);
          console.log("error in change site status...!");
        });
    },

    async OnSearchButtonClicked(){
    this.searchData.pageNo =1;
    this.currentPage=1;
    this.getShedules();
    },

SortSelected(sortProp,id){
      console.log('SortPropChanged');
      const selectedElement=document.getElementById(id)
      const selectedElementID=document.getElementById(id).id     
      if(sortProp!=null && id==selectedElementID)
      {        
        if(this.IsDesc == true)
        {
          // if(selectedElement.classList.contains("sortDesc")){
          //   return
          // }
           if(selectedElement.classList.contains("sortAsc"))
          {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }    
          else selectedElement.classList.add("sortDesc")
        }
        else
        {
          if(selectedElement.classList.contains("sortDesc")){
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          // else if(selectedElement.classList.contains("sortAsc"))
          // {
          //   return
          // }          
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.sProp=sortProp;
      this.IsDesc = this.IsDesc==true?false:true;
      this.getShedules();
    },

    async getShedules() {
      console.log("search");
      if (this.searchData.visitStatus == "All") {
        this.searchData.visitStatus = "";
      }
      this.validateDate(this.searchData.fromDate, this.searchData.toDate);

      if (this.validation.includes(true)) {
        alert(this.$t("navigations.from date should be less than to date"));
      } else {
        console.log("in list");
        const idtoken = store.getters.getIdToken;
        const usermail = jwt_decode(idtoken).email;
        const StudyId = store.getters.getStudyIs;
        await axios
          .get(
            this.baseUrl +
              "getunscheduledvisit" +
              "?Investigator=" +
              usermail +
              "&StudyId=" +
              StudyId +
              "&patientID=" +
              this.patientId +
              "&patientNo=" +
              this.searchData.patientNo +
              "&fromDate=" +
              this.searchData.fromDate +
              "&toDate=" +
              this.searchData.toDate +
              "&visitStatus=" +
              this.searchData.visitStatus +
              "&PageNo=" +
              this.searchData.pageNo +
              "&ItemCount=" +
              this.searchData.pageSize +
              "&sortprop=" +
              this.sProp +
              "&isDes=" +
              this.IsDesc,

            this.headerValues
          )

          .then((res) => {
            console.log("sheduleList result:", res.data.data);

            this.sheduleList = res.data.data;

            this.hideCompleteIcon();

            // console.log("invsti",this.popupdata.nursename);

            // this.popupdata.popstudyid

            this.totalCount = res.data.totalCount;

            this.totalPage = parseInt(
              this.totalCount / this.searchData.pageSize
            );

            if (this.totalCount % this.searchData.pageSize != 0) {
              this.totalPage = this.totalPage + 1;
            }
          })

          .catch((err) => {
            console.log("error is", err);
          })

          .catch((err) => {
            console.log("error is", err);
          });
      }
    },

    validateDate(fromDate, toDate) {
      if (fromDate > toDate) {
        this.validation[0] = true;
      } else {
        this.validation[0] = false;
      }
    },

    loadPage: function (page) {
      this.currentPage = page;

      this.searchData.pageNo = page;

      this.getShedules();
    },
    async popupvalidate() {
      //console.log("from popupo nurse",  this.popupdata.nursename);

      //console.log("from popupo vst nme",  this.popupdata.visitName);

      if (this.popupdata.visitNo != "") {
        this.popupvalidations[0] = true;
      } else {
        this.popupvalidations[0] = false;
      }

      if (this.popupdata.visitName != "") {
        console.log(" vst nme not null", this.popupdata.visitName);

        this.popupvalidations[1] = true;
      } else {
        this.popupvalidations[1] = false;
      }

      if (this.popupdata.investigator != "") {
        this.popupvalidations[2] = true;
      } else {
        this.popupvalidations[2] = false;
      }

      if (this.popupdata.visitDate != "") {
        this.popupvalidations[3] = true;
      } else {
        this.popupvalidations[3] = false;
      }

      if (this.selectedmodeltemplate != "") {
        this.popupvalidations[4] = true;
      } else {
        this.popupvalidations[4] = false;
      }
    },

    async gettemplatedata() {
      const idtoken = store.getters.getIdToken;

      console.log("template data function");

      await axios

        .get(
          `${this.baseapi}/forms/template/studytemplatedetails?StudyID=${store.getters.getStudyIs}`,

          {
            headers: {
              Authorization: "Bearer " + idtoken,

              "Content-Type": "application/json",
            },
          }
        )

        .then((res) => {
          console.log("template data is");

          console.log(res.data);

          this.templatelist = res.data;
        })

        .catch((err) => {
          console.log("error");

          console.log(err);
        });
    },

    closebutton() {
      this.editShedule = "";
    },
  },
};
</script>

 

<style>
@import "../../assets/style.css";

@import "../../assets/formpage.css";

.editicon {
  position: relative;
}

.editicon .pointer {
  visibility: hidden;

  width: 150px;

  background-color: #616161;

  color: #fff;

  text-align: center;

  border-radius: 0.3em;

  padding: 0.3rem 0.2rem;

  right: 0;

  top: -35px;

  font-size: 0.8rem;

  font-weight: 500;

  /* Position the tooltip */

  position: absolute;

  z-index: 1;

  transition: visibility 0.4s ease-in;
}

.editicon:hover .pointer {
  visibility: visible;
}

/*pagination styles*/

.pagination {
  margin: 0;
}

.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}

.Page-active {
  padding: 1rem;

  color: #ffffff;

  border: 1px solid var(--pagination-active-clr);

  border-radius: inherit;

  margin: 0;
}

.Page {
  padding: 1rem;

  border-radius: inherit;

  margin: 0;
}

.PaginationControl {
  padding: 0.42rem;
}

.scheduleBlock {
  display: flex;

  justify-content: flex-end;
}

.UnscheduleBlock {
  display: flex;

  justify-content: flex-end;
}
.badge-genre {
  background-color: #b6bdbf;
  color: #ffffff;
}
.badge-grn {
  background-color: #1ab394;
  color: #ffffff;
}

/*.modal__wrapper {

  position: fixed;

  left: 0;

  top: 0;

 background: rgb(0 0 0 / 18%);

  height:50%;

  width: 100%;

  display: flex;

  align-items: center;

  justify-content: center;

  opacity: 0;

  pointer-events: none;

  transition: cubic-bezier;

  overflow: scroll;

}

*/

.titleClass {
  background-color: var(--pop-up-background);

  color: white;

  height: 35px;

  padding-left: 5px;

  padding-top: 5px;
}

.form-select {
  border-radius: .5rem !important;
}

.form-select:focus {
  border-color: #145faf !important;

  box-shadow: 0 0 0 !important;
}
</style>