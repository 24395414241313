import axios from "axios";
import Multiselect from "@vueform/multiselect";
import arctween from "../../custom_lib/AiCharts/arctween.vue";
import percebtagechart from "../../custom_lib/AiCharts/percentagechart.vue";
import d3BarchartComparitive from "../../custom_lib/AiCharts/d3BarchartComparitive.vue";
import D3PieChart from '../../custom_lib/AiCharts/D3PieChart.vue';
import sunburst from '../../custom_lib/AiCharts/sunburst.vue';
import groupbarchart from '../../custom_lib/AiCharts/groupbarchartscreening.vue';
import d3table from '../../custom_lib/AiCharts/d3table.vue';
export default{
    name: "RetentionDashboard",
    components: {
        Multiselect,
        arctween,
        percebtagechart,
        d3BarchartComparitive,
        D3PieChart,
        sunburst,
        groupbarchart,
        d3table
    },
    props: {

    },
    data(){
        return {
            baseUrl: `${process.env.VUE_APP_Service_URL}/forms-ml/`,
            totalCount: 0,
            retentionDetails: [],
            retentionCount: 0,
            searchData: {
                PageNo: 0,
                ItemCount: 0,
                SortBy: "",
                IsDescending: false,
                ML_confidenceMin: 0,
                ML_confidenceMax: 1,
                ML_prediction: null,
            },
            loader:true,
        };
    },
    async mounted(){
        console.log("in retention dashboard.")
        await this.getRetentionCount();
        await this.getPatientRetentionInfo();
        
    },
    methods:{
        async getPatientRetentionInfo() {
            this.loader = true;
            await axios
              .post(`${this.baseUrl}retention/getretentioninfo`, this.searchData)
              .then((res) => {
                this.retentionDetails = res.data.data;
                this.totalCount = res.data.count;
                this.loader = false;
              })
              .catch((err) => console.log("error in getting retention result", err));
        },
        async getRetentionCount(){
            await axios.get(`${this.baseUrl}retention/getretentioncounts`)
            .then((res) => {
              this.retentionCount = res.data;
            })
            .catch(err => {
              console.log("error in get retention counts", err)
            });
        },
    }
};