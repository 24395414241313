<template>
    <div id="survivalanalysis">
      <div class="col-lg-12">
        <SurvivalChart/>
                
      </div>
    </div>
  </template>
  
  <script>
  import SurvivalChart from '../../custom_lib/AiCharts/SurvivalChart.vue';
  export default {
    name: 'survivalanalysis',
    components: {
      SurvivalChart
    },
    data() {
      return {
      };
    }
  };
  </script>
  