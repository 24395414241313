<template>
  <div class="stat-card-white pieminheight">
    <div class="stat-header-white">
      <span>Interactive Analysis</span>
      <div v-show="!loader">
        <select class="graph-select" v-model="selectedAttribute" id="attribute-select" @change="updateChart">
          <option v-for="option in attributeOptions" :key="option" :value="option">{{ option }}</option>
        </select>
        
      </div>
    </div>
    <div class="stat-body-white chart-container d-flex flex-column" ref="chartContainerBlock">
      <div v-if="loader" class="loader"></div>
      <div v-if="!loader">
      <label class="mr-2" v-if="enrolledornot && fromPage=='patientScreening'|| fromPage== 'patientRetention'">
          <input type="checkbox" v-model="aiPredictionTrue" @change="updateChart"> {{labelOne}}
        </label>
        <label v-if="enrolledornot && fromPage=='patientScreening'|| fromPage== 'patientRetention'">
          <input type="checkbox" v-model="aiPredictionFalse" @change="updateChart"> {{labelTwo}}
        </label>
      </div>
      <svg ref="chartContainer" v-show="!loader"></svg>
    </div>
  </div>
</template>
<script>
import * as d3 from 'd3';
import axios from "axios";
import store from "../../store/index";

export default {
  name: 'PieChart',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    templateId: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 500,
    },
    height: {
      type: Number,
      default: 300,
    },
    fromPage: {
      type: String,
      default: ""
    },
    enrolledornot: {
      type: Boolean,
      default: true
    },
    labelOne: {
      type: String,
      default: 'Enrolled'
    },
    labelTwo: {
      type: String,
      default: 'Non-enrolled'
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      idtoken: "",
      baseApi: process.env.VUE_APP_Service_URL,
      mappings: {},
      svg: null,
      selectedAttribute: '',
      attributeOptions: [],
      aiPredictionTrue: true,
      aiPredictionFalse: true,
    };
  },
  watch: {
    data: {
      handler() {
        this.loadQuantitativeAttributes();
        this.createChart();
      },
      deep: true,
    },
    selectedAttribute() {
      this.updateChart();
    },
  },
  async mounted() {
    this.idtoken = store.getters.getIdToken;
    if (this.fromPage == 'patientScreening') {
      await this.createmapping();
    }
    this.createChart();
  },
  methods: {
    async loadQuantitativeAttributes() {
      if (this.fromPage == 'patientScreening' || this.fromPage == 'patientRetention') {
        const sampleData = (this.data && this.data.length > 0 && this.data[0].features) ? this.data[0].features : {};
        const qualitativeAttributes = [];

        Object.keys(sampleData).forEach(key => {
          const uniqueValues = new Set(this.data.map(item => item.features[key])).size;
          if (uniqueValues < 6) {
            qualitativeAttributes.push(key);
          }
        });
        this.attributeOptions = qualitativeAttributes;
        this.selectedAttribute = qualitativeAttributes[0] || '';
      }
      if (this.fromPage == 'descriptiveStatistics') {
        const sampleData = (this.data && this.data.length > 0) ? this.data[0] : {};
        const qualitativeAttributes = [];

        const keys = Object.keys(this.data[0]).filter(key => key !== '_id' && key !== 'Participant ID' && key !== 'SubjectID');

        keys.forEach(key => {
          const uniqueValues = new Set(this.data.map(item => item[key])).size;
          qualitativeAttributes.push(key);
        });
        this.attributeOptions = qualitativeAttributes;
        this.selectedAttribute = qualitativeAttributes[0] || '';
      }
    },
    async createmapping() {
      try {
        const response = await axios.get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          const pages = response.data.pages;
          pages.forEach(page => {
            page.field.forEach(field => {
              if (field.options && field.options.length > 0) {
                this.mappings[field.fieldName] = field.options.reduce((map, option) => {
                  map[option.codevalue] = option.optionname;
                  return map;
                }, {});
              }
            });
          });
        }
      } catch (error) {
        console.log("Error creating mappings", error);
      }
    },
    validateData(data) {
      if (this.fromPage == 'patientScreening' || this.fromPage == 'patientRetention') {
        return data.filter(d => {
          const aiPrediction = d.features.AIPrediction;
          if ((aiPrediction && this.aiPredictionTrue) || (!aiPrediction && this.aiPredictionFalse)) {
            return d.features[this.selectedAttribute] !== undefined || d[this.selectedAttribute] !== undefined;
          }
          return false;
        });
      }
      if (this.fromPage == 'descriptiveStatistics') {
        return data.filter(d => (d[this.selectedAttribute] !== undefined || d[this.selectedAttribute] !== undefined));
      }
    },
    createChart() {
      try {
        const { width, height, data } = this;
        if (!Array.isArray(data) || data.length === 0) {
          console.error('Data is invalid or empty:', data);
          return;
        }

        const validData = this.validateData(data);
        // console.log("Valid data is",validData)
        if (validData.length === 0) {
          console.error('Valid data is empty');
          return;
        }
        if (this.fromPage == 'patientScreening' || this.fromPage == 'patientRetention') {
          const aggregatedData = d3.rollups(
            validData,
            v => v.length,
            d => {
              const value = d.features[this.selectedAttribute] !== undefined ? d.features[this.selectedAttribute] : d[this.selectedAttribute];
              return this.mappings[this.selectedAttribute] ? this.mappings[this.selectedAttribute][value] || value : value;
            }
          ).map(([key, value]) => ({ key, value }));

          if (aggregatedData.length === 0) {
            console.error('Aggregated data is empty');
            return;
          }
          const radius = 100;
          const customColors = ['#28C0FF', '#FF98BD', '#8C82FF', '#FFB44F', '#57D9A5'];
          const color = d3.scaleOrdinal()
            .domain(aggregatedData.map(d => d.key))
            .range([...customColors, ...d3.schemeCategory10.slice(customColors.length)]);

          this.svg = d3.select(this.$refs.chartContainer)
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${width / 2.1}, ${height / 2})`);

          const pie = d3.pie()
            .sort(null)
            .value(d => d.value);

          const arc = d3.arc()
            .innerRadius(0)
            .outerRadius(radius);

          const arcs = this.svg.selectAll('.arc')
            .data(pie(aggregatedData))
            .enter().append('g')
            .attr('class', 'arc');

          arcs.append('path')
            .attr('d', arc)
            .attr('fill', d => color(d.data.key));

          // Add legends with values
          const legend = this.svg.append('g')
            .attr('class', 'legend')
            .attr('transform', `translate(${-width / 2 + 20}, ${-height / 2 + 20})`);

          const legendItem = legend.selectAll('.legend-item')
            .data(aggregatedData)
            .enter().append('g')
            .attr('class', 'legend-item')
            .attr('transform', (d, i) => `translate(0, ${i * 20})`);

          legendItem.append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', 18)
            .attr('height', 18)
            .attr('fill', d => color(d.key));

          legendItem.append('text')
            .attr('x', 24)
            .attr('y', 9)
            .attr('dy', '0.35em')
            .text(d => `${d.key}: ${d.value}`);

        }
        if (this.fromPage == 'descriptiveStatistics') {

          const aggregatedData = d3.rollups(
          validData,
          v => v.length,
          d => {
            const value = d[this.selectedAttribute] !== undefined || d[this.selectedAttribute] !== null? d[this.selectedAttribute] : d[this.selectedAttribute];
            if(typeof d[this.selectedAttribute] === 'number')
            {
              const range=this.getRange(value)
              return this.mappings[this.selectedAttribute] ? this.mappings[this.selectedAttribute][range] || range : range;
            }
            else{
              return this.mappings[this.selectedAttribute] ? this.mappings[this.selectedAttribute][value] || value : value;
            }
          }
          ).map(([key, value]) => ({ key, value }));

          if (aggregatedData.length === 0) {
            console.error('Aggregated data is empty');
            return;
          }

          const radius = 100;
          const customColors = ['#28C0FF', '#FF98BD', '#8C82FF', '#FFB44F', '#57D9A5'];
          const color = d3.scaleOrdinal()
            .domain(aggregatedData.map(d => d.key))
            .range([...customColors, ...d3.schemeCategory10.slice(customColors.length)]);

          this.svg = d3.select(this.$refs.chartContainer)
            .attr('viewBox', [0, 0, width, height])
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${width / 2}, ${height / 2})`);

          const pie = d3.pie()
            .sort(null)
            .value(d => d.value);

          const arc = d3.arc()
            .innerRadius(0)
            .outerRadius(radius);

          const arcs = this.svg.selectAll('.arc')
            .data(pie(aggregatedData))
            .enter().append('g')
            .attr('class', 'arc');

          arcs.append('path')
            .attr('d', arc)
            .attr('fill', d => color(d.data.key));

          // Add legends with values
          const legend = this.svg.append('g')
            .attr('class', 'legend')
            .attr('transform', `translate(${-width / 2 + 20}, ${-height / 2 + 20})`);

          const legendItem = legend.selectAll('.legend-item')
            .data(aggregatedData)
            .enter().append('g')
            .attr('class', 'legend-item')
            .attr('transform', (d, i) => `translate(0, ${i * 20})`);

          legendItem.append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', 18)
            .attr('height', 18)
            .attr('fill', d => color(d.key))

          legendItem.append('text')
            .attr('x', 24)
            .attr('y', 9)
            .attr('dy', '0.35em')
            .text(d => `${d.key}: ${d.value}`)

        }
      } catch (error) {
        console.error('Error creating pie chart:', error);
      } 
    },
    updateChart() {
      d3.select(this.$refs.chartContainer).selectAll('*').remove();
      this.svg = null;
      this.createChart();
    },
    // Function to determine the range
    getRange(value) {
      const ranges = [0, 10, 20, 30, 40, 50]; // Define your ranges
      for (let i = 0; i < ranges.length - 1; i++) {
        if (value >= ranges[i] && value < ranges[i + 1]) {
          return `${ranges[i]}-${ranges[i + 1]}`;
        }
      }
      return `${ranges[ranges.length - 1]}+`; // Last range
    }
  },
};
</script>
<style scoped>
.pieminheight{
  min-height:405px
}
.chart-container {
  overflow-x: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.legend-item {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.stat-card-white {
  background: #ffffff;
  border-radius: 10px;
  color: #676a6c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stat-header-white {
  border-bottom: 2px solid rgba(241, 241, 241);
  padding:0.4rem 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stat-header-white span {
  font-size: 1.2em;
  font-weight: 500;
}

.stat-body-white {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 330px;
}

.controls {
  margin-bottom: 10px;
}

.controls label {
  margin-right: 5px;
}

.controls select {
  margin-right: 15px;
}

.pieheight {
  height: 400px;
}

.graph-select {
  display: block;
  width: 100%;
  padding: 0.3rem 2.25rem 0.3rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.graph-select:focus,
.graph-select:focus-within,
.graph-select:focus-visible {
  outline: none;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
