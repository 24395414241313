<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t('navigations.patients') }}</h2>
    </div>
  </div>

  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-0 w-100 pr-1">
            <label class="mb-0">{{ $t('navigations.site code') }}</label>
            <select
              class="form-select my-2 my-lg-0"
              name="account"
              placeholder="Site code"
              v-model="searchData.siteId"
            >
              <option value="All">{{ $t('navigations.all') }}</option>
              <option
                v-for="site in sites"
                :key="site.siteID"
                :value="site.siteID"
              >
                {{ site.siteCode }}
              </option>
              <!--<option>Archived</option>-->
            </select>
          </div>
          <div class="form-group mb-0 w-100 px-1">
            <label class="mb-0">{{ $t('navigations.subject id') }}</label>
            <input
              type="text"
              class="form-control my-2 my-lg-0"
              v-model="searchData.subjectid"
            />
          </div>
          <div class="form-group mb-0 w-100 px-1">
            <label class="mb-0">{{ $t('navigations.status') }}</label>
            <select
              class="form-select my-2 my-lg-0"
              name="account"
              placeholder="Search"
              v-model="searchData.status"
            >
              <option value= "" > {{ $t('navigations.all') }} </option>
              <option :value=" $t('navigations.activated') " > {{ $t('navigations.activated') }} </option>
              <option :value=" $t('navigations.pending') "> {{ $t('navigations.pending') }}</option>
            </select> 
          </div>
          <span class="save_btn px-5 mx-lg-1 align-self-end" @click="SearchButtonClicked()">
            {{ $t('navigations.search') }}
          </span>
        </div>
      </div>
    </div>

    <div class="ibox-body">
      <div class="ibox-title style_2">
        <h5 class="float-left">{{ $t('navigations.patient login list') }}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>{{ $t('navigations.site code') }}</th>
                <th>{{ $t('navigations.subject id') }}</th>
                <th
                  class="sort_block"
                  @click="SortSelected('language', 4)"
                  id="4"
                >
                  <div class="d-flex flex-row justify-content-between">      
                    {{ $t("navigations.language") }}        
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('nurseName', 6)"
                  id="6"
                >
                  <div class="d-flex flex-row justify-content-between">     
                    {{ $t("navigations.investigator") }}         
                    <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th>{{ $t('navigations.login email') }}</th>
                <th>{{ $t('navigations.activation status') }}</th>
                <th v-if="roleprivileges.includes('Patient Login Add Login')" class="text-center">{{ $t('navigations.add login') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="patient in finalList" :key="patient.patientId">
                <td>{{ patient.siteCode }}</td>
                <td>{{ patient.subjectId }}</td>
                <td>{{ patient.language }}</td>
                <td>{{ patient.name }}</td>
                <td>{{ patient.emailID }}</td>
                <td>{{ patient.activeStatus }}</td>
                <td v-if="roleprivileges.includes('Patient Login Add Login')" class="text-center">
                  <i
                    v-if="!patient.activeStatus"
                    class="fa fa-plus-circle edit-delete"
                    aria-hidden="true"
                    @click.prevent="
                      openModal(
                        patient.siteCode,
                        patient.subjectId,
                        patient.patientId
                      )
                    "
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>

          <!------ pagination -------->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="
                  dataTables_paginate
                  paging_simple_numbers
                  pagination
                  float-right
                "
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="currentPage"
                    :pages="totalPages"
                    :range-size="1"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- pagin -->
        </div>
      </div>
    </div>
  </div>

  <!-- modal starts -->
  <div class="patient__login__modal__wrapper" v-if="patientModal">
    <div class="patient__login__modal__container">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">{{ $t('navigations.patient login') }}</h4>
        <div class="cursor-pointer" @click.prevent="closeModal">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="px-4 py-3">
        <!-- test -->
          <div class="form-group row">
            <!-- <div class="d-flex flex-column flex-lg-row my-2 mb-lg-0"> -->
            <div class="col-lg-6">
              <label class="col-form-label">{{ $t('navigations.site code') }}</label>
              <input
                type="text"
                class="form-control"
                v-model="modalData.siteCode"
                disabled
              />
            </div>
            <div class="col-lg-6">
              <label class="col-form-label">{{ $t('navigations.subject id') }}</label>
              <input
                type="text"
                class="form-control"
                v-model="modalData.subjectId"
                disabled
              />
            </div>
            <div class="col-lg-12 text-center mt-2">
              <label class="col-form-label"
                >{{ $t('navigations.email') }}<sup><i class="fa fa-asterisk required"></i></sup
              ></label>
              <input
                type="text"
                class="form-control"
                v-model="modalData.email"
              />
              <span class="errormsg col-lg-12" v-if="errors[0] == false">
                {{ $t('navigations.please enter email') }}</span
              >
              <span
                class="errormsg col-lg-12 my-2"
                v-if="errors[1] == false && errors[0] == true"
              >
                {{ $t('navigations.invalid email') }}</span
              >
              <span
                class="errormsg col-lg-12"
                v-if="
                  errors[2] == false && errors[0] == true && errors[1] == true
                "
                >{{ $t('navigations.email already exist') }}</span
              >
              <button
                  class="save_btn px-5 mt-3"
                  @click.prevent="addLogin()"
                >
                {{ $t('navigations.submit') }}
              </button>
            </div>
                
              </div>
            </div>
        <!-- test -->
      </div>
    </div>
  <!-- modal ends -->
</template>
<script>
/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "patientLoginList",
  components: {
    VPagination,
  },
  data() {
    return {
      currentPage: 1,
      roleprivileges: "",
      patientModal:false,
      sites: "",
      baseEnv: `${process.env.VUE_APP_Service_URL}/management/patientlogin/`,
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/patient/`,
      baseapi: process.env.VUE_APP_Service_URL,
      patientLists: [],
      patientIds: [],
      countries: [],
      enablePrevious: true,
      enableNext: true,
      totalPages: 1,
      siteId: "",
      subjectid: "",
      status: "",
      pageIndex: 1,
      pageSize: 5,
      sortProperty: "",
      sortorder: 0,

      searchData: {
        siteId: "",
        subjectid: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        sortProperty: "",
        sortorder: 0,
      },
      modalData: {
        siteCode: "",
        subjectId: "",
        email: "",
      },
      values: {
        subjectID: "",
        emailID: "",
        patientPin: "",
        password: "",
        activeStatus: "",
      },
      finalList: [],
      validations: [false, false, false],
      errors: [],
      patientLoginDetails: "",
      logged: false,
      headerValues: "",
      loginList: [],
      flage: false,
    };
  },
  async mounted() {
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      const idtoken = store.getters.getIdToken;
      this.headerValues = {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      };
      // window.location.reload();
      this.getCountries();
      this.search();
      this.getsites();
      this.filter();
      this.listPatientLogin();
      this.mergeLists();
    });
    await this.setroleprivileges(); 
    console.log("in mounted");
    await this.getCountries();
    await this.search();
    await this.getsites();
    this.filter();
    this.mergeLists();
  },

  methods: {
    async setroleprivileges(){
      this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    filter(data) {
      if (data) {
        return moment(data).format("DD/MM/YYYY");
      }
    },
    async getCountries() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseapi}/management/patient/getallcountry`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          this.countries = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getsites() {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      await axios
        .get(`${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudy}&sortProperty=siteCode&IsDes=false&getAll=false`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          console.log(idtoken);
          this.sites = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async editPatient(id) {
      console.log(id);
      this.$router.push(`/managePatient/${id}`);
    },
    async getPreviouspage() {
      if (this.searchData.pageIndex > 1) {
        this.searchData.pageIndex = this.searchData.pageIndex - 1;
      }
      await this.search();
    },
    async getNextpage() {
      if (this.searchData.pageIndex < this.totalPages) {
        this.searchData.pageIndex = this.searchData.pageIndex + 1;
      }
      await this.search();
    },
    async SearchButtonClicked(){
      this.searchData.pageIndex=1;
      this.currentPage=1;
this.search();
    },
    async search() {
      if (this.searchData.siteId == "All") {
        this.searchData.siteId = "";
      }
      if (this.searchData.status == "All") {
        this.searchData.status = "";
      }
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      console.log(currentstudy);
      console.log("search");

      this.siteId = this.searchData.siteId;
      this.subjectid = this.searchData.subjectid;
      this.status = this.searchData.status;
      //this.sortProperty = "";
      //this.sortorder = 0;
      this.pageIndex = this.searchData.pageIndex;
      this.pageSize = this.searchData.pageSize;
      await axios
        .get(
          `${this.baseUrl}listpatient?SiteId=${this.siteId}&Studyid=${currentstudy}&SubjectId=${this.subjectid}&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=${this.sortProperty}&SortOrder=${this.sortorder}&api-version=1.0`,
         // `https://localhost:5001/patient/listpatient?SiteId=${this.siteId}&Studyid=${currentstudy}&SubjectId=${this.subjectid}&Status=${this.searchData.status}&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=${this.sortProperty}&SortOrder=${this.sortorder}&api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.totalPages = parseInt(res.data.totalCount / this.pageSize);
          this.patientLists = res.data.results;
          this.patientLists.filter((item) => {
            if (item.country) {
              this.countries.filter((subitem) => {
                if (item.country == subitem.code) {
                  item.country = subitem.name;
                }
              });
            }
          });
          if (res.data.totalCount % this.pageSize != 0) {
            this.totalPages = this.totalPages + 1;
          }
          console.log(res);
          
        });
        await this.listPatientLogin();
        this.mergeLists();
        console.log("Final List is ", this.finalList);
    },
    async SortSelected(sortProp, id) {
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID)
        console.log(this.sortorder);
      {
        if (this.sortorder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          }
          else selectedElement.classList.add("sortAsc");
        }
      }
      this.searchData.pageIndex = 1;
      this.currentPage = 1;
      this.sortProperty = sortProp;
      this.sortorder = this.sortorder == 0 ? 1 : 0;
      console.log("SortPropChanged",this.sortorder,this.sortProperty);
      await this.search();
    },
    async loadPage(page) {
      this.currentPage = page;
      this.searchData.pageIndex = page;
      this.search();
      this.listPatientLogin();
      this.mergeLists();
    },
    openModal(siteCode, subjectId, patientId) {
      document.body.style.overflowY = "hidden";
      this.values.subjectID = patientId;
      this.modalData.siteCode = siteCode;
      this.modalData.subjectId = subjectId;
      this.patientModal=!this.patientModal
    },
    closeModal() {
    document.body.style.overflowY = "auto";
     this.patientModal=!this.patientModal
      this.modalData = {
        siteCode: "",
        subjectId: "",
        email: "",
      };
      this.errors=[];
    },
    async addLogin() {
      this.values.emailID = this.modalData.email;
      this.validateEmail(this.values.emailID);
      this.IsEmailExist(this.values.emailID);
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      } else {
        const idtoken = store.getters.getIdToken;
        console.log("values...", this.values);
        await axios
          .post(`${this.baseEnv}createpatientlogin`, this.values, {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log("created succefully.!", res);
            this.closeModal();
            this.search();
            this.listPatientLogin();
            this.mergeLists();
            alert(this.$t('navigations.patient login created successfully'));
           // this.$router.go();
          })
          .catch((err) => {
            if (
              err.response.data.messages[0].includes("Patient already exists")
            ) {
              alert(this.$t("navigations.patient already exists"));
            }
          });
      }
    },
    isLogined(patientId) {
      this.getPatientLoginById(patientId);
      return this.logged;
    },
    validateEmail(email) {
      if (email == "" || email == "" || email == null) {
        this.validations[0] = false;
      } else {
        this.validations[0] = true;
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
          this.validations[1] = true;
        } else {
          this.validations[1] = false;
        }
      }
    },
    IsEmailExist(email) {
      let flag = false;
      this.loginList.forEach((login) => {
        if (login.emailID == email) {
          flag = true;
        }
      });
      if (flag == true) {
        this.validations[2] = false;
      } else {
        this.validations[2] = true;
      }
    },
    async getPatientLoginById(patientId) {
      const idtoken = store.getters.getIdToken;
      console.log("Testttttttttttttttttt get by id");
      await axios
        .get(`${this.baseEnv}getpatientloginbyid/` + patientId, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.logged = true;
          this.patientLoginDetails = res.data;
        })
        .catch((err) => {
          console.log("error..", err);
          this.logged = true;
        });
    },
    async listPatientLogin() {
      const idtoken = store.getters.getIdToken;
      console.log("Testttttttttttttttttt list");
      await axios
        .get(`${this.baseEnv}listpatientlogin?status=${this.searchData.status}`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.loginList = res.data;
          console.log("Login list ", this.loginList);
        })
        .catch((err) => console.log("error..", err));
    },
    mergeLists() {
      this.finalList = [];
      let item = {
        name: "",
        language: "",
        subjectId: "",
        siteCode: "",
        patientId: "",
        activeStatus: "",
        emailID: "",
      };
      console.log("patient item", this.patientLists);
      console.log("patient  login item", this.loginList);

      this.patientLists.forEach((patient) => {
        this.flage = false;
        this.loginList.forEach((login) => {
          if (patient.patientId == login.subjectID ) {
            this.flage = true;
            item = {
              name: patient.nurseName,
              language: patient.language,
              subjectId: patient.subjectId,
              siteCode: patient.siteCode,
              patientId: patient.patientId,
              activeStatus: login.activeStatus,
              emailID: login.emailID,
            };
           this.finalList.push(item);
          }
        });
        if(this.flage == false && this.searchData.status  == "" ) {
          item = {
            name: patient.nurseName,
            language: patient.language,
            subjectId: patient.subjectId,
            siteCode: patient.siteCode,
            patientId: patient.patientId,
            activeStatus: "",
            emailID: "",
          };
           this.finalList.push(item);
        }
      });
    },
  },
};
</script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.modelbody {
  overflow: unset !important;
  background-color :#ffffff !important; 
}

.modelbody {
  /* background-color: aquamarine; */
  margin: 0;
  padding: 5%;
}

.form-control {
  padding: 0.2rem 0.75rem !important;
}
.form-select {
  border-radius: .5rem !important;
  padding: 0.2rem 0.75rem !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
.patient__login__modal__wrapper{
  position: fixed;
  inset:0;
  background: rgb(0 0 0 / 18%);
  display:flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.patient__login__modal__container{
  position: fixed;
  width: min(50%, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}
.modal__title{
  background-color: var(--pop-up-background);
  color: white;
} 

</style>